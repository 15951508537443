
#main,
#main.main-full {
	padding-left: 0
}

ul.list-type-bullet,
ul.list-type-bullet li {
	list-style-type: inherit
}

.avatar-status,
.card-alert .alert-circle,
.material-icons,
.text-ellipsis,
.text-nowrap {
	white-space: nowrap
}

.clear,
.clear-both {
	clear: both
}

body,
html {
	height: 100%
}

@media only screen and (max-width:600px) {
	.layout-semi-dark #header nav.navbar-color {
		background: #37474f
	}
}

.preload-transitions * {
	-webkit-transition: none!important;
	transition: none!important
}

#main {
	min-height: calc(100% - 116px);
	-webkit-transition: .3s ease all;
	transition: .3s ease all;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden
}

#main .content-wrapper-before {
	position: absolute;
	z-index: -1;
	top: 64px;
	left: 0;
	width: 100%;
	height: 185px;
	-webkit-transition: .3s ease all;
	transition: .3s ease all
}

footer {
	padding: 0 10px
}

footer.page-footer {
	padding-top: 1px
}

@media only screen and (min-width:993px) {
	
	.sidenav-overlay {
		background-color: transparent
	}
	#main {
		padding-left: 260px
	}
	#main.main-full {
		padding-left: 64px
	}
	.nav-lock~footer {
		padding-left: 267px
	}
	footer {
		padding-left: 71px
	}
}

@media only screen and (max-width:600px) {
	#main {
		min-height: calc(100% - 107px)
	}
	#main .content-wrapper-before {
		top: 56px
	}
}

.ps-scrollbar-x-rail,
.ps-scrollbar-y-rail {
	z-index: 9999
}

.bg-opacity-8 {
	background-color: rgba(255, 255, 255, .8)
}

[type=reset],
[type=submit],
button,
html [type=button] {
	cursor: pointer;
	-webkit-appearance: none
}


.small {
	font-size: 1rem!important
}

.medium-small {
	font-size: .9rem!important
}

.ultra-small {
	font-size: .8rem!important
}

small {
	font-size: .8rem
}

.strong {
	font-weight: 600
}

h4.header {
	font-size: 1.4rem;
	font-weight: 400;
	line-height: 2.508rem;
	margin: 1.14rem 0 .912rem;
	text-transform: uppercase
}

h4.header2 {
	font-size: 1.1rem;
	font-weight: 400;
	text-transform: uppercase
}

.more-text,
p.header {
	font-weight: 500;
	text-transform: uppercase
}

p.title {
	font-size: 1.3rem
}

p.header {
	font-size: 1rem
}

li.li-hover:hover {
	background: 0 0!important
}

li.search-out:hover {
	background: 0 0
}

li.mobile:before {
	background: #f7464a
}

li.kitchen:before {
	background: #46bfbd
}

li.home:before {
	background: #fdb45c
}

.more-text {
	padding: 5px 20px;
	letter-spacing: 1px
}

.valign-demo {
	height: 400px;
	background-color: #ddd
}

.margin {
	margin: 0!important
}

.uppercase {
	text-transform: uppercase
}

.section {
	padding: .5rem 0
}

.row {
	margin-bottom: 0
}

.row .col {
	/* padding: 0 1rem */
}

.materialize-red {
	background-color: #e51c23!important
}

.materialize-red-text {
	color: #e51c23!important
}

.materialize-red.lighten-5 {
	background-color: #fdeaeb!important
}

.materialize-red-text.text-lighten-5 {
	color: #fdeaeb!important
}

.materialize-red.lighten-4 {
	background-color: #f8c1c3!important
}

.materialize-red-text.text-lighten-4 {
	color: #f8c1c3!important
}

.materialize-red.lighten-3 {
	background-color: #f3989b!important
}

.materialize-red-text.text-lighten-3 {
	color: #f3989b!important
}

.materialize-red.lighten-2 {
	background-color: #ee6e73!important
}

.materialize-red-text.text-lighten-2 {
	color: #ee6e73!important
}

.materialize-red.lighten-1 {
	background-color: #ea454b!important
}

.materialize-red-text.text-lighten-1 {
	color: #ea454b!important
}

.materialize-red.darken-1 {
	background-color: #d0181e!important
}

.materialize-red-text.text-darken-1 {
	color: #d0181e!important
}

.materialize-red.darken-2 {
	background-color: #b9151b!important
}

.materialize-red-text.text-darken-2 {
	color: #b9151b!important
}

.materialize-red.darken-3 {
	background-color: #a21318!important
}

.materialize-red-text.text-darken-3 {
	color: #a21318!important
}

.materialize-red.darken-4 {
	background-color: #8b1014!important
}

.materialize-red-text.text-darken-4 {
	color: #8b1014!important
}

.red {
	background-color: #f44336!important
}

.red-text {
	color: #f44336!important
}

.red.lighten-5 {
	background-color: #ffebee!important
}

.red-text.text-lighten-5 {
	color: #ffebee!important
}

.red.lighten-4 {
	background-color: #ffcdd2!important
}

.red-text.text-lighten-4 {
	color: #ffcdd2!important
}

.red.lighten-3 {
	background-color: #ef9a9a!important
}

.red-text.text-lighten-3 {
	color: #ef9a9a!important
}

.red.lighten-2 {
	background-color: #e57373!important
}

.red-text.text-lighten-2 {
	color: #e57373!important
}

.red.lighten-1 {
	background-color: #ef5350!important
}

.red-text.text-lighten-1 {
	color: #ef5350!important
}

.red.darken-1 {
	background-color: #e53935!important
}

.red-text.text-darken-1 {
	color: #e53935!important
}

.red.darken-2 {
	background-color: #d32f2f!important
}

.red-text.text-darken-2 {
	color: #d32f2f!important
}

.red.darken-3 {
	background-color: #c62828!important
}

.red-text.text-darken-3 {
	color: #c62828!important
}

.red.darken-4 {
	background-color: #b71c1c!important
}

.red-text.text-darken-4 {
	color: #b71c1c!important
}

.red.accent-1 {
	background-color: #ff8a80!important
}

.red-text.text-accent-1 {
	color: #ff8a80!important
}

.red.accent-2 {
	background-color: #ff5252!important
}

.red-text.text-accent-2 {
	color: #ff5252!important
}

.red.accent-3 {
	background-color: #ff1744!important
}

.red-text.text-accent-3 {
	color: #ff1744!important
}

.red.accent-4 {
	background-color: #d50000!important
}

.red-text.text-accent-4 {
	color: #d50000!important
}

.pink {
	background-color: #e91e63!important
}

.pink-text {
	color: #e91e63!important
}

.pink.lighten-5 {
	background-color: #fce4ec!important
}

.pink-text.text-lighten-5 {
	color: #fce4ec!important
}

.pink.lighten-4 {
	background-color: #f8bbd0!important
}

.pink-text.text-lighten-4 {
	color: #f8bbd0!important
}

.pink.lighten-3 {
	background-color: #f48fb1!important
}

.pink-text.text-lighten-3 {
	color: #f48fb1!important
}

.pink.lighten-2 {
	background-color: #f06292!important
}

.pink-text.text-lighten-2 {
	color: #f06292!important
}

.pink.lighten-1 {
	background-color: #ec407a!important
}

.pink-text.text-lighten-1 {
	color: #ec407a!important
}

.pink.darken-1 {
	background-color: #d81b60!important
}

.pink-text.text-darken-1 {
	color: #d81b60!important
}

.pink.darken-2 {
	background-color: #c2185b!important
}

.pink-text.text-darken-2 {
	color: #c2185b!important
}

.pink.darken-3 {
	background-color: #ad1457!important
}

.pink-text.text-darken-3 {
	color: #ad1457!important
}

.pink.darken-4 {
	background-color: #880e4f!important
}

.pink-text.text-darken-4 {
	color: #880e4f!important
}

.pink.accent-1 {
	background-color: #ff80ab!important
}

.pink-text.text-accent-1 {
	color: #ff80ab!important
}

.pink.accent-2 {
	background-color: #ff4081!important
}

.pink-text.text-accent-2 {
	color: #ff4081!important
}

.pink.accent-3 {
	background-color: #f50057!important
}

.pink-text.text-accent-3 {
	color: #f50057!important
}

.pink.accent-4 {
	background-color: #c51162!important
}

.pink-text.text-accent-4 {
	color: #c51162!important
}

.purple {
	background-color: #9c27b0!important
}

.purple-text {
	color: #9c27b0!important
}

.purple.lighten-5 {
	background-color: #f3e5f5!important
}

.purple-text.text-lighten-5 {
	color: #f3e5f5!important
}

.purple.lighten-4 {
	background-color: #e1bee7!important
}

.purple-text.text-lighten-4 {
	color: #e1bee7!important
}

.purple.lighten-3 {
	background-color: #ce93d8!important
}

.purple-text.text-lighten-3 {
	color: #ce93d8!important
}

.purple.lighten-2 {
	background-color: #ba68c8!important
}

.purple-text.text-lighten-2 {
	color: #ba68c8!important
}

.purple.lighten-1 {
	background-color: #ab47bc!important
}

.purple-text.text-lighten-1 {
	color: #ab47bc!important
}

.purple.darken-1 {
	background-color: #8e24aa!important
}

.purple-text.text-darken-1 {
	color: #8e24aa!important
}

.purple.darken-2 {
	background-color: #7b1fa2!important
}

.purple-text.text-darken-2 {
	color: #7b1fa2!important
}

.purple.darken-3 {
	background-color: #6a1b9a!important
}

.purple-text.text-darken-3 {
	color: #6a1b9a!important
}

.purple.darken-4 {
	background-color: #4a148c!important
}

.purple-text.text-darken-4 {
	color: #4a148c!important
}

.purple.accent-1 {
	background-color: #ea80fc!important
}

.purple-text.text-accent-1 {
	color: #ea80fc!important
}

.purple.accent-2 {
	background-color: #e040fb!important
}

.purple-text.text-accent-2 {
	color: #e040fb!important
}

.purple.accent-3 {
	background-color: #d500f9!important
}

.purple-text.text-accent-3 {
	color: #d500f9!important
}

.purple.accent-4 {
	background-color: #a0f!important
}

.purple-text.text-accent-4 {
	color: #a0f!important
}

.deep-purple {
	background-color: #673ab7!important
}

.deep-purple-text {
	color: #673ab7!important
}

.deep-purple.lighten-5 {
	background-color: #ede7f6!important
}

.deep-purple-text.text-lighten-5 {
	color: #ede7f6!important
}

.deep-purple.lighten-4 {
	background-color: #d1c4e9!important
}

.deep-purple-text.text-lighten-4 {
	color: #d1c4e9!important
}

.deep-purple.lighten-3 {
	background-color: #b39ddb!important
}

.deep-purple-text.text-lighten-3 {
	color: #b39ddb!important
}

.deep-purple.lighten-2 {
	background-color: #9575cd!important
}

.deep-purple-text.text-lighten-2 {
	color: #9575cd!important
}

.deep-purple.lighten-1 {
	background-color: #7e57c2!important
}

.deep-purple-text.text-lighten-1 {
	color: #7e57c2!important
}

.deep-purple.darken-1 {
	background-color: #5e35b1!important
}

.deep-purple-text.text-darken-1 {
	color: #5e35b1!important
}

.deep-purple.darken-2 {
	background-color: #512da8!important
}

.deep-purple-text.text-darken-2 {
	color: #512da8!important
}

.deep-purple.darken-3 {
	background-color: #4527a0!important
}

.deep-purple-text.text-darken-3 {
	color: #4527a0!important
}

.deep-purple.darken-4 {
	background-color: #311b92!important
}

.deep-purple-text.text-darken-4 {
	color: #311b92!important
}

.deep-purple.accent-1 {
	background-color: #b388ff!important
}

.deep-purple-text.text-accent-1 {
	color: #b388ff!important
}

.deep-purple.accent-2 {
	background-color: #7c4dff!important
}

.deep-purple-text.text-accent-2 {
	color: #7c4dff!important
}

.deep-purple.accent-3 {
	background-color: #651fff!important
}

.deep-purple-text.text-accent-3 {
	color: #651fff!important
}

.deep-purple.accent-4 {
	background-color: #6200ea!important
}

.deep-purple-text.text-accent-4 {
	color: #6200ea!important
}

.indigo {
	background-color: #3f51b5!important
}

.indigo-text {
	color: #3f51b5!important
}

.indigo.lighten-5 {
	background-color: #e8eaf6!important
}

.indigo-text.text-lighten-5 {
	color: #e8eaf6!important
}

.indigo.lighten-4 {
	background-color: #c5cae9!important
}

.indigo-text.text-lighten-4 {
	color: #c5cae9!important
}

.indigo.lighten-3 {
	background-color: #9fa8da!important
}

.indigo-text.text-lighten-3 {
	color: #9fa8da!important
}

.indigo.lighten-2 {
	background-color: #7986cb!important
}

.indigo-text.text-lighten-2 {
	color: #7986cb!important
}

.indigo.lighten-1 {
	background-color: #5c6bc0!important
}

.indigo-text.text-lighten-1 {
	color: #5c6bc0!important
}

.indigo.darken-1 {
	background-color: #3949ab!important
}

.indigo-text.text-darken-1 {
	color: #3949ab!important
}

.indigo.darken-2 {
	background-color: #303f9f!important
}

.indigo-text.text-darken-2 {
	color: #303f9f!important
}

.indigo.darken-3 {
	background-color: #283593!important
}

.indigo-text.text-darken-3 {
	color: #283593!important
}

.indigo.darken-4 {
	background-color: #1a237e!important
}

.indigo-text.text-darken-4 {
	color: #1a237e!important
}

.indigo.accent-1 {
	background-color: #8c9eff!important
}

.indigo-text.text-accent-1 {
	color: #8c9eff!important
}

.indigo.accent-2 {
	background-color: #536dfe!important
}

.indigo-text.text-accent-2 {
	color: #536dfe!important
}

.indigo.accent-3 {
	background-color: #3d5afe!important
}

.indigo-text.text-accent-3 {
	color: #3d5afe!important
}

.indigo.accent-4 {
	background-color: #304ffe!important
}

.indigo-text.text-accent-4 {
	color: #304ffe!important
}

.blue {
	background-color: #2196f3!important
}

.blue-text {
	color: #2196f3!important
}

.blue.lighten-5 {
	background-color: #e3f2fd!important
}

.blue-text.text-lighten-5 {
	color: #e3f2fd!important
}

.blue.lighten-4 {
	background-color: #bbdefb!important
}

.blue-text.text-lighten-4 {
	color: #bbdefb!important
}

.blue.lighten-3 {
	background-color: #90caf9!important
}

.blue-text.text-lighten-3 {
	color: #90caf9!important
}

.blue.lighten-2 {
	background-color: #64b5f6!important
}

.blue-text.text-lighten-2 {
	color: #64b5f6!important
}

.blue.lighten-1 {
	background-color: #42a5f5!important
}

.blue-text.text-lighten-1 {
	color: #42a5f5!important
}

.blue.darken-1 {
	background-color: #1e88e5!important
}

.blue-text.text-darken-1 {
	color: #1e88e5!important
}

.blue.darken-2 {
	background-color: #1976d2!important
}

.blue-text.text-darken-2 {
	color: #1976d2!important
}

.blue.darken-3 {
	background-color: #1565c0!important
}

.blue-text.text-darken-3 {
	color: #1565c0!important
}

.blue.darken-4 {
	background-color: #0d47a1!important
}

.blue-text.text-darken-4 {
	color: #0d47a1!important
}

.blue.accent-1 {
	background-color: #82b1ff!important
}

.blue-text.text-accent-1 {
	color: #82b1ff!important
}

.blue.accent-2 {
	background-color: #448aff!important
}

.blue-text.text-accent-2 {
	color: #448aff!important
}

.blue.accent-3 {
	background-color: #2979ff!important
}

.blue-text.text-accent-3 {
	color: #2979ff!important
}

.blue.accent-4 {
	background-color: #2962ff!important
}

.blue-text.text-accent-4 {
	color: #2962ff!important
}

.light-blue {
	background-color: #03a9f4!important
}

.light-blue-text {
	color: #03a9f4!important
}

.light-blue.lighten-5 {
	background-color: #e1f5fe!important
}

.light-blue-text.text-lighten-5 {
	color: #e1f5fe!important
}

.light-blue.lighten-4 {
	background-color: #b3e5fc!important
}

.light-blue-text.text-lighten-4 {
	color: #b3e5fc!important
}

.light-blue.lighten-3 {
	background-color: #81d4fa!important
}

.light-blue-text.text-lighten-3 {
	color: #81d4fa!important
}

.light-blue.lighten-2 {
	background-color: #4fc3f7!important
}

.light-blue-text.text-lighten-2 {
	color: #4fc3f7!important
}

.light-blue.lighten-1 {
	background-color: #29b6f6!important
}

.light-blue-text.text-lighten-1 {
	color: #29b6f6!important
}

.light-blue.darken-1 {
	background-color: #039be5!important
}

.light-blue-text.text-darken-1 {
	color: #039be5!important
}

.light-blue.darken-2 {
	background-color: #0288d1!important
}

.light-blue-text.text-darken-2 {
	color: #0288d1!important
}

.light-blue.darken-3 {
	background-color: #0277bd!important
}

.light-blue-text.text-darken-3 {
	color: #0277bd!important
}

.light-blue.darken-4 {
	background-color: #01579b!important
}

.light-blue-text.text-darken-4 {
	color: #01579b!important
}

.light-blue.accent-1 {
	background-color: #80d8ff!important
}

.light-blue-text.text-accent-1 {
	color: #80d8ff!important
}

.light-blue.accent-2 {
	background-color: #40c4ff!important
}

.light-blue-text.text-accent-2 {
	color: #40c4ff!important
}

.light-blue.accent-3 {
	background-color: #00b0ff!important
}

.light-blue-text.text-accent-3 {
	color: #00b0ff!important
}

.light-blue.accent-4 {
	background-color: #0091ea!important
}

.light-blue-text.text-accent-4 {
	color: #0091ea!important
}

.cyan {
	background-color: #00bcd4!important
}

.cyan-text {
	color: #00bcd4!important
}

.cyan.lighten-5 {
	background-color: #e0f7fa!important
}

.cyan-text.text-lighten-5 {
	color: #e0f7fa!important
}

.cyan.lighten-4 {
	background-color: #b2ebf2!important
}

.cyan-text.text-lighten-4 {
	color: #b2ebf2!important
}

.cyan.lighten-3 {
	background-color: #80deea!important
}

.cyan-text.text-lighten-3 {
	color: #80deea!important
}

.cyan.lighten-2 {
	background-color: #4dd0e1!important
}

.cyan-text.text-lighten-2 {
	color: #4dd0e1!important
}

.cyan.lighten-1 {
	background-color: #26c6da!important
}

.cyan-text.text-lighten-1 {
	color: #26c6da!important
}

.cyan.darken-1 {
	background-color: #00acc1!important
}

.cyan-text.text-darken-1 {
	color: #00acc1!important
}

.cyan.darken-2 {
	background-color: #0097a7!important
}

.cyan-text.text-darken-2 {
	color: #0097a7!important
}

.cyan.darken-3 {
	background-color: #00838f!important
}

.cyan-text.text-darken-3 {
	color: #00838f!important
}

.cyan.darken-4 {
	background-color: #006064!important
}

.cyan-text.text-darken-4 {
	color: #006064!important
}

.cyan.accent-1 {
	background-color: #84ffff!important
}

.cyan-text.text-accent-1 {
	color: #84ffff!important
}

.cyan.accent-2 {
	background-color: #18ffff!important
}

.cyan-text.text-accent-2 {
	color: #18ffff!important
}

.cyan.accent-3 {
	background-color: #00e5ff!important
}

.cyan-text.text-accent-3 {
	color: #00e5ff!important
}

.cyan.accent-4 {
	background-color: #00b8d4!important
}

.cyan-text.text-accent-4 {
	color: #00b8d4!important
}

.teal {
	background-color: #009688!important
}

.teal-text {
	color: #009688!important
}

.teal.lighten-5 {
	background-color: #e0f2f1!important
}

.teal-text.text-lighten-5 {
	color: #e0f2f1!important
}

.teal.lighten-4 {
	background-color: #b2dfdb!important
}

.teal-text.text-lighten-4 {
	color: #b2dfdb!important
}

.teal.lighten-3 {
	background-color: #80cbc4!important
}

.teal-text.text-lighten-3 {
	color: #80cbc4!important
}

.teal.lighten-2 {
	background-color: #4db6ac!important
}

.teal-text.text-lighten-2 {
	color: #4db6ac!important
}

.teal.lighten-1 {
	background-color: #26a69a!important
}

.teal-text.text-lighten-1 {
	color: #26a69a!important
}

.teal.darken-1 {
	background-color: #00897b!important
}

.teal-text.text-darken-1 {
	color: #00897b!important
}

.teal.darken-2 {
	background-color: #00796b!important
}

.teal-text.text-darken-2 {
	color: #00796b!important
}

.teal.darken-3 {
	background-color: #00695c!important
}

.teal-text.text-darken-3 {
	color: #00695c!important
}

.teal.darken-4 {
	background-color: #004d40!important
}

.teal-text.text-darken-4 {
	color: #004d40!important
}

.teal.accent-1 {
	background-color: #a7ffeb!important
}

.teal-text.text-accent-1 {
	color: #a7ffeb!important
}

.teal.accent-2 {
	background-color: #64ffda!important
}

.teal-text.text-accent-2 {
	color: #64ffda!important
}

.teal.accent-3 {
	background-color: #1de9b6!important
}

.teal-text.text-accent-3 {
	color: #1de9b6!important
}

.teal.accent-4 {
	background-color: #00bfa5!important
}

.teal-text.text-accent-4 {
	color: #00bfa5!important
}

.green {
	background-color: #4caf50!important
}

.green-text {
	color: #4caf50!important
}

.green.lighten-5 {
	background-color: #e8f5e9!important
}

.green-text.text-lighten-5 {
	color: #e8f5e9!important
}

.green.lighten-4 {
	background-color: #c8e6c9!important
}

.green-text.text-lighten-4 {
	color: #c8e6c9!important
}

.green.lighten-3 {
	background-color: #a5d6a7!important
}

.green-text.text-lighten-3 {
	color: #a5d6a7!important
}

.green.lighten-2 {
	background-color: #81c784!important
}

.green-text.text-lighten-2 {
	color: #81c784!important
}

.green.lighten-1 {
	background-color: #66bb6a!important
}

.green-text.text-lighten-1 {
	color: #66bb6a!important
}

.green.darken-1 {
	background-color: #43a047!important
}

.green-text.text-darken-1 {
	color: #43a047!important
}

.green.darken-2 {
	background-color: #388e3c!important
}

.green-text.text-darken-2 {
	color: #388e3c!important
}

.green.darken-3 {
	background-color: #2e7d32!important
}

.green-text.text-darken-3 {
	color: #2e7d32!important
}

.green.darken-4 {
	background-color: #1b5e20!important
}

.green-text.text-darken-4 {
	color: #1b5e20!important
}

.green.accent-1 {
	background-color: #b9f6ca!important
}

.green-text.text-accent-1 {
	color: #b9f6ca!important
}

.green.accent-2 {
	background-color: #69f0ae!important
}

.green-text.text-accent-2 {
	color: #69f0ae!important
}

.green.accent-3 {
	background-color: #00e676!important
}

.green-text.text-accent-3 {
	color: #00e676!important
}

.green.accent-4 {
	background-color: #00c853!important
}

.green-text.text-accent-4 {
	color: #00c853!important
}

.light-green {
	background-color: #8bc34a!important
}

.light-green-text {
	color: #8bc34a!important
}

.light-green.lighten-5 {
	background-color: #f1f8e9!important
}

.light-green-text.text-lighten-5 {
	color: #f1f8e9!important
}

.light-green.lighten-4 {
	background-color: #dcedc8!important
}

.light-green-text.text-lighten-4 {
	color: #dcedc8!important
}

.light-green.lighten-3 {
	background-color: #c5e1a5!important
}

.light-green-text.text-lighten-3 {
	color: #c5e1a5!important
}

.light-green.lighten-2 {
	background-color: #aed581!important
}

.light-green-text.text-lighten-2 {
	color: #aed581!important
}

.light-green.lighten-1 {
	background-color: #9ccc65!important
}

.light-green-text.text-lighten-1 {
	color: #9ccc65!important
}

.light-green.darken-1 {
	background-color: #7cb342!important
}

.light-green-text.text-darken-1 {
	color: #7cb342!important
}

.light-green.darken-2 {
	background-color: #689f38!important
}

.light-green-text.text-darken-2 {
	color: #689f38!important
}

.light-green.darken-3 {
	background-color: #558b2f!important
}

.light-green-text.text-darken-3 {
	color: #558b2f!important
}

.light-green.darken-4 {
	background-color: #33691e!important
}

.light-green-text.text-darken-4 {
	color: #33691e!important
}

.light-green.accent-1 {
	background-color: #ccff90!important
}

.light-green-text.text-accent-1 {
	color: #ccff90!important
}

.light-green.accent-2 {
	background-color: #b2ff59!important
}

.light-green-text.text-accent-2 {
	color: #b2ff59!important
}

.light-green.accent-3 {
	background-color: #76ff03!important
}

.light-green-text.text-accent-3 {
	color: #76ff03!important
}

.light-green.accent-4 {
	background-color: #64dd17!important
}

.light-green-text.text-accent-4 {
	color: #64dd17!important
}

.lime {
	background-color: #cddc39!important
}

.lime-text {
	color: #cddc39!important
}

.lime.lighten-5 {
	background-color: #f9fbe7!important
}

.lime-text.text-lighten-5 {
	color: #f9fbe7!important
}

.lime.lighten-4 {
	background-color: #f0f4c3!important
}

.lime-text.text-lighten-4 {
	color: #f0f4c3!important
}

.lime.lighten-3 {
	background-color: #e6ee9c!important
}

.lime-text.text-lighten-3 {
	color: #e6ee9c!important
}

.lime.lighten-2 {
	background-color: #dce775!important
}

.lime-text.text-lighten-2 {
	color: #dce775!important
}

.lime.lighten-1 {
	background-color: #d4e157!important
}

.lime-text.text-lighten-1 {
	color: #d4e157!important
}

.lime.darken-1 {
	background-color: #c0ca33!important
}

.lime-text.text-darken-1 {
	color: #c0ca33!important
}

.lime.darken-2 {
	background-color: #afb42b!important
}

.lime-text.text-darken-2 {
	color: #afb42b!important
}

.lime.darken-3 {
	background-color: #9e9d24!important
}

.lime-text.text-darken-3 {
	color: #9e9d24!important
}

.lime.darken-4 {
	background-color: #827717!important
}

.lime-text.text-darken-4 {
	color: #827717!important
}

.lime.accent-1 {
	background-color: #f4ff81!important
}

.lime-text.text-accent-1 {
	color: #f4ff81!important
}

.lime.accent-2 {
	background-color: #eeff41!important
}

.lime-text.text-accent-2 {
	color: #eeff41!important
}

.lime.accent-3 {
	background-color: #c6ff00!important
}

.lime-text.text-accent-3 {
	color: #c6ff00!important
}

.lime.accent-4 {
	background-color: #aeea00!important
}

.lime-text.text-accent-4 {
	color: #aeea00!important
}

.yellow {
	background-color: #ffeb3b!important
}

.yellow-text {
	color: #ffeb3b!important
}

.yellow.lighten-5 {
	background-color: #fffde7!important
}

.yellow-text.text-lighten-5 {
	color: #fffde7!important
}

.yellow.lighten-4 {
	background-color: #fff9c4!important
}

.yellow-text.text-lighten-4 {
	color: #fff9c4!important
}

.yellow.lighten-3 {
	background-color: #fff59d!important
}

.yellow-text.text-lighten-3 {
	color: #fff59d!important
}

.yellow.lighten-2 {
	background-color: #fff176!important
}

.yellow-text.text-lighten-2 {
	color: #fff176!important
}

.yellow.lighten-1 {
	background-color: #ffee58!important
}

.yellow-text.text-lighten-1 {
	color: #ffee58!important
}

.yellow.darken-1 {
	background-color: #fdd835!important
}

.yellow-text.text-darken-1 {
	color: #fdd835!important
}

.yellow.darken-2 {
	background-color: #fbc02d!important
}

.yellow-text.text-darken-2 {
	color: #fbc02d!important
}

.yellow.darken-3 {
	background-color: #f9a825!important
}

.yellow-text.text-darken-3 {
	color: #f9a825!important
}

.yellow.darken-4 {
	background-color: #f57f17!important
}

.yellow-text.text-darken-4 {
	color: #f57f17!important
}

.yellow.accent-1 {
	background-color: #ffff8d!important
}

.yellow-text.text-accent-1 {
	color: #ffff8d!important
}

.yellow.accent-2 {
	background-color: #ff0!important
}

.yellow-text.text-accent-2 {
	color: #ff0!important
}

.yellow.accent-3 {
	background-color: #ffea00!important
}

.yellow-text.text-accent-3 {
	color: #ffea00!important
}

.yellow.accent-4 {
	background-color: #ffd600!important
}

.yellow-text.text-accent-4 {
	color: #ffd600!important
}

.amber {
	background-color: #ffc107!important
}

.amber-text {
	color: #ffc107!important
}

.amber.lighten-5 {
	background-color: #fff8e1!important
}

.amber-text.text-lighten-5 {
	color: #fff8e1!important
}

.amber.lighten-4 {
	background-color: #ffecb3!important
}

.amber-text.text-lighten-4 {
	color: #ffecb3!important
}

.amber.lighten-3 {
	background-color: #ffe082!important
}

.amber-text.text-lighten-3 {
	color: #ffe082!important
}

.amber.lighten-2 {
	background-color: #ffd54f!important
}

.amber-text.text-lighten-2 {
	color: #ffd54f!important
}

.amber.lighten-1 {
	background-color: #ffca28!important
}

.amber-text.text-lighten-1 {
	color: #ffca28!important
}

.amber.darken-1 {
	background-color: #ffb300!important
}

.amber-text.text-darken-1 {
	color: #ffb300!important
}

.amber.darken-2 {
	background-color: #ffa000!important
}

.amber-text.text-darken-2 {
	color: #ffa000!important
}

.amber.darken-3 {
	background-color: #ff8f00!important
}

.amber-text.text-darken-3 {
	color: #ff8f00!important
}

.amber.darken-4 {
	background-color: #ff6f00!important
}

.amber-text.text-darken-4 {
	color: #ff6f00!important
}

.amber.accent-1 {
	background-color: #ffe57f!important
}

.amber-text.text-accent-1 {
	color: #ffe57f!important
}

.amber.accent-2 {
	background-color: #ffd740!important
}

.amber-text.text-accent-2 {
	color: #ffd740!important
}

.amber.accent-3 {
	background-color: #ffc400!important
}

.amber-text.text-accent-3 {
	color: #ffc400!important
}

.amber.accent-4 {
	background-color: #ffab00!important
}

.amber-text.text-accent-4 {
	color: #ffab00!important
}

.orange {
	background-color: #ff9800!important
}

.orange-text {
	color: #ff9800!important
}

.orange.lighten-5 {
	background-color: #fff3e0!important
}

.orange-text.text-lighten-5 {
	color: #fff3e0!important
}

.orange.lighten-4 {
	background-color: #ffe0b2!important
}

.orange-text.text-lighten-4 {
	color: #ffe0b2!important
}

.orange.lighten-3 {
	background-color: #ffcc80!important
}

.orange-text.text-lighten-3 {
	color: #ffcc80!important
}

.orange.lighten-2 {
	background-color: #ffb74d!important
}

.orange-text.text-lighten-2 {
	color: #ffb74d!important
}

.orange.lighten-1 {
	background-color: #ffa726!important
}

.orange-text.text-lighten-1 {
	color: #ffa726!important
}

.orange.darken-1 {
	background-color: #fb8c00!important
}

.orange-text.text-darken-1 {
	color: #fb8c00!important
}

.orange.darken-2 {
	background-color: #f57c00!important
}

.orange-text.text-darken-2 {
	color: #f57c00!important
}

.orange.darken-3 {
	background-color: #ef6c00!important
}

.orange-text.text-darken-3 {
	color: #ef6c00!important
}

.orange.darken-4 {
	background-color: #e65100!important
}

.orange-text.text-darken-4 {
	color: #e65100!important
}

.orange.accent-1 {
	background-color: #ffd180!important
}

.orange-text.text-accent-1 {
	color: #ffd180!important
}

.orange.accent-2 {
	background-color: #ffab40!important
}

.orange-text.text-accent-2 {
	color: #ffab40!important
}

.orange.accent-3 {
	background-color: #ff9100!important
}

.orange-text.text-accent-3 {
	color: #ff9100!important
}

.orange.accent-4 {
	background-color: #ff6d00!important
}

.orange-text.text-accent-4 {
	color: #ff6d00!important
}

.deep-orange {
	background-color: #ff5722!important
}

.deep-orange-text {
	color: #ff5722!important
}

.deep-orange.lighten-5 {
	background-color: #fbe9e7!important
}

.deep-orange-text.text-lighten-5 {
	color: #fbe9e7!important
}

.deep-orange.lighten-4 {
	background-color: #ffccbc!important
}

.deep-orange-text.text-lighten-4 {
	color: #ffccbc!important
}

.deep-orange.lighten-3 {
	background-color: #ffab91!important
}

.deep-orange-text.text-lighten-3 {
	color: #ffab91!important
}

.deep-orange.lighten-2 {
	background-color: #ff8a65!important
}

.deep-orange-text.text-lighten-2 {
	color: #ff8a65!important
}

.deep-orange.lighten-1 {
	background-color: #ff7043!important
}

.deep-orange-text.text-lighten-1 {
	color: #ff7043!important
}

.deep-orange.darken-1 {
	background-color: #f4511e!important
}

.deep-orange-text.text-darken-1 {
	color: #f4511e!important
}

.deep-orange.darken-2 {
	background-color: #e64a19!important
}

.deep-orange-text.text-darken-2 {
	color: #e64a19!important
}

.deep-orange.darken-3 {
	background-color: #d84315!important
}

.deep-orange-text.text-darken-3 {
	color: #d84315!important
}

.deep-orange.darken-4 {
	background-color: #bf360c!important
}

.deep-orange-text.text-darken-4 {
	color: #bf360c!important
}

.deep-orange.accent-1 {
	background-color: #ff9e80!important
}

.deep-orange-text.text-accent-1 {
	color: #ff9e80!important
}

.deep-orange.accent-2 {
	background-color: #ff6e40!important
}

.deep-orange-text.text-accent-2 {
	color: #ff6e40!important
}

.deep-orange.accent-3 {
	background-color: #ff3d00!important
}

.deep-orange-text.text-accent-3 {
	color: #ff3d00!important
}

.deep-orange.accent-4 {
	background-color: #dd2c00!important
}

.deep-orange-text.text-accent-4 {
	color: #dd2c00!important
}

.brown {
	background-color: #795548!important
}

.brown-text {
	color: #795548!important
}

.brown.lighten-5 {
	background-color: #efebe9!important
}

.brown-text.text-lighten-5 {
	color: #efebe9!important
}

.brown.lighten-4 {
	background-color: #d7ccc8!important
}

.brown-text.text-lighten-4 {
	color: #d7ccc8!important
}

.brown.lighten-3 {
	background-color: #bcaaa4!important
}

.brown-text.text-lighten-3 {
	color: #bcaaa4!important
}

.brown.lighten-2 {
	background-color: #a1887f!important
}

.brown-text.text-lighten-2 {
	color: #a1887f!important
}

.brown.lighten-1 {
	background-color: #8d6e63!important
}

.brown-text.text-lighten-1 {
	color: #8d6e63!important
}

.brown.darken-1 {
	background-color: #6d4c41!important
}

.brown-text.text-darken-1 {
	color: #6d4c41!important
}

.brown.darken-2 {
	background-color: #5d4037!important
}

.brown-text.text-darken-2 {
	color: #5d4037!important
}

.brown.darken-3 {
	background-color: #4e342e!important
}

.brown-text.text-darken-3 {
	color: #4e342e!important
}

.brown.darken-4 {
	background-color: #3e2723!important
}

.brown-text.text-darken-4 {
	color: #3e2723!important
}

.blue-grey {
	background-color: #607d8b!important
}

.blue-grey-text {
	color: #607d8b!important
}

.blue-grey.lighten-5 {
	background-color: #eceff1!important
}

.blue-grey-text.text-lighten-5 {
	color: #eceff1!important
}

.blue-grey.lighten-4 {
	background-color: #cfd8dc!important
}

.blue-grey-text.text-lighten-4 {
	color: #cfd8dc!important
}

.blue-grey.lighten-3 {
	background-color: #b0bec5!important
}

.blue-grey-text.text-lighten-3 {
	color: #b0bec5!important
}

.blue-grey.lighten-2 {
	background-color: #90a4ae!important
}

.blue-grey-text.text-lighten-2 {
	color: #90a4ae!important
}

.blue-grey.lighten-1 {
	background-color: #78909c!important
}

.blue-grey-text.text-lighten-1 {
	color: #78909c!important
}

.blue-grey.darken-1 {
	background-color: #546e7a!important
}

.blue-grey-text.text-darken-1 {
	color: #546e7a!important
}

.blue-grey.darken-2 {
	background-color: #455a64!important
}

.blue-grey-text.text-darken-2 {
	color: #455a64!important
}

.blue-grey.darken-3 {
	background-color: #37474f!important
}

.blue-grey-text.text-darken-3 {
	color: #37474f!important
}

.blue-grey.darken-4 {
	background-color: #263238!important
}

.blue-grey-text.text-darken-4 {
	color: #263238!important
}

.grey {
	background-color: #9e9e9e!important
}

.grey-text {
	color: #9e9e9e!important
}

.grey.lighten-5 {
	background-color: #fafafa!important
}

.grey-text.text-lighten-5 {
	color: #fafafa!important
}

.grey.lighten-4 {
	background-color: #f5f5f5!important
}

.grey-text.text-lighten-4 {
	color: #f5f5f5!important
}

.grey.lighten-3 {
	background-color: #eee!important
}

.grey-text.text-lighten-3 {
	color: #eee!important
}

.grey.lighten-2 {
	background-color: #e0e0e0!important
}

.grey-text.text-lighten-2 {
	color: #e0e0e0!important
}

.grey.lighten-1 {
	background-color: #bdbdbd!important
}

.grey-text.text-lighten-1 {
	color: #bdbdbd!important
}

.grey.darken-1 {
	background-color: #757575!important
}

.grey-text.text-darken-1 {
	color: #757575!important
}

.grey.darken-2 {
	background-color: #616161!important
}

.grey-text.text-darken-2 {
	color: #616161!important
}

.grey.darken-3 {
	background-color: #424242!important
}

.grey-text.text-darken-3 {
	color: #424242!important
}

.grey.darken-4 {
	background-color: #212121!important
}

.grey-text.text-darken-4 {
	color: #212121!important
}

.black {
	background-color: #000!important
}

.black-text {
	color: #000!important
}

.white {
	background-color: #fff!important
}

.white-text {
	color: #fff!important
}

.transparent {
	background-color: transparent!important
}

.transparent-text {
	color: transparent!important
}

.gradient-45deg-light-blue-teal {
	background: -webkit-linear-gradient(45deg, #b3e5fc, #64ffda)!important;
	background: linear-gradient(45deg, #b3e5fc, #64ffda)!important
}

.gradient-45deg-light-blue-teal.gradient-shadow {
	box-shadow: 0 6px 20px 0 rgba(100, 255, 218, .5)!important
}

.gradient-45deg-indigo-light-blue {
	background: -webkit-linear-gradient(45deg, #3949ab, #4fc3f7)!important;
	background: linear-gradient(45deg, #3949ab, #4fc3f7)!important
}

.gradient-45deg-indigo-light-blue.gradient-shadow {
	box-shadow: 0 6px 20px 0 rgba(79, 195, 247, .5)!important
}

.gradient-45deg-light-blue-indigo {
	background: -webkit-linear-gradient(45deg, #b3e5fc, #9fa8da)!important;
	background: linear-gradient(45deg, #b3e5fc, #9fa8da)!important
}

.gradient-45deg-light-blue-indigo.gradient-shadow {
	box-shadow: 0 6px 20px 0 rgba(159, 168, 218, .5)!important
}

.gradient-45deg-yellow-green {
	background: -webkit-linear-gradient(45deg, #ffff8d, #b9f6ca)!important;
	background: linear-gradient(45deg, #ffff8d, #b9f6ca)!important
}

.gradient-45deg-yellow-green.gradient-shadow {
	box-shadow: 0 6px 20px 0 rgba(185, 246, 202, .5)!important
}

.gradient-45deg-orange-deep-orange {
	background: -webkit-linear-gradient(45deg, #ffe0b2, #ffccbc)!important;
	background: linear-gradient(45deg, #ffe0b2, #ffccbc)!important
}

.gradient-45deg-orange-deep-orange.gradient-shadow {
	box-shadow: 0 6px 20px 0 rgba(255, 204, 188, .5)!important
}

.gradient-45deg-deep-purple-purple {
	background: -webkit-linear-gradient(45deg, #d1c4e9, #f3e5f5)!important;
	background: linear-gradient(45deg, #d1c4e9, #f3e5f5)!important
}

.gradient-45deg-deep-purple-purple.gradient-shadow {
	box-shadow: 0 6px 20px 0 rgba(243, 229, 245, .5)!important
}

.gradient-45deg-red-pink {
	background: -webkit-linear-gradient(45deg, #ff5252, #f48fb1)!important;
	background: linear-gradient(45deg, #ff5252, #f48fb1)!important
}

.gradient-45deg-red-pink.gradient-shadow {
	box-shadow: 0 6px 20px 0 rgba(244, 143, 177, .5)!important
}

.gradient-45deg-light-green-amber {
	background: -webkit-linear-gradient(45deg, #c5e1a5, #fff8e1)!important;
	background: linear-gradient(45deg, #c5e1a5, #fff8e1)!important
}

.gradient-45deg-light-green-amber.gradient-shadow {
	box-shadow: 0 6px 20px 0 rgba(255, 248, 225, .5)!important
}

.gradient-45deg-amber-amber {
	background: -webkit-linear-gradient(45deg, #ff6f00, #ffca28)!important;
	background: linear-gradient(45deg, #ff6f00, #ffca28)!important
}

.gradient-45deg-amber-amber.gradient-shadow {
	box-shadow: 0 6px 20px 0 rgba(255, 202, 40, .5)!important
}

.gradient-45deg-purple-pink {
	background: -webkit-linear-gradient(45deg, #ea80fc, #fce4ec)!important;
	background: linear-gradient(45deg, #ea80fc, #fce4ec)!important
}

.gradient-45deg-purple-pink.gradient-shadow {
	box-shadow: 0 6px 20px 0 rgba(252, 228, 236, .5)!important
}

.gradient-45deg-teal-cyan {
	background: -webkit-linear-gradient(45deg, #e0f2f1, #00e5ff)!important;
	background: linear-gradient(45deg, #e0f2f1, #00e5ff)!important
}

.gradient-45deg-teal-cyan.gradient-shadow {
	box-shadow: 0 6px 20px 0 rgba(0, 229, 255, .5)!important
}

.gradient-45deg-blue-grey-blue-grey {
	background: -webkit-linear-gradient(45deg, #cfd8dc, #546e7a)!important;
	background: linear-gradient(45deg, #cfd8dc, #546e7a)!important
}

.gradient-45deg-blue-grey-blue-grey.gradient-shadow {
	box-shadow: 0 6px 20px 0 rgba(84, 110, 122, .5)!important
}

.gradient-45deg-indigo-blue.gradient-shadow,
.gradient-45deg-orange-amber.gradient-shadow {
	box-shadow: 0 6px 20px 0 rgba(25, 118, 210, .5)!important
}

.gradient-45deg-orange-amber {
	background: -webkit-linear-gradient(45deg, #e65100, #1976d2)!important;
	background: linear-gradient(45deg, #e65100, #1976d2)!important
}

.gradient-45deg-indigo-blue {
	background: -webkit-linear-gradient(45deg, #303f9f, #1976d2)!important;
	background: linear-gradient(45deg, #303f9f, #1976d2)!important
}

.gradient-45deg-brown-brown {
	background: -webkit-linear-gradient(45deg, #6d4c41, #bbdefb)!important;
	background: linear-gradient(45deg, #6d4c41, #bbdefb)!important
}

.gradient-45deg-brown-brown.gradient-shadow {
	box-shadow: 0 6px 20px 0 rgba(187, 222, 251, .5)!important
}

.gradient-45deg-blue-grey-blue {
	background: -webkit-linear-gradient(45deg, #263238, #2979ff)!important;
	background: linear-gradient(45deg, #263238, #2979ff)!important
}

.gradient-45deg-blue-grey-blue.gradient-shadow {
	box-shadow: 0 6px 20px 0 rgba(41, 121, 255, .5)!important
}

.gradient-45deg-purple-deep-orange {
	background: -webkit-linear-gradient(45deg, #8e24aa, #ff6e40)!important;
	background: linear-gradient(45deg, #8e24aa, #ff6e40)!important
}

.gradient-45deg-purple-deep-orange.gradient-shadow {
	box-shadow: 0 6px 20px 0 rgba(255, 110, 64, .5)!important
}

.gradient-45deg-green-teal {
	background: -webkit-linear-gradient(45deg, #43a047, #1de9b6)!important;
	background: linear-gradient(45deg, #43a047, #1de9b6)!important
}

.gradient-45deg-green-teal.gradient-shadow {
	box-shadow: 0 6px 20px 0 rgba(29, 233, 182, .5)!important
}

.gradient-45deg-purple-light-blue {
	background: -webkit-linear-gradient(45deg, #e040fb, #4fc3f7)!important;
	background: linear-gradient(45deg, #e040fb, #4fc3f7)!important
}

.gradient-45deg-purple-light-blue.gradient-shadow {
	box-shadow: 0 6px 20px 0 rgba(79, 195, 247, .5)!important
}

.gradient-45deg-cyan-cyan {
	background: -webkit-linear-gradient(45deg, #18ffff, #00e5ff)!important;
	background: linear-gradient(45deg, #18ffff, #00e5ff)!important
}

.gradient-45deg-cyan-cyan.gradient-shadow {
	box-shadow: 0 6px 20px 0 rgba(0, 229, 255, .5)!important
}

.gradient-45deg-yellow-teal {
	background: -webkit-linear-gradient(45deg, #fff9c4, #64ffda)!important;
	background: linear-gradient(45deg, #fff9c4, #64ffda)!important
}

.gradient-45deg-yellow-teal.gradient-shadow {
	box-shadow: 0 6px 20px 0 rgba(100, 255, 218, .5)!important
}

.gradient-45deg-purple-deep-purple {
	background: -webkit-linear-gradient(45deg, #7b1fa2, #7c4dff)!important;
	background: linear-gradient(45deg, #7b1fa2, #7c4dff)!important
}

.gradient-45deg-purple-deep-purple.gradient-shadow {
	box-shadow: 0 6px 20px 0 rgba(124, 77, 255, .5)!important
}

.gradient-45deg-cyan-light-green {
	background: -webkit-linear-gradient(45deg, #0097a7, #b2ff59)!important;
	background: linear-gradient(45deg, #0097a7, #b2ff59)!important
}

.gradient-45deg-cyan-light-green.gradient-shadow {
	box-shadow: 0 6px 20px 0 rgba(178, 255, 89, .5)!important
}

.gradient-45deg-purple-amber {
	background: -webkit-linear-gradient(45deg, #d500f9, #ffa000)!important;
	background: linear-gradient(45deg, #d500f9, #ffa000)!important
}

.gradient-45deg-purple-amber.gradient-shadow {
	box-shadow: 0 6px 20px 0 rgba(255, 160, 0, .5)!important
}

.gradient-45deg-indigo-purple {
	background: -webkit-linear-gradient(45deg, #303f9f, #7b1fa2)!important;
	background: linear-gradient(45deg, #303f9f, #7b1fa2)!important
}

.gradient-45deg-indigo-purple.gradient-shadow {
	box-shadow: 0 6px 20px 0 rgba(123, 31, 162, .5)!important
}

.gradient-45deg-deep-purple-blue {
	background: -webkit-linear-gradient(45deg, #6200ea, #1976d2)!important;
	background: linear-gradient(45deg, #6200ea, #1976d2)!important
}

.gradient-45deg-deep-purple-blue.gradient-shadow {
	box-shadow: 0 6px 20px 0 rgba(25, 118, 210, .5)!important
}

.gradient-45deg-deep-orange-orange {
	background: -webkit-linear-gradient(45deg, #bf360c, #f57c00)!important;
	background: linear-gradient(45deg, #bf360c, #f57c00)!important
}

.gradient-45deg-deep-orange-orange.gradient-shadow {
	box-shadow: 0 6px 20px 0 rgba(245, 124, 0, .5)!important
}

.gradient-45deg-light-blue-cyan {
	background: -webkit-linear-gradient(45deg, #0288d1, #26c6da)!important;
	background: linear-gradient(45deg, #0288d1, #26c6da)!important
}

.gradient-45deg-light-blue-cyan.gradient-shadow {
	box-shadow: 0 6px 20px 0 rgba(38, 198, 218, .5)!important
}

.gradient-45deg-blue-indigo {
	background: -webkit-linear-gradient(45deg, #2962ff, #3949ab)!important;
	background: linear-gradient(45deg, #2962ff, #3949ab)!important
}

.gradient-45deg-blue-indigo.gradient-shadow {
	box-shadow: 0 6px 20px 0 rgba(57, 73, 171, .5)!important
}

.gradient-45deg-semi-dark {
	background: -webkit-linear-gradient(right, #00aeff, #3369e6)!important;
	background: linear-gradient(-90deg, #00aeff, #3369e6)!important
}

.gradient-45deg-semi-dark.gradient-shadow {
	box-shadow: 0 6px 20px 0 rgba(51, 105, 230, .5)!important
}

.btn-light-amber,
.btn-light-blue,
.btn-light-blue-grey,
.btn-light-brown,
.btn-light-cyan,
.btn-light-deep-orange,
.btn-light-deep-purple,
.btn-light-green,
.btn-light-grey,
.btn-light-indigo,
.btn-light-light-blue,
.btn-light-light-green,
.btn-light-lime,
.btn-light-materialize-red,
.btn-light-orange,
.btn-light-pink,
.btn-light-purple,
.btn-light-red,
.btn-light-yellow {
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .1), 0 3px 1px -2px transparent, 0 1px 5px 0 rgba(0, 0, 0, .08)
}

.btn-light-materialize-red {
	color: #e51c23!important;
	background-color: #fdeaeb
}

.btn-light-materialize-red.hover,
.btn-light-materialize-red:focus,
.btn-light-materialize-red:hover {
	color: #fff!important;
	background-color: #ea454b!important
}

.btn-light-materialize-red.active,
.btn-light-materialize-red:active {
	color: #fff!important;
	background-color: #d0181e!important
}

.widget-timeline li.timeline-items.timeline-icon-materialize-red:before {
	background: #e51c23!important
}

.btn-light-red {
	color: #f44336!important;
	background-color: #ffebee
}

.btn-light-red.hover,
.btn-light-red:focus,
.btn-light-red:hover {
	color: #fff!important;
	background-color: #ef5350!important
}

.btn-light-red.active,
.btn-light-red:active {
	color: #fff!important;
	background-color: #e53935!important
}

.widget-timeline li.timeline-items.timeline-icon-red:before {
	background: #f44336!important
}

.btn-light-pink {
	color: #e91e63!important;
	background-color: #fce4ec
}

.btn-light-pink.hover,
.btn-light-pink:focus,
.btn-light-pink:hover {
	color: #fff!important;
	background-color: #ec407a!important
}

.btn-light-pink.active,
.btn-light-pink:active {
	color: #fff!important;
	background-color: #d81b60!important
}

.widget-timeline li.timeline-items.timeline-icon-pink:before {
	background: #e91e63!important
}

.btn-light-purple {
	color: #9c27b0!important;
	background-color: #f3e5f5
}

.btn-light-purple.hover,
.btn-light-purple:focus,
.btn-light-purple:hover {
	color: #fff!important;
	background-color: #ab47bc!important
}

.btn-light-purple.active,
.btn-light-purple:active {
	color: #fff!important;
	background-color: #8e24aa!important
}

.widget-timeline li.timeline-items.timeline-icon-purple:before {
	background: #9c27b0!important
}

.btn-light-deep-purple {
	color: #673ab7!important;
	background-color: #ede7f6
}

.btn-light-deep-purple.hover,
.btn-light-deep-purple:focus,
.btn-light-deep-purple:hover {
	color: #fff!important;
	background-color: #7e57c2!important
}

.btn-light-deep-purple.active,
.btn-light-deep-purple:active {
	color: #fff!important;
	background-color: #5e35b1!important
}

.widget-timeline li.timeline-items.timeline-icon-deep-purple:before {
	background: #673ab7!important
}

.btn-light-indigo {
	color: #3f51b5!important;
	background-color: #e8eaf6
}

.btn-light-indigo.hover,
.btn-light-indigo:focus,
.btn-light-indigo:hover {
	color: #fff!important;
	background-color: #5c6bc0!important
}

.btn-light-indigo.active,
.btn-light-indigo:active {
	color: #fff!important;
	background-color: #3949ab!important
}

.widget-timeline li.timeline-items.timeline-icon-indigo:before {
	background: #3f51b5!important
}

.btn-light-blue {
	color: #2196f3!important;
	background-color: #e3f2fd
}

.btn-light-blue.hover,
.btn-light-blue:focus,
.btn-light-blue:hover {
	color: #fff!important;
	background-color: #42a5f5!important
}

.btn-light-blue.active,
.btn-light-blue:active {
	color: #fff!important;
	background-color: #1e88e5!important
}

.widget-timeline li.timeline-items.timeline-icon-blue:before {
	background: #2196f3!important
}

.btn-light-light-blue {
	color: #03a9f4!important;
	background-color: #e1f5fe
}

.btn-light-light-blue.hover,
.btn-light-light-blue:focus,
.btn-light-light-blue:hover {
	color: #fff!important;
	background-color: #29b6f6!important
}

.btn-light-light-blue.active,
.btn-light-light-blue:active {
	color: #fff!important;
	background-color: #039be5!important
}

.widget-timeline li.timeline-items.timeline-icon-light-blue:before {
	background: #03a9f4!important
}

.btn-light-cyan {
	color: #00bcd4!important;
	background-color: #e0f7fa
}

.btn-light-cyan.hover,
.btn-light-cyan:focus,
.btn-light-cyan:hover {
	color: #fff!important;
	background-color: #26c6da!important
}

.btn-light-cyan.active,
.btn-light-cyan:active {
	color: #fff!important;
	background-color: #00acc1!important
}

.widget-timeline li.timeline-items.timeline-icon-cyan:before {
	background: #00bcd4!important
}

.btn-light-teal {
	color: #009688!important;
	background-color: #e0f2f1;
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .1), 0 3px 1px -2px transparent, 0 1px 5px 0 rgba(0, 0, 0, .08)
}

.btn-light-teal.hover,
.btn-light-teal:focus,
.btn-light-teal:hover {
	color: #fff!important;
	background-color: #26a69a!important
}

.btn-light-teal.active,
.btn-light-teal:active {
	color: #fff!important;
	background-color: #00897b!important
}

.widget-timeline li.timeline-items.timeline-icon-teal:before {
	background: #009688!important
}

.btn-light-green {
	color: #4caf50!important;
	background-color: #e8f5e9
}

.btn-light-green.hover,
.btn-light-green:focus,
.btn-light-green:hover {
	color: #fff!important;
	background-color: #66bb6a!important
}

.btn-light-green.active,
.btn-light-green:active {
	color: #fff!important;
	background-color: #43a047!important
}

.widget-timeline li.timeline-items.timeline-icon-green:before {
	background: #4caf50!important
}

.btn-light-light-green {
	color: #8bc34a!important;
	background-color: #f1f8e9
}

.btn-light-light-green.hover,
.btn-light-light-green:focus,
.btn-light-light-green:hover {
	color: #fff!important;
	background-color: #9ccc65!important
}

.btn-light-light-green.active,
.btn-light-light-green:active {
	color: #fff!important;
	background-color: #7cb342!important
}

.widget-timeline li.timeline-items.timeline-icon-light-green:before {
	background: #8bc34a!important
}

.btn-light-lime {
	color: #cddc39!important;
	background-color: #f9fbe7
}

.btn-light-lime.hover,
.btn-light-lime:focus,
.btn-light-lime:hover {
	color: #fff!important;
	background-color: #d4e157!important
}

.btn-light-lime.active,
.btn-light-lime:active {
	color: #fff!important;
	background-color: #c0ca33!important
}

.widget-timeline li.timeline-items.timeline-icon-lime:before {
	background: #cddc39!important
}

.btn-light-yellow {
	color: #ffeb3b!important;
	background-color: #fffde7
}

.btn-light-yellow.hover,
.btn-light-yellow:focus,
.btn-light-yellow:hover {
	color: #fff!important;
	background-color: #ffee58!important
}

.btn-light-yellow.active,
.btn-light-yellow:active {
	color: #fff!important;
	background-color: #fdd835!important
}

.widget-timeline li.timeline-items.timeline-icon-yellow:before {
	background: #ffeb3b!important
}

.btn-light-amber {
	color: #ffc107!important;
	background-color: #fff8e1
}

.btn-light-amber.hover,
.btn-light-amber:focus,
.btn-light-amber:hover {
	color: #fff!important;
	background-color: #ffca28!important
}

.btn-light-amber.active,
.btn-light-amber:active {
	color: #fff!important;
	background-color: #ffb300!important
}

.widget-timeline li.timeline-items.timeline-icon-amber:before {
	background: #ffc107!important
}

.btn-light-orange {
	color: #ff9800!important;
	background-color: #fff3e0
}

.btn-light-orange.hover,
.btn-light-orange:focus,
.btn-light-orange:hover {
	color: #fff!important;
	background-color: #ffa726!important
}

.btn-light-orange.active,
.btn-light-orange:active {
	color: #fff!important;
	background-color: #fb8c00!important
}

.widget-timeline li.timeline-items.timeline-icon-orange:before {
	background: #ff9800!important
}

.btn-light-deep-orange {
	color: #ff5722!important;
	background-color: #fbe9e7
}

.btn-light-deep-orange.hover,
.btn-light-deep-orange:focus,
.btn-light-deep-orange:hover {
	color: #fff!important;
	background-color: #ff7043!important
}

.btn-light-deep-orange.active,
.btn-light-deep-orange:active {
	color: #fff!important;
	background-color: #f4511e!important
}

.widget-timeline li.timeline-items.timeline-icon-deep-orange:before {
	background: #ff5722!important
}

.btn-light-brown {
	color: #795548!important;
	background-color: #efebe9
}

.btn-light-brown.hover,
.btn-light-brown:focus,
.btn-light-brown:hover {
	color: #fff!important;
	background-color: #8d6e63!important
}

.btn-light-brown.active,
.btn-light-brown:active {
	color: #fff!important;
	background-color: #6d4c41!important
}

.widget-timeline li.timeline-items.timeline-icon-brown:before {
	background: #795548!important
}

.btn-light-blue-grey {
	color: #607d8b!important;
	background-color: #eceff1
}

.btn-light-blue-grey.hover,
.btn-light-blue-grey:focus,
.btn-light-blue-grey:hover {
	color: #fff!important;
	background-color: #78909c!important
}

.btn-light-blue-grey.active,
.btn-light-blue-grey:active {
	color: #fff!important;
	background-color: #546e7a!important
}

.widget-timeline li.timeline-items.timeline-icon-blue-grey:before {
	background: #607d8b!important
}

.btn-light-grey {
	color: #9e9e9e!important;
	background-color: #fafafa
}

.btn-light-grey.hover,
.btn-light-grey:focus,
.btn-light-grey:hover {
	color: #fff!important;
	background-color: #bdbdbd!important
}

.btn-light-grey.active,
.btn-light-grey:active {
	color: #fff!important;
	background-color: #757575!important
}

.widget-timeline li.timeline-items.timeline-icon-grey:before {
	background: #9e9e9e!important
}

.avatar-status {
	position: relative;
    display: inline-flex;
	align-items: center;
    width: 40px;
    height: 40px;
    vertical-align: bottom;
    border-radius: 100%;
    margin-right: 10px;
	border: 1px solid #d2d2d2;
	background-color: rgba(0, 0, 0, 0);
}

.avatar-status i {
	position: absolute;
	right: -2px;
	bottom: 18px;
	width: 9px!important;
	height: 9px!important;
	border: 1px solid #fff;
	border-radius: 100%
}

.avatar-status span.text-circle {
	font-size: 1.2rem;
	display: table-cell;
	text-align: center;
	vertical-align: middle;
	color: #fff;
	background: #fafafa
}

.avatar-status img {
	width: 100%;
	max-width: 100%;
	height: auto;
	border: 0;
	border-radius: 1000px;
	background: #e6e6e6
}

.avatar-online i {
	background-color: #00e676
}

.avatar-off i {
	background-color: #bdbdbd
}

.avatar-busy i {
	background-color: #ff1744
}

.avatar-away i {
	background-color: #ffc400
}

.avatar-status.avatar-100 {
	width: 100px
}

.avatar-status.avatar-100 span.text-circle {
	width: 100px;
	height: 100px
}

.avatar-status.avatar-100 i {
	width: 20px;
	height: 20px
}

.avatar-status.avatar-50 {
	line-height: 0;
	width: 50px
}

.avatar-status.avatar-50 span.text-circle {
	width: 50px;
	height: 50px
}

.avatar-status.avatar-50 i {
	right: -1px;
	bottom: 21px;
	width: 12px!important;
	height: 12px!important
}

.avatar-status.avatar-lg {
	width: 144px
}

.avatar-status.avatar-lg span.text-circle {
	width: 144px;
	height: 144px
}

.avatar-status.avatar-lg i {
	right: 0;
	bottom: 0;
	width: 20px;
	height: 20px
}

.avatar-status.avatar-md {
	width: 40px
}

.avatar-status.avatar-md span.text-circle {
	width: 40px;
	height: 40px
}

.avatar-status.avatar-md i {
	right: 0;
	bottom: 0;
	width: 10px;
	height: 10px
}

.avatar-status.avatar-sm {
	width: 32px
}

.avatar-status.avatar-sm span.text-circle {
	width: 32px;
	height: 32px
}

.avatar-status.avatar-sm i {
	width: 8px;
	height: 8px
}

.avatar-status.avatar-xs {
	width: 24px
}

.avatar-status.avatar-xs span.text-circle {
	width: 24px;
	height: 24px
}

.avatar-status.avatar-xs i {
	width: 7px;
	height: 7px
}

.collection .collection-item.avatar:not(.circle-clipper)>.circle {
	background: #e6e6e6
}

.dropdown-content li.active,
.dropdown-content li:hover,
.dropdown-content li>a.active,
.dropdown-content li>a:hover,
.dropdown-content li>span.active,
.dropdown-content li>span:hover {
	background-color: #eee
}

.dropdown-content li>a,
.dropdown-content li>span {
	padding: 14px 24px
}

/* .dropdown-content li>a>i {
	width: 24px;
	margin: 0 12px 0 0
} */

span.badge {
	color: #fff
}

span.badge.pill {
	font-size: .8rem;
	line-height: 20px;
	min-width: 1rem;
	height: 20px;
	border-radius: 9px
}

#badges-navbar nav .brand-logo {
	font-size: 2.1rem
}

.btn,
.btn-flat,
.btn-large,
.btn-small {
	text-transform: capitalize
}

.btn-block {
	display: block;
	width: 100%
}

.material-icons {
	font-family: 'Material Icons';
	font-size: 24px;
	font-weight: 400;
	font-style: normal;
	line-height: 1;
	display: inline-block;
	letter-spacing: normal;
	text-transform: none;
	word-wrap: normal;
	direction: ltr;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-feature-settings: 'liga';
	font-feature-settings: 'liga'
}

.material-icons.md-18 {
	font-size: 18px
}

.material-icons.md-24 {
	font-size: 24px
}

.material-icons.md-36 {
	font-size: 36px
}

.material-icons.md-48 {
	font-size: 48px
}

.material-icons.md-dark {
	color: rgba(0, 0, 0, .54)
}

.material-icons.md-dark.md-inactive {
	color: rgba(0, 0, 0, .26)
}

.material-icons.md-light {
	color: #fff
}

.material-icons.md-light.md-inactive {
	color: rgba(255, 255, 255, .3)
}

.pulse-style-one {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around
}

@media screen and (-ms-high-contrast:active),
(-ms-high-contrast:none) {
	.modal {
		position: -ms-page;
		top: 10%
	}
	.modal.bottom-sheet {
		position: -ms-page;
		top: 155%;
		height: 100%
	}
	.modal.bottom-sheet+.modal-overlay {
		position: -ms-page;
		top: 100%
	}
	.modal-overlay {
		position: -ms-page;
		top: 5%;
		height: 100%
	}
}

.navbar {
	position: relative;
	z-index: 1046;
}

.navbar .header-search-wrapper {
	position: relative;
	display: inline-block;
	width: calc(100% - 360px);
	height: 40px;
	margin: 10px auto 0 75px;
	-webkit-transition: .3s ease all;
	transition: .3s ease all;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden
}

.navbar .header-search-wrapper i {
	font-size: 24px;
	line-height: 32px!important;
	position: absolute;
	top: 6px;
	left: 24px;
	-webkit-transition: color .2s ease;
	transition: color .2s ease
}

.navbar .sideNav-lock .header-search-wrapper {
	width: calc(100% - 560px);
	margin: 10px auto 0 270px
}

.navbar input.header-search-input {
	font-size: 16px;
	font-weight: 400;
	display: block;
	box-sizing: border-box;
	width: 100%;
	margin-bottom: 0;
	padding: 8px 8px 8px 65px;
	-webkit-transition: all .2s ease;
	transition: all .2s ease;
	border: none;
	border-radius: 3px;
	outline: 0;
	background: rgba(255, 255, 255, .3);
	-webkit-appearance: textfield;
	-moz-appearance: textfield;
	appearance: textfield
}

.navbar input.header-search-input:hover {
	background: rgba(255, 255, 255, .5)
}

.navbar input.header-search-input:active,
.navbar input.header-search-input:focus {
	color: #333!important;
	border-bottom: none!important;
	background: #fff!important;
	box-shadow: none!important
}

.navbar .header-search-wrapper-focus i {
	color: #444!important
}

.navbar .header-search-input:focus::-webkit-input-placeholder {
	font-size: 16px;
	font-weight: 400;
	color: #333
}

.navbar .header-search-input:focus::-moz-placeholder {
	font-size: 16px;
	font-weight: 400;
	color: #333
}

.navbar .header-search-input:focus::-ms-input-placeholder {
	font-size: 16px;
	font-weight: 400;
	color: #333
}

.navbar .header-search-input:focus::placeholder {
	font-size: 16px;
	font-weight: 400;
	color: #333
}

.navbar .header-search-input:focus:-ms-input-placeholder {
	font-size: 16px;
	font-weight: 400;
	color: #333
}

.navbar .header-search-input::-webkit-input-placeholder {
	font-size: 16px;
	font-weight: 400;
	color: #fff
}

.navbar .header-search-input::-moz-placeholder {
	font-size: 16px;
	font-weight: 400;
	color: #fff
}

.navbar .header-search-input:-ms-input-placeholder {
	font-size: 16px;
	font-weight: 400;
	color: #fff
}

.navbar .header-search-input::-ms-input-placeholder {
	font-size: 16px;
	font-weight: 400;
	color: #fff
}

.navbar .header-search-input::placeholder {
	font-size: 16px;
	font-weight: 400;
	color: #fff
}

.navbar .notification-button,
.navbar .toggle-fullscreen {
	line-height: 1
}

.navbar .notification-badge {
	position: relative;
	top: -20px;
	right: 5px;
	margin: 0 -.8em;
	padding: 2px 5px;
	color: #fff;
	border-radius: 50%;
	background-color: #ff4081;
	box-shadow: 0 0 10px 0 #ff4081
}

.navbar #notifications-dropdown {
	width: 400px
}

.navbar #notifications-dropdown h5 {
	font-size: 1rem;
	font-weight: 500;
	text-transform: capitalize
}

.navbar #notifications-dropdown li {
	font-size: 1rem;
	padding: 8px 16px
}

.navbar #notifications-dropdown li>a {
	font-size: 1.1rem;
	font-weight: 300;
	padding: 0
}

.navbar #notifications-dropdown li>a>span {
	font-size: 1.2rem;
	position: relative;
	top: 4px;
	display: inline-block;
	margin-right: 5px
}

.navbar #notifications-dropdown li>time {
	font-size: .8rem;
	font-weight: 400;
	position: relative;
	top: -4px;
	margin-left: 38px
}

.navbar #notifications-dropdown li.divider {
	padding: 0
}

.navbar #profile-dropdown {
	width: 160px
}

.navbar #profile-dropdown li a i {
	margin-right: 10px
}

.navbar .navbar-light {
	background: #fff
}

.navbar .navbar-light .header-search-wrapper i,
.navbar .navbar-light ul a {
	color: rgba(0, 0, 0, .87)
}

.navbar .navbar-light .header-search-wrapper input.header-search-input {
	color: rgba(0, 0, 0, .87);
	border: 1px solid #efefef;
	background: rgba(230, 230, 230, .3)
}

.navbar .navbar-light .header-search-wrapper input.header-search-input:active,
.navbar .navbar-light .header-search-wrapper input.header-search-input:focus,
.navbar .navbar-light .header-search-wrapper input.header-search-input:hover {
	border: 1px solid #adadad;
	border-bottom: 1px solid #adadad!important;
	background: rgba(230, 230, 230, 0)
}

.navbar .navbar-light .header-search-wrapper input.header-search-input::-webkit-input-placeholder {
	color: rgba(0, 0, 0, .87)
}

.navbar .navbar-light .header-search-wrapper input.header-search-input::-moz-placeholder {
	color: rgba(0, 0, 0, .87)
}

.navbar .navbar-light .header-search-wrapper input.header-search-input:-ms-input-placeholder {
	color: rgba(0, 0, 0, .87)
}

.navbar .navbar-light .header-search-wrapper input.header-search-input::-ms-input-placeholder {
	color: rgba(0, 0, 0, .87)
}

.navbar .navbar-light .header-search-wrapper input.header-search-input::placeholder {
	color: rgba(0, 0, 0, .87)
}

.navbar .navbar-dark {
	background: #37474f
}

.navbar .navbar-dark .header-search-wrapper i,
.navbar .navbar-dark ul a {
	color: #fff
}

.navbar .navbar-dark .header-search-wrapper input.header-search-input {
	color: #fff;
	border: 1px solid rgba(230, 230, 230, .05);
	background: rgba(230, 230, 230, .3)
}

.navbar .navbar-dark .header-search-wrapper input.header-search-input:active,
.navbar .navbar-dark .header-search-wrapper input.header-search-input:focus,
.navbar .navbar-dark .header-search-wrapper input.header-search-input:hover {
	border: 1px solid rgba(230, 230, 230, .1);
	background: rgba(230, 230, 230, .1)
}

.navbar .navbar-dark .header-search-wrapper input.header-search-input::-webkit-input-placeholder {
	color: #fff
}

.navbar .navbar-dark .header-search-wrapper input.header-search-input::-moz-placeholder {
	color: #fff
}

.navbar .navbar-dark .header-search-wrapper input.header-search-input:-ms-input-placeholder {
	color: #fff
}

.navbar .navbar-dark .header-search-wrapper input.header-search-input::-ms-input-placeholder {
	color: #fff
}

.navbar .navbar-dark .header-search-wrapper input.header-search-input::placeholder {
	color: #fff
}

.navbar .no-shadow {
	box-shadow: none
}

.horizontal-layout .navbar .header-search-wrapper {
	width: calc(100% - 560px)
}

.navbar .navbar-main {
	padding: 0 8px 0 15px
}

.search-list {
	position: absolute;
	top: 100%;
	height: calc(100vh - 9rem);
	max-height: 450px;
	margin-top: 0;
	border: none;
	border-radius: 0 0 3px 3px
}

.sidenav,
.sidenav-dark.sidenav-main .collapsible {
	border: none
}

.search-list li {
	width: 100%
}

.search-list li a {
	padding: 8px 20px 5px!important
}

.search-list li a .search-title {
	font-size: .9rem;
	font-weight: 700;
	margin: .8rem 0 4px;
	color: #3f51b5
}

.search-list li a span {
	font-size: 1rem;
	line-height: .9;
	vertical-align: middle;
	color: #9e9e9e
}

.search-list li a span.material-icons {
	font-size: 1.4rem;
	line-height: 1.2
}

.search-list li a .member-info {
	margin-left: .8rem
}

.search-list li a .member-info span:hover {
	color: #3f51b5!important
}

.search-list li a .status small:not(:first-child) {
	color: #757575
}

.search-list li.current_item a {
	background-color: #eee
}

.search-list li.auto-suggestion-title,
.search-list li.auto-suggestion-title a,
.search-list li.auto-suggestion-title a:hover {
	background-color: #fff!important
}

.content-overlay {
	position: fixed;
	z-index: -1;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	visibility: hidden;
	width: 100%;
	height: 100%;
	cursor: pointer;
	-webkit-transition: all .7s;
	transition: all .7s
}

.content-overlay.show {
	z-index: 995;
	visibility: visible;
	background-color: rgba(0, 0, 0, .5)
}

nav.display-none.search-sm {
	position: absolute;
	background-color: #fff
}

nav.display-none.search-sm .nav-wrapper form .input-field input.search-box-sm:not(:focus) {
	color: #3f51b5
}

nav.display-none.search-sm .nav-wrapper form .input-field i {
	color: #444
}

@media only screen and (max-width:600px) {
	.navbar .nav-wrapper .dropdown-content {
		left: 25px!important;
		width: calc(100% - 33px)!important
	}
	.navbar .nav-wrapper .navbar-list>li>a {
		padding: 0 13px
	}
	.navbar .profile-button span.avatar-status {
		line-height: 39px
	}
	.notification-button i {
		font-size: 29px;
		position: relative;
		top: 12px
	}
	.search-button i {
		position: relative;
		top: 6px;
		height: 0
	}
	.search-sm-close {
		position: absolute!important;
		top: 12px!important
	}
	.search-sm-icon {
		position: absolute!important;
		top: 14px!important
	}
}

@media (max-width:992px) {
	.navbar .navbar-main {
		padding: 0 8px 0 0
	}
}

.sidenav-main {
	position: fixed;
	z-index: 999;
	top: 0;
	width: 260px;
	height: 100%;
	-webkit-transition: .3s ease all;
	transition: .3s ease all
}

.brand-sidebar,
.sidenav {
	width: inherit;
	box-shadow: 0 4px 7px 0 rgba(0, 0, 0, .2)
}

.sidenav-main span.badge.new {
	line-height: 20px;
	margin-top: 11px
}

.brand-sidebar {
	position: fixed;
	top: 0;
	left: 0;
	height: 64px;
	background: #fff;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden
}

.brand-sidebar .logo-wrapper {
	font-size: 3rem;
	line-height: 17px!important;
	margin: 0;
	white-space: nowrap
}

.brand-sidebar .logo-wrapper a.brand-logo img {
	height: 18px
}

.brand-sidebar .logo-wrapper a.navbar-toggler {
	position: absolute;
	top: 7px;
	right: 23px
}

.brand-sidebar .logo-wrapper a.navbar-toggler i {
	font-size: 15px;
	line-height: 56px;
	display: block;
	height: 56px;
	color: #333
}

.brand-sidebar span.logo-text {
	font-size: 24px;
	visibility: visible;
	padding-left: 8px;
	-webkit-transition: opacity .2s linear;
	transition: opacity .2s linear;
	opacity: 1
}

.brand-sidebar .brand-logo {
	font-size: 2.1rem;
	line-height: 0;
	position: absolute;
	display: inline-block;
	margin: 1px 0;
	padding: 22px 12px 22px 22px;
	color: #333
}

.brand-sidebar .brand-logo img {
	height: 18px
}

.brand-sidebar .brand-logo.center {
	left: 50%;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%)
}

@media only screen and (max-width:992px) {
	.brand-sidebar .brand-logo {
		left: 50%;
		-webkit-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		transform: translateX(-50%)
	}
	.brand-sidebar .brand-logo.left,
	.brand-sidebar .brand-logo.right {
		padding: 0;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none
	}
	.brand-sidebar .brand-logo.left {
		left: .5rem
	}
	.brand-sidebar .brand-logo.right {
		right: .5rem;
		left: auto
	}
}

.brand-sidebar .brand-logo.right {
	right: .5rem;
	padding: 0
}

.brand-sidebar .brand-logo [class*=mdi-],
.brand-sidebar .brand-logo [class^=mdi-],
.brand-sidebar .brand-logo i,
.brand-sidebar .brand-logo i.material-icons {
	float: left;
	margin-right: 15px
}

.sidenav {
	top: 64px;
	overflow: hidden;
	height: 100%;
	padding-bottom: 65px;
	-webkit-transition: auto!important;
	transition: auto!important;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden
}

.sidenav.native-scroll {
	/* overflow-y: scroll!important */
}

#chart-dashboard .card,
#slide-out-right,
.browser-window,
.card .card-action,
.tooltip-class {
	overflow: hidden
}

.sidenav a {
	line-height: 42px;
	height: 42px
}

.sidenav .navigation-header {
	font-size: .85rem;
	font-weight: 600;
	margin-top: 10px;
	padding: 0 23px;
	text-transform: uppercase
}

.sidenav .navigation-header .navigation-header-icon {
	display: none
}

.sidenav .navigation-header .navigation-header-text {
	font-size: .85rem;
	font-weight: 600;
	line-height: 30px;
	height: 30px;
	margin: 0;
	padding: 0!important;
	color: #969696
}

.sidenav .navigation-header .navigation-header-text:hover {
	background-color: transparent!important
}

.sidenav li {
	line-height: 44px;
	padding: 0
}

.sidenav li a {
	margin-right: 1.5rem;
	color: rgba(0, 0, 0, .87)
}

.sidenav li a i {
	font-size: 1.4rem;
	line-height: inherit;
	display: block;
	float: left;
	width: 2rem;
	margin-right: 1rem
}

.sidenav .collapsible-header {
	font-weight: 400
}

.sidenav .collapsible-header:after {
	font-family: 'Material Icons';
	font-size: 18px;
	position: absolute;
	right: 0;
	content: 'keyboard_arrow_right';
	transition: transform .3s ease-in-out, opacity .25s ease-in-out .1s, -webkit-transform .3s ease-in-out;
	color: #afafaf
}

.sidenav-active-rounded .sidenav .collapsible-header:after,
.sidenav-active-square .sidenav .collapsible-header:after {
	right: 4px
}

.sidenav .collapsible-header.active:after {
	content: 'keyboard_arrow_down'
}

.sidenav li .collapsible-body {
	background: 0 0
}

.sidenav li .collapsible-body a i {
	font-size: .7rem
}

.sidenav li a.active {
	margin: 0;
	color: #fff;
	background: -webkit-linear-gradient(45deg, #303f9f, #7b1fa2);
	background: linear-gradient(45deg, #303f9f, #7b1fa2);
	box-shadow: 3px 3px 20px 0 rgba(123, 31, 162, .5)
}

.sidenav li a.active i {
	color: #fff
}

.sidenav li:not(.active).open {
	background-color: rgba(0, 0, 0, .035)
}

.sidenav li.active {
	background-color: transparent
}

.sidenav li.active>.collapsible-header:after {
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg)
}

.sidenav li.open {
	background: rgba(0, 0, 0, .035)
}

.sidenav li a.collapsible-header,
.sidenav li>a {
	font-weight: 400;
	line-height: 44px;
	height: 44px;
	padding: 0 16px!important
}

.sidenav li a.collapsible-header>i.material-icons,
.sidenav li>a>i.material-icons {
	line-height: 44px;
	height: 44px;
	margin: 0 20px 0 0
}

.sidenav li>a:not(.active):hover {
	background-color: rgba(0, 0, 0, .035)!important
}

.sidenav .collapsible-body>ul:not(.collapsible)>li.active,
.sidenav.sidenav-fixed .collapsible-body>ul:not(.collapsible)>li.active {
	background-color: #d1d6ef
}

.sidenav .collapsible-body>ul:not(.collapsible)>li.active a,
.sidenav .collapsible-body>ul:not(.collapsible)>li.active i,
.sidenav.sidenav-fixed .collapsible-body>ul:not(.collapsible)>li.active a,
.sidenav.sidenav-fixed .collapsible-body>ul:not(.collapsible)>li.active i {
	color: #3f51b5;
	background: 0 0
}

.nav-expanded .sidenav .menu-title {
	visibility: visible;
	-webkit-transition: opacity .2s linear;
	transition: opacity .2s linear;
	opacity: 1
}

.nav-expanded .navigation-header-text {
	display: block
}

.nav-collapsed .navigation-header-text,
.nav-expanded .navigation-header-icon {
	display: none
}

.nav-collapsed.sidenav-main {
	width: 64px
}

.nav-collapsed .brand-sidebar .brand-logo span,
.nav-collapsed .brand-sidebar .navbar-toggler {
	visibility: hidden;
	-webkit-transition: visibility 0s 2s, opacity .05s linear;
	transition: visibility 0s 2s, opacity .05s linear;
	opacity: 0
}

.nav-collapsed .brand-sidebar .brand-logo {
	padding: 22px 12px 22px 15px
}

.nav-collapsed .navigation-header-icon {
	display: block!important
}

.nav-collapsed .sidenav .collapsible-body span,
.nav-collapsed .sidenav .collapsible-header:after,
.nav-collapsed .sidenav .menu-title,
.nav-collapsed .sidenav .user-options,
.nav-lock .navigation-header-icon {
	display: none
}

.nav-collapsed .sidenav .menu-title {
	visibility: visible;
	-webkit-transition: opacity .2s linear;
	transition: opacity .2s linear;
	opacity: 1
}

.nav-collapsible.nav-collapsed span.badge.pill {
	visibility: hidden;
	-webkit-transition: visibility 0s 2s, opacity .05s linear;
	transition: visibility 0s 2s, opacity .05s linear;
	opacity: 0
}

.nav-collapsible span.badge {
	color: #fff
}

.nav-collapsible span.badge.pill {
	position: absolute;
	right: 4px;
	visibility: visible;
	float: none;
	-webkit-transition: opacity .2s linear;
	transition: opacity .2s linear;
	opacity: 1
}

.sidenav-dark {
	background: #2c323f
}

.sidenav-dark .brand-sidebar {
	background: #2c323f;
	box-shadow: 0 -4px 7px 1px rgba(0, 0, 0, .2)
}

.sidenav-dark.sidenav-main .sidenav {
	background-color: transparent
}

.sidenav-dark.sidenav-main .sidenav .collapsible-body>ul:not(.collapsible)>li.active,
.sidenav-dark.sidenav-main .sidenav.fixed .collapsible-body>ul:not(.collapsible)>li.active {
	background: #3f51b5
}

.sidenav-dark.sidenav-main .sidenav li .navigation-header-text {
	color: #b0bec5
}

.sidenav-dark.sidenav-main .brand-sidebar .brand-logo,
.sidenav-dark.sidenav-main .brand-sidebar .logo-wrapper a.navbar-toggler i,
.sidenav-dark.sidenav-main .sidenav li .more-text,
.sidenav-dark.sidenav-main .sidenav li .more-text>i,
.sidenav-dark.sidenav-main .sidenav li>a,
.sidenav-dark.sidenav-main .sidenav li>a>i {
	color: #fff
}

.sidenav-dark.sidenav-main .sidenav li.bold>a:not(.active):hover,
.sidenav-dark.sidenav-main .sidenav li:not(.active).open {
	background-color: rgba(0, 0, 0, .1)!important
}

.sidenav-dark .ps__rail-y:hover,
.sidenav-dark.sidenav-gradient .brand-sidebar,
.sidenav-dark.sidenav-gradient.fixed .collapsible-body {
	background: 0 0
}

.sidenav-dark .collapsible-header:after,
.sidenav-dark.sidenav-gradient.sidenav-main .sidenav li .navigation-header-text {
	color: #ececec
}

.navbar-full .brand-sidebar {
	background: #a53394
}

.sidenav-active-rounded.nav-collapsed li.close,
.sidenav-active-square.nav-collapsed li.close {
	background-color: rgba(0, 0, 0, .08)
}

.navbar-full .brand-sidebar .brand-logo,
.navbar-full .brand-sidebar .logo-wrapper a.navbar-toggler i {
	color: #fff
}

.sidenav-active-rounded .sidenav li>a.active {
	margin-right: 1.15rem;
	color: #fff;
	border-radius: 0 25px 25px 0
}

.sidenav-active-rounded .sidenav li:not(.open)>a.active,
.sidenav-active-rounded .sidenav li:not(.open)>a.active>i,
.sidenav-active-rounded .sidenav li>a.active>i {
	color: #fff
}

.sidenav-active-rounded .sidenav li>a:hover,
.sidenav-active-rounded .sidenav li>li:not(.active)>a:hover {
	border-radius: 0 25px 25px 0
}

.sidenav-active-rounded .sidenav li a.collapsible-header,
.sidenav-active-rounded .sidenav li>a {
	margin-right: 1.15rem;
	padding: 0 0 0 21px!important;
	-webkit-transition: none;
	transition: none
}

.sidenav-active-rounded.nav-collapsed li a.collapsible-header,
.sidenav-active-rounded.nav-collapsed li>a {
	margin-right: 10px!important
}

.sidenav-active-square .sidenav li>a.active {
	margin-right: 1.15rem;
	color: #fff;
	border-radius: 0 5px 5px 0
}

.sidenav-active-square .sidenav li:not(.open)>a.active,
.sidenav-active-square .sidenav li:not(.open)>a.active>i,
.sidenav-active-square .sidenav li>a.active>i {
	color: #fff
}

.sidenav-active-square .sidenav li>a:hover,
.sidenav-active-square .sidenav li>li:not(.active)>a:hover {
	border-radius: 0 5px 5px 0
}

.sidenav-active-square .sidenav li a.collapsible-header,
.sidenav-active-square .sidenav li>a {
	margin-right: 1.15rem;
	padding: 0 0 0 21px!important;
	-webkit-transition: none;
	transition: none
}

.sidenav-active-square.nav-collapsed li a.collapsible-header,
.sidenav-active-square.nav-collapsed li>a {
	margin-right: 10px!important
}

@media only screen and (max-width:992px) {
	.sidenav-main {
		width: auto;
		height: 0
	}
	.sidenav-main .brand-sidebar {
		left: 80px;
		background: 0 0;
		box-shadow: none
	}
	.sidenav-main .brand-sidebar .navbar-toggler {
		display: none
	}
	.sidenav-main .brand-sidebar .logo-wrapper {
		padding: 0!important
	}
	.sidenav-main .brand-sidebar .sidenav-trigger {
		top: -48px
	}
	.sidenav-main .sidenav {
		top: 0;
		width: 260px
	}
	.sidenav-main .btn-sidenav-toggle {
		position: relative;
		top: 10px;
		left: 10px
	}
	.sidenav-dark.sidenav-main .sidenav {
		background: #2c323f
	}
}

@media only screen and (max-width:600px) {
	.sidenav-main .brand-sidebar .brand-logo {
		padding: 16px 12px 20px
	}
	.sidenav-main .btn-sidenav-toggle {
		position: relative;
		top: 6px;
		left: 6px
	}
}

@media screen and (max-width:992px) {
	.sidenav {
		padding-bottom: 0
	}
}

@media screen and (-ms-high-contrast:active) and (min-width:993px),
screen and (-ms-high-contrast:none) and (min-width:993px) {
	ul#slide-out.sidenav {
		height: calc(100% - 64px)!important
	}
}

@media screen and (-ms-high-contrast:active),
(-ms-high-contrast:none) {
	.sidenav .collapsible-header:after {
		width: 0;
		content: '+'
	}
	.sidenav li.active>.collapsible-header:after {
		right: 0;
		width: 0;
		content: '-';
		-webkit-transform: rotate(180deg);
		-ms-transform: rotate(180deg);
		transform: rotate(180deg)
	}
}

@-moz-document url-prefix() {
	@media screen and (min-width: 993px) {
		ul#slide-out.sidenav {
			height:calc(100% - 64px)!important
		}
	}
}

@supports (-ms-ime-align:auto) {
	@media screen and (min-width: 993px) {
		ul#slide-out.sidenav {
			height:calc(100% - 64px)!important
		}
	}
}

#right-search .input-field {
	margin-top: 0
}

#slide-out-right {
	top: 64px
}

#slide-out-right .tab a {
	padding: 0!important
}

#slide-out-right.sidenav {
	top: 0;
	width: 340px;
	padding-bottom: 0
}

#slide-out-right.sidenav li a.active {
	background: 0 0;
	box-shadow: none
}

#slide-out-right.sidenav .slide-out-right-body {
	width: 100%;
	height: 100vh
}

#slide-out-right.sidenav #messages {
	position: relative;
	height: calc(100vh - 54px);
	padding: .7rem
}

#slide-out-right.sidenav #messages .header-search-input {
	width: 86%;
	height: 2.8rem;
	margin: 1rem;
	padding-left: 1rem;
	border: none;
	border-radius: 2rem;
	background-color: #eee
}

#slide-out-right.sidenav #messages .collection .collection-item,
#slide-out-right.sidenav #messages .collection ul.collection {
	border: none
}

#slide-out-right.sidenav #messages .collection ul.collection li {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 1.2rem;
	cursor: pointer
}

#slide-out-right.sidenav #messages .collection ul.collection li .user-content {
	margin-top: .4em;
	margin-left: 1rem
}

#slide-out-right.sidenav #messages .collection ul.collection li .secondary-content {
	top: 1.6rem;
	color: #c5cae9
}

#slide-out-right.sidenav #messages .collection ul.collection li:hover {
	background-color: #f5f5f5
}

#slide-out-right.sidenav #activity,
#slide-out-right.sidenav #settings {
	position: relative;
	height: calc(100vh - 54px)
}

#slide-out-right .fixed-line-height {
	line-height: 24px
}

#slide-out-right #chatapp li {
	line-height: 35px!important;
	min-height: 70px!important
}

#slide-out-right #chatapp .sidenav li {
	line-height: 20px!important
}

.chat-close-collapse {
	padding: 5px 15px 0 0
}

.icon-bg-color {
	font-size: 20px;
	width: 35px;
	height: 35px;
	padding-top: 8px;
	border-radius: 50%
}

.border-bottom-1 {
	border-bottom: 1px solid #f5f5f5
}

#activity .widget-timeline {
	margin: .5rem 5%;
	padding-left: 40px!important
}

#activity .widget-timeline li {
	position: relative;
	padding: 1.1rem 0;
	list-style: none
}

#activity .widget-timeline li.timeline-items:before {
	position: absolute;
	z-index: 2;
	top: 22px;
	left: -37px;
	width: 13px;
	height: 13px;
	content: '';
	border: 3px solid #fff;
	border-radius: 50%;
	background: #3f51b5;
	box-shadow: 1px 2px 6px 0 rgba(25, 42, 70, .3)
}

#activity .widget-timeline li.timeline-items.active:not(:last-child):after {
	position: absolute;
	z-index: 1;
	top: 22px;
	left: -31px;
	width: 1px;
	height: 100%;
	content: '';
	background: #e0e0e0
}

#activity .widget-timeline li.timeline-items .timeline-content {
	font-size: 13px;
	font-weight: 700;
	line-height: 1;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: .5rem 1rem;
	color: #9e9e9e;
	border-radius: .267rem;
	background-color: #fafafa;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center
}

#activity .widget-timeline li .timeline-time {
	font-size: .75rem;
	line-height: 1.7;
	float: right
}

#activity .widget-timeline li .timeline-title {
	font-size: 15px;
	font-weight: 900;
	line-height: 1;
	margin: 0;
	color: #6b6f82
}

#activity .widget-timeline li .timeline-text {
	font-size: .75rem;
	line-height: 2.2;
	margin: 0;
	color: #9e9e9e
}

.slide-out-right-sidenav-chat {
	top: 0;
	width: 340px
}

#settings .setting-header {
	font-size: 1.1rem
}

#settings ul.collection {
	max-height: 100%!important
}

@media only screen and (max-width:992px) {
	#slide-out-right {
		top: 0
	}
	#slide-out-right.sidenav {
		height: 100%!important
	}
}

#slide-out-chat .chat-footer,
#slide-out-chat .chat-head {
	z-index: 1;
	width: 100%;
	background-color: #eceff1
}

#slide-out-chat .chat-footer {
	bottom: -.6rem
}

#slide-out-chat .chat-footer form {
	background-color: #eceff1
}

#slide-out-chat .chat-footer input {
	width: 80%;
	margin-left: -2rem;
	padding-left: 1rem;
	border: none;
	border-radius: 3rem;
	background-color: #fff
}

#slide-out-chat .chat-footer label {
	margin-top: -.7rem;
	margin-left: 2rem
}

#slide-out-chat .chat-footer label:not(.label-icon).active {
	display: none
}

#slide-out-chat .chat-footer i {
	cursor: pointer
}

#slide-out-chat .chat-footer a {
	line-height: 2rem;
	position: absolute;
	width: 40px;
	background: 0 0;
	box-shadow: none
}

#slide-out-chat .speech-bubble {
	position: relative;
	margin-left: 1rem;
	border-radius: .3rem;
	background: #eceff1
}

#slide-out-chat .speech-bubble:after {
	position: absolute;
	top: 50%;
	left: -9px;
	width: 0;
	height: 0;
	margin-top: -20px;
	content: '';
	border: 10px solid transparent;
	border-right-color: #eceff1;
	border-left: 0
}

#slide-out-chat .speech-bubble-right {
	position: relative;
	margin-left: 1rem;
	color: #fff;
	border-radius: .3rem;
	background-color: #3f51b5
}

#slide-out-chat .speech-bubble-right:after {
	position: absolute;
	top: 50%;
	right: 0;
	width: 0;
	height: 0;
	margin-top: -16px;
	margin-right: -8px;
	content: '';
	border: 10px solid transparent;
	border-right: 0;
	border-left-color: #3f51b5
}

#slide-out-chat .chat-body ul.collection {
	top: 0;
	margin-top: 0;
	margin-bottom: 0
}

#slide-out-chat .collection {
	top: 48px;
	height: calc(100vh - 129px)
}

#slide-out-chat .collection .collection-item {
	min-height: auto;
	margin-bottom: 1rem;
	border: none
}

#slide-out-chat .collection .collection-item .user-content {
	padding: 1rem
}

@media (max-width:600px) {
	#slide-out-right.sidenav {
		box-shadow: none
	}
}

#breadcrumbs-wrapper {
	padding: 20px 0;
	background: 0 0
}

#breadcrumbs-wrapper .breadcrumbs-btn {
	top: 24px
}

#breadcrumbs-wrapper h5.breadcrumbs-header {
	font-size: 1.64rem;
	line-height: 1.804rem;
	margin: 1.5rem 0 0
}

#breadcrumbs-wrapper .breadcrumbs {
	margin: 15px 0;
	padding: 0;
	list-style: none
}

#breadcrumbs-wrapper .breadcrumbs>li {
	display: inline-block
}

#breadcrumbs-wrapper .breadcrumbs>li+li::before {
	font-family: 'Material Icons';
	position: relative;
	top: 3px;
	padding: 0 5px 0 4px;
	content: 'keyboard_arrow_right';
	color: #bdbdbd
}

#breadcrumbs-wrapper .breadcrumbs .active {
	font-weight: 400;
	color: #9e9e9e
}

#breadcrumbs-wrapper .breadcrumbs-title {
	font-size: 1.4rem;
	line-height: 1.4rem
}

#breadcrumbs-wrapper .breadcrumbs-nav {
	margin: 8px 0 9px
}

#breadcrumbs-wrapper.breadcrumbs-bg-image {
	position: relative;
	z-index: 1;
	color: #fff;
	background-position: center center;
	background-size: cover
}

#breadcrumbs-wrapper.breadcrumbs-bg-image .breadcrumbs-title {
	color: #fff
}

#breadcrumbs-wrapper.breadcrumbs-bg-image .breadcrumbs {
	margin: 10px 0
}

#breadcrumbs-wrapper.breadcrumbs-bg-image .breadcrumbs a {
	color: rgba(255, 255, 255, .8)
}

#breadcrumbs-wrapper.breadcrumbs-bg-image .breadcrumbs .active {
	color: rgba(255, 255, 255, .9)
}

#breadcrumbs-wrapper.breadcrumbs-bg-image::before {
	position: absolute;
	z-index: -1;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	content: '';
	background-color: rgba(0, 0, 0, .07)
}

#breadcrumbs-wrapper.breadcrumbs-dark .breadcrumbs-title,
#breadcrumbs-wrapper.breadcrumbs-dark a {
	color: rgba(255, 255, 255, .85)
}

#breadcrumbs-wrapper.breadcrumbs-inline .breadcrumbs,
#breadcrumbs-wrapper.breadcrumbs-inline .breadcrumbs-title {
	display: inline
}

#breadcrumbs-wrapper.breadcrumbs-inline .breadcrumbs-left {
	position: relative;
	top: 8px
}

#breadcrumbs-wrapper.breadcrumbs-inline .breadcrumbs-title {
	padding-right: 20px;
	border-right: 1px solid #bdbdbd
}

#breadcrumbs-wrapper.breadcrumbs-inline .breadcrumbs {
	padding-left: 20px
}

#breadcrumbs-wrapper.breadcrumbs-inline .breadcrumbs-btn {
	top: 0
}

#breadcrumbs-wrapper .breadcrumbs-right .breadcrumbs {
	float: right;
	margin-top: 0
}

@media screen and (-ms-high-contrast:active),
(-ms-high-contrast:none) {
	#breadcrumbs-wrapper .breadcrumbs>li+li::before {
		top: 0;
		width: 0;
		content: '/'
	}
}

.input-field div.error {
	font-size: .8rem;
	position: relative;
	top: 0;
	left: 0;
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateY(0);
	color: #ff4081
}

.input-field label.active {
	width: 100%
}

.left-alert input[type=date]+label:after,
.left-alert input[type=datetime-local]+label:after,
.left-alert input[type=email]+label:after,
.left-alert input[type=number]+label:after,
.left-alert input[type=password]+label:after,
.left-alert input[type=search]+label:after,
.left-alert input[type=tel]+label:after,
.left-alert input[type=text]+label:after,
.left-alert input[type=time]+label:after,
.left-alert input[type=url]+label:after,
.left-alert textarea.materialize-textarea+label:after {
	left: 0
}

.right-alert input[type=date]+label:after,
.right-alert input[type=datetime-local]+label:after,
.right-alert input[type=email]+label:after,
.right-alert input[type=number]+label:after,
.right-alert input[type=password]+label:after,
.right-alert input[type=search]+label:after,
.right-alert input[type=tel]+label:after,
.right-alert input[type=text]+label:after,
.right-alert input[type=time]+label:after,
.right-alert input[type=url]+label:after,
.right-alert textarea.materialize-textarea+label:after {
	right: 70px
}

.select2-icons+.select2-container--default .select2-selection__choice,
.select2-icons+.select2-container--default .select2-selection__rendered {
	display: -webkit-box!important;
	display: -webkit-flex!important;
	display: -ms-flexbox!important;
	display: flex!important;
	padding-bottom: 5px!important;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center
}

.select2-icons+.select2-container--default .select2-selection__choice i,
.select2-icons+.select2-container--default .select2-selection__rendered i {
	margin-right: 1rem
}

.select2-results .select2-results__option i {
	position: relative;
	top: 7px;
	margin-right: 1rem
}

.btn-toolbar {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap
}

.btn-toolbar .btn-group-sm {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: .5rem
}

.btn-toolbar .btn-group-sm .btn {
	line-height: 0;
	height: 28px;
	margin-right: 1rem;
	padding: 0 1rem
}

.select2-selection.select-lg {
	font-size: 1.2rem;
	min-height: calc(1.4em + 1.334rem + 3.7px)!important;
	margin-bottom: 0!important
}

.select2-selection.select-lg .select2-selection__rendered {
	line-height: 28px
}

.select2-selection.select-sm {
	font-size: .75rem;
	line-height: 1.5;
	min-height: calc(1.1em + .94rem + 3.7px)!important;
	margin-bottom: 0!important;
	padding: 0 .2rem
}

table.dataTable.dtr-inline.collapsed>tbody>tr[role=row]>td:first-child:before,
table.dataTable.dtr-inline.collapsed>tbody>tr[role=row]>th:first-child:before {
	line-height: 15px;
	top: 19px;
	text-indent: -1px!important
}

table.bordered td,
table.bordered th {
	padding: 15px!important;
	border: 1px solid rgba(0, 0, 0, .12)!important
}

.card {
	margin: 1rem 0
}

.card .card-title {
	font-size: 18px;
	font-weight: 400;
	margin: 0
}

.card .activator {
	cursor: pointer
}

#card-stats .card-stats-title {
	font-size: 1rem
}

#card-stats .card-stats-title i {
	font-size: 1.2rem;
	position: relative;
	top: 3px
}

#card-stats .card-stats-compare {
	font-size: .85rem
}

#card-stats .card-stats-compare i {
	position: relative;
	top: 8px
}

#card-stats .card-stats-number {
	font-size: 1.8rem;
	font-weight: 600;
	line-height: 2rem;
	margin: .8rem 0 .6rem
}

#card-stats .card .card-content {
	padding: 10px 14px
}

#card-stats .card .card-action {
	overflow: hidden;
	padding: 10px 14px
}

.vertical-align-top {
	vertical-align: top
}

.card-animation-1,
.image-n-margin {
	margin-top: -50px;
	-webkit-transition: .3s;
	-webkit-transition: .5s;
	transition: .5s
}

.card-animation-1:hover .image-n-margin {
	margin-top: -80px;
	margin-bottom: 30px;
	cursor: pointer;
	-webkit-transition: .5s;
	transition: .5s
}

.card-alert .card-content {
	padding: 10px 20px
}

.card-alert i {
	font-size: 20px;
	position: relative;
	top: -1px;
	vertical-align: middle
}

.card-alert .alert-circle {
	position: relative;
	top: 0;
	left: -2px;
	display: inline-block;
	width: 40px;
	vertical-align: bottom;
	border-radius: 1000px
}

.card-alert .single-alert {
	line-height: 42px
}

.card-alert button {
	font-size: 20px;
	position: absolute;
	top: 5px;
	right: 10px;
	color: #fff;
	border: none;
	background: 0 0
}

.card-alert .card .card-content {
	padding: 20px 40px 20px 20px
}

.card-alert .card-action i {
	top: 0;
	margin: 0
}

#work-collections .collection-header {
	font-size: 1.14rem;
	font-weight: 500
}

#work-collections p.collections-title {
	font-size: 1rem;
	font-weight: 500;
	margin: 0;
	padding: 0
}

#work-collections p.collections-content {
	font-size: .9rem;
	font-weight: 400;
	margin: 0;
	padding: 0
}

#work-collections .collection-item.avatar {
	height: auto;
	padding-top: 22px
}

#flight-card .flight-card-title {
	font-weight: 300;
	margin: 0;
	color: #fff
}

#flight-card .flight-card-date {
	font-size: 1rem;
	margin: 0;
	color: #fff
}

#flight-card .flight-state {
	padding-bottom: 15px
}

#flight-card .card-header .card-title,
#task-card .collection-header {
	padding: 20px
}

#flight-card .flight-state-two {
	border-left: 1px dashed #bdbdbd
}

#flight-card .flight-icon {
	font-size: 2.2rem;
	display: block;
	-webkit-transform: rotate(90deg);
	-moz-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	-o-transform: rotate(90deg);
	transform: rotate(90deg);
}

#flight-card .card-content-bg {
	/* background: url(../../../images/gallery/3.png) center center no-repeat; */
	background-size: cover
}

#flight-card .card-content {
	background: rgba(0, 0, 0, .25)
}

#flight-card .flight-state-wrapper {
	margin: 0 0 80px!important
}

#task-card label {
	display: block;
	height: auto;
	color: rgba(0, 0, 0, .87)
}

#task-card .task-card-title {
	font-weight: 300;
	margin: 0;
	color: #fff
}

#task-card .task-card-date {
	font-size: 1rem;
	margin: 0;
	color: #fff
}

#task-card .task-cat,
.task-cat {
	font-size: .8rem;
	padding: 2px 4px;
	border-radius: 2px;
	background-clip: padding-box;
	font-weight: 300;
	color: #fff
}

#task-card .task-add {
	position: absolute;
	top: 76px;
	right: 10px
}

#task-card .task-cat {
	margin-left: 37px
}

.collection .collection-item.avatar i.circle {
	font-size: 28px
}

#profile-card .card-image {
	height: 150px
}

#profile-card .card-profile-image {
	position: absolute;
	z-index: 1;
	top: 110px;
	width: 70px;
	cursor: pointer
}

.bg-image-1,
.bg-image-2,
.bg-image-3 {
	width: 100%;
	background-size: cover
}

#profile-card .btn-move-up {
	position: relative;
	top: -40px;
	right: -18px;
	margin-right: 10px!important
}

#profile-card .card-content .profile-card-i {
	position: relative;
	top: 6px;
	margin-right: 10px
}

#profile-card .card-content p {
	font-size: 1rem;
	margin: 10px 0 12px
}

.card-move-up .move-up {
	padding: 20px
}

.black-overlay {
	background-color: rgba(0, 0, 0, .42)!important
}
/* 
.bg-image-1 {
	background-image: url(../../../images/cards/macbook.png)
}

.bg-image-2 {
	background-image: url(../../../images/cards/iphone.png)
}

.bg-image-3 {
	background-image: url(../../../images/cards/applewatch.png)
} */

.icon-background {
	width: 50px;
	height: 50px;
	margin: 8px;
	padding-top: 11px
}

#profile-card .card-reveal i.material-icons {
	position: relative;
	top: 6px
}

#user-card .flip-card {
	max-width: 100%
}

@media screen and (min-width:1290px) {
	.icon-background {
		width: 3.125rem;
		height: 3.125rem;
		margin: 1.25rem 1.25rem 1.25rem 2rem;
		padding-top: .6875rem
	}
}

@media screen and (max-width:1366px) {
	.icon-background {
		margin-left: 1.25rem
	}
}

@media screen and (min-width:1440px) {
	.icon-background {
		margin-left: 2rem
	}
}

@media screen and (min-width:1680px) {
	.icon-background {
		margin-left: 2.6875rem
	}
}

@media screen and (min-width:1024px) and (max-width:1365px) {
	.icon-background {
		margin-left: .25rem
	}
}

@media screen and (max-width:992px) {
	.icon-background {
		margin-left: 1.875rem
	}
}

@media screen and (max-width:786px) {
	.icon-background {
		margin-left: .9375rem
	}
}

@media screen and (max-width:540px) {
	.icon-background {
		margin-left: .625rem
	}
}

.small-ico-bg {
	padding: 6px;
	border-radius: 50%;
	background-color: #f3f1f1
}

@media only screen and (min-device-width:1024px) and (max-device-width:1366px) {
	#cards-extended #card-panel-type .card-width,
	#cards-extended #card-with-analytics .card-width,
	#cards-extended #gradient-Analytics .card-width {
		width: 50%
	}
}

#card-stats {
	padding-top: 12px
}

#chart-dashboard .card .card-content {
	padding: 10px 14px
}

#chartjs .sample-chart-wrapper,
#chartjs2 .sample-chart-wrapper,
#chartjs3 .sample-chart-wrapper {
	margin-top: -75px;
	margin-bottom: -14px
}

.sample-chart-wrapper {
	width: 100%
}

.chart-title {
	font-size: 1.6rem;
	font-weight: 300
}

.chart-revenue {
	float: right;
	padding: 8px;
	border-radius: 3px
}

.chart-revenue .chart-revenue-total {
	font-size: .9rem;
	margin: 0
}

.chart-revenue .chart-revenue-per {
	font-size: .8rem;
	margin: 0
}

.chart-revenue .chart-revenue-per i {
	position: relative;
	top: 5px
}

.chart-revenue-switch {
	padding-top: 28px;
	padding-right: 10px;
	text-align: right;
	color: #fff
}

#materialize-chartist .ct-chart-bar,
#materialize-chartist .ct-chart-donut,
#materialize-chartist .ct-chart-line,
#materialize-chartist .ct-chart-pie {
	height: 400px!important
}

.doughnut-chart-status {
	font-size: 16px;
	font-weight: 500;
	position: relative;
	top: -75px;
	left: 0;
	height: 0;
	text-align: center
}

.doughnut-chart-status p {
	margin-top: -5px
}

.doughnut-chart-legend li {
	font-size: .9rem;
	padding: 2px 0
}

.doughnut-chart-legend li:before {
	display: block;
	float: left;
	width: 8px;
	height: 8px;
	margin-top: 6px;
	margin-right: 4px;
	content: ''
}

a.button-collapse.top-nav {
	font-size: 32px;
	position: absolute;
	z-index: 2;
	top: 0;
	left: 7.5%;
	float: none;
	margin-left: 1.5rem;
	color: #fff
}

.trending-bar-chart-wrapper {
	width: 100%
}

.btn-move-up {
	position: relative;
	top: -28px;
	right: -18px;
	margin-right: 10px!important
}

.jqstooltip {
	box-sizing: content-box;
	border: none!important
}

.tooltip-class {
	width: 30px;
	height: 20px;
	color: #fff
}

#ct2-chart .ct-series.ct-series-a .ct-area {
	fill: #00bcd4
}

#ct2-chart .ct-series.ct-series-a .ct-point {
	stroke: #00bcd4
}

#ct2-chart .ct-series.ct-series-a .ct-line {
	stroke: #54e2f4
}

.socialbox .logo {
	font-size: 28px;
	position: absolute;
	bottom: 20px;
	left: 20px;
	color: #fff
}

.socialbox .info {
	font-size: 15px;
	position: absolute;
	right: 20px;
	bottom: 20px;
	margin: 0;
	padding: 0;
	color: #fff
}

.chartist-tooltip.tooltip-show {
	display: inline-block
}

.chartist-tooltip {
	display: none
}

@media only screen and (min-device-width:320px) and (max-device-width:480px) {
	.doughnut-chart-status {
		display: none
	}
}

@media only screen and (min-device-width:768px) and (max-device-width:1024px) {
	.doughnut-chart-status {
		display: none
	}
	#card-stats .card-stats-compare {
		font-size: .8rem
	}
}

#full-calendar {
	padding-top: 30px
}

#calendar h2 {
	font-size: 1.5rem;
	line-height: 35px;
	text-transform: uppercase
}

#calendar .fc-day-header {
	font-weight: 400;
	text-transform: uppercase
}

#external-events {
	padding-top: 50px
}

#external-events .fc-event {
	margin-bottom: 10px;
	padding: 5px;
	cursor: all-scroll;
	text-decoration: none;
	color: #fff;
	border: none
}

.fc button {
	background: #fff
}

.fc td,
.fc th {
	border-width: 0!important
}

.fc-state-active,
.fc-state-down {
	color: #3f51b5!important
}

@-webkit-keyframes fadeUp {
	0% {
		-webkit-transform: translateY(30px);
		transform: translateY(30px);
		opacity: 0
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1
	}
}

@keyframes fadeUp {
	0% {
		-webkit-transform: translateY(30px);
		transform: translateY(30px);
		opacity: 0
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1
	}
}

.animate.fadeUp {
	-webkit-animation: fadeUp;
	animation: fadeUp;
	-webkit-animation-duration: 1.5s;
	animation-duration: 1.5s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both
}

@-webkit-keyframes fadeLeft {
	0% {
		-webkit-transform: translateX(-30px);
		transform: translateX(-30px);
		opacity: 0
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1
	}
}

@keyframes fadeLeft {
	0% {
		-webkit-transform: translateX(-30px);
		transform: translateX(-30px);
		opacity: 0
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1
	}
}

.animate.fadeLeft {
	-webkit-animation: fadeLeft;
	animation: fadeLeft;
	-webkit-animation-duration: 1.5s;
	animation-duration: 1.5s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both
}

@-webkit-keyframes fadeRight {
	0% {
		-webkit-transform: translateX(30px);
		transform: translateX(30px);
		opacity: 0
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1
	}
}

@keyframes fadeRight {
	0% {
		-webkit-transform: translateX(30px);
		transform: translateX(30px);
		opacity: 0
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1
	}
}

.animate.fadeRight {
	-webkit-animation: fadeRight;
	animation: fadeRight;
	-webkit-animation-duration: 1.5s;
	animation-duration: 1.5s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both
}

.animation-fast {
	-webkit-animation-delay: .1s;
	animation-delay: .1s
}

.animation-slow {
	-webkit-animation-delay: 2s;
	animation-delay: 2s
}

.delay-1 {
	-webkit-animation-delay: .3s!important;
	animation-delay: .3s!important
}

.delay-2 {
	-webkit-animation-delay: .4s!important;
	animation-delay: .4s!important
}

.delay-3 {
	-webkit-animation-delay: .5s!important;
	animation-delay: .5s!important
}

.delay-4 {
	-webkit-animation-delay: .6s!important;
	animation-delay: .6s!important
}

.delay-5 {
	-webkit-animation-delay: .7s!important;
	animation-delay: .7s!important
}

.dynamic-color .amber,
.dynamic-color .blue,
.dynamic-color .blue-grey,
.dynamic-color .brown,
.dynamic-color .cyan,
.dynamic-color .deep-orange,
.dynamic-color .deep-purple,
.dynamic-color .green,
.dynamic-color .grey,
.dynamic-color .indigo,
.dynamic-color .light-blue,
.dynamic-color .light-green,
.dynamic-color .lime,
.dynamic-color .orange,
.dynamic-color .pink,
.dynamic-color .purple,
.dynamic-color .red,
.dynamic-color .teal,
.dynamic-color .yellow {
	font-size: 12px;
	font-weight: 500;
	line-height: 55px;
	display: block;
	box-sizing: border-box;
	width: 100%;
	height: 55px;
	padding: 0 15px
}

.dynamic-color .col {
	margin-bottom: 55px
}

tr.group,
tr.group:hover {
	background-color: #ddd!important
}

@media only screen and (max-width:1024px) {
	tfoot {
		display: none
	}
}

.flat-text,
.flat-text-header {
	display: block;
	width: 80%;
	margin: 27px auto
}

.site-layout-example-left {
	height: 300px;
	background-color: #90a4ae
}

.site-layout-example-right {
	height: 300px;
	background-color: #00bfa5
}

.site-layout-example-top {
	height: 50px;
	background-color: #ff5252
}

.flat-text-header {
	height: 35px;
	background-color: rgba(255, 255, 255, .15)
}

.flat-text,
.flat-text.small {
	height: 25px;
	background-color: rgba(0, 0, 0, .15)
}

.flat-text.small {
	width: 25%
}

.flat-text.full-width {
	width: 100%
}

.col.grid-example {
	font-size: 28px;
	line-height: 50px;
	margin: 7px 0;
	padding: 0;
	text-align: center;
	color: #fff;
	border: 1px solid #eee;
	background-color: tomato
}

.col.grid-example span {
	font-weight: 200;
	line-height: 50px
}

.waves-color-demo .collection-item {
	line-height: 57px;
	height: 57px
}

.collection a.collection-item:not(.active):hover {
	background-color: #f9f9f9
}

.browser-window {
	display: inline-block;
	width: 100%;
	height: auto;
	margin: 20px 0;
	text-align: left;
	border-radius: 5px 5px 2px 2px;
	background-color: transparent;
	background-clip: padding-box
}

.browser-window .top-bar {
	height: 30px;
	border-top: thin solid #eaeae9;
	border-bottom: thin solid #ddd;
	border-radius: 5px 5px 0 0;
	background: -webkit-linear-gradient(#e7e7e6, #e0e0e0);
	background: linear-gradient(#e7e7e6, #e0e0e0);
	background-clip: padding-box
}

.browser-window .circle {
	display: inline-block;
	width: 10px;
	height: 10px;
	margin-right: 1px;
	border-radius: 50%
}

.browser-window .circles {
	margin: 5px 12px
}

.browser-window .content {
	display: inline-block;
	width: 100%;
	margin: 0;
	border-radius: 0 0 5px 5px;
	background-color: #fafafa
}

.browser-window .row {
	margin: 0
}

.close-circle {
	background-color: #ff5c5a
}

.minimize-circle {
	background-color: #fdb45c
}

.maximize-circle {
	background-color: #1bc656
}

.promo i {
	font-size: 6rem;
	display: block;
	color: #ee6e73
}

.promo-caption {
	font-size: 1.7rem;
	font-weight: 500;
	margin-top: 5px;
	margin-bottom: 0
}

.shadow-demo {
	width: 100px;
	height: 100px;
	margin: 20px auto;
	background-color: #26a69a
}

#input-select .input-field label {
	font-size: .8rem;
	position: absolute;
	top: -14px
}

.page-footer.footer-fixed {
	position: fixed;
	bottom: 0;
	width: 100%
}

.page-footer .container {
	padding: 0 15px
}

.page-footer .footer-copyright {
	background-color: transparent
}

#world-map-markers {
	height: 300px
}

#polar-chart-holder {
	padding-top: 20px
}

.btn-buy-now,
.btn-customizer {
	padding: 0 1rem
}

.footer-light {
	background-color: #fff
}

.footer-light.page-footer {
	border-top: 1px solid #d2d2d2!important
}

.footer-light .footer-copyright {
	color: rgba(0, 0, 0, .87)
}

.footer-dark {
	background: #37474f
}

.footer-dark .footer-copyright {
	color: rgba(255, 255, 255, .8)
}

.footer-dark .footer-copyright a {
	color: #fff
}

.btn-customizer i {
	-webkit-animation: fa-spin 2s infinite linear;
	animation: fa-spin 2s infinite linear
}

@-webkit-keyframes fa-spin {
	0% {
		-webkit-transform: rotate(0);
		transform: rotate(0)
	}
	100% {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg)
	}
}

@keyframes fa-spin {
	0% {
		-webkit-transform: rotate(0);
		transform: rotate(0)
	}
	100% {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg)
	}
}

.theme-cutomizer {
	z-index: 99999;
	top: 0;
	width: 360px
}

.theme-cutomizer .sidenav-close {
	position: absolute;
	top: 5px;
	right: 10px
}

.theme-cutomizer .customize-devider {
	border: 1px solid #e4e4e4
}

.theme-cutomizer .menu-options .menu-bg-color-option,
.theme-cutomizer .menu-options .menu-color-option,
.theme-cutomizer .menu-options .navbar-color-option,
.theme-cutomizer .navbar-options .menu-bg-color-option,
.theme-cutomizer .navbar-options .menu-color-option,
.theme-cutomizer .navbar-options .navbar-color-option {
	display: inline-block;
	width: 24px;
	height: 24px;
	margin-right: 8px;
	margin-bottom: 10px;
	cursor: pointer;
	border: 3px solid #fff;
	border-radius: 12px
}

.theme-cutomizer .menu-options .menu-bg-color-option.selected,
.theme-cutomizer .menu-options .menu-color-option.selected,
.theme-cutomizer .menu-options .navbar-color-option.selected,
.theme-cutomizer .navbar-options .menu-bg-color-option.selected,
.theme-cutomizer .navbar-options .menu-color-option.selected,
.theme-cutomizer .navbar-options .navbar-color-option.selected {
	box-shadow: 0 0 10px 3px #03a9f4
}

.btn-buy-now {
	position: fixed;
	z-index: 99;
	top: 50%;
	right: -2px
}

.buy-now-animated {
	-webkit-animation-duration: 1.5s;
	animation-duration: 1.5s;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both
}

@-webkit-keyframes tada {
	0%,
	100% {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1)
	}
	10%,
	20% {
		-webkit-transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
		transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg)
	}
	30%,
	50%,
	70%,
	90% {
		-webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
		transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg)
	}
	40%,
	60%,
	80% {
		-webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
		transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg)
	}
}

@keyframes tada {
	0%,
	100% {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1)
	}
	10%,
	20% {
		-webkit-transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
		transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg)
	}
	30%,
	50%,
	70%,
	90% {
		-webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
		transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg)
	}
	40%,
	60%,
	80% {
		-webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
		transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg)
	}
}

.tada {
	-webkit-animation-name: tada;
	animation-name: tada
}

.theme-cutomizer-trigger {
	position: fixed;
	z-index: 99;
	top: 40%;
	right: -2px
}

.ant-select-single.ant-select-open .ant-select-selection-item {
    color: #999;
}

.ant-switch-checked {
    background-color: #02c541;
}