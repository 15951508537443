@import '../scss/abstracts/variables';
@import '../scss/abstracts/mixins';
@import '../scss/abstracts/functions';

@import '../scss/base/base';

@import '../scss/components/buttons';
@import '../scss/components/select';
@import '../scss/components/datepicker';
@import '../scss/components/radiobox';
@import '../scss/components/forms';

.h-min {
  min-height: calc(100% - 30px);
}

.visible_sml {
  display: none;
}

.jexcel>tbody>tr>td.readonly {
  color: rgba(17, 3, 3, 0.62) !important;
}

table {
  &>tbody {
    &>tr {
      &>td {
        position: relative;
        // word-break: break-all;

        .my_rating {
          @include transform(translateY(-50%));
          @include transition(all 0.25s ease);
          align-items: center;
          border-radius: 3px;
          display: inline-flex;
          cursor: pointer;
          height: 32px;
          justify-content: center;
          position: absolute;
          right: 5px;
          top: 50%;
          width: 32px;

          &::before {
            content: '';
            background-color: rgba(0, 0, 0, 0);
            background-image: url('../images/ico-edit.png');
            background-position: 0 0;
            background-repeat: no-repeat;
            background-size: 100% auto;
            display: inline-block;
            height: 24px;
            width: 24px;
          }

          &:hover {
            background-color: rgba($color: #000000, $alpha: 0.05);
          }
        }

        .notes_val {
          @include transition(all 0.25s ease);
          align-items: center;
          border-radius: 3px;
          cursor: pointer;
          display: inline-flex;
          height: 32px;
          justify-content: center;
          width: 32px;

          &::before {
            content: '';
            background-color: rgba(0, 0, 0, 0);
            background-image: url('../images/ico-comments.png');
            background-position: 0 0;
            background-repeat: no-repeat;
            background-size: 100% auto;
            display: inline-block;
            height: 24px;
            width: 24px;
          }

          &:hover {
            background-color: rgba($color: #000000, $alpha: 0.05);
          }

          &[aria-describedby] {
            border: 1px solid #333;

            &:hover {
              background-color: rgba($color: #000000, $alpha: 0);
            }
          }
        }

        .jrating {
          margin-right: 30px;

          &>div {
            background-color: rgba(0, 0, 0, 0);
            background-image: url('../images/ratings_blank.svg');
            background-position: 0 0;
            background-repeat: no-repeat;
            background-size: 100% auto;
            display: inline-flex;
            height: 16px;
            line-height: initial;
            margin: 0 2px;
            width: 16px;

            &.jrating-selected {
              background-image: url('../images/ratings_selected.svg');
            }
          }
        }
      }
    }
  }
}

.pagination {
  width: 100%;
  justify-content: flex-end;
  padding: 20px 0 0;

  li {
    a {

      .mdi-navigation-chevron-left,
      .mdi-navigation-chevron-right {
        background-image: url('../images/ico-date-left.png');
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: 100% auto;
        display: inline-block;
        height: 14px;
        line-height: initial;
        position: relative;
        width: 8px;
      }
    }
  }
}

.appTabs_container {
  overflow-x: hidden;
  overflow: visible;

  .tabs {
    background-color: rgba(0, 0, 0, 0);
    position: relative;
    height: auto;
    margin: 0 -15px;
    width: auto;

    .tab {
      height: auto;
      line-height: 1.3;
      padding: 0 15px;
      text-transform: capitalize;

      a {
        color: #333333;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.3;
        padding: 20px 50px;
        position: relative;

        &:hover {
          background-color: transparent;
        }

        &.active {
          background-color: transparent;
          color: #fe0000;
        }
      }
    }

    .indicator {
      background-color: #fe0000;
      height: 5px;
    }
  }

  .card {
    border-radius: 0 5px 5px 5px;

    &.h-min {
      // min-height: calc(100vh - 385px);
      min-height: auto;
    }
  }

  .ant-tabs {
    overflow: visible;

    .ant-tabs-nav {
      margin-bottom: 0;

      .ant-tabs-nav-list {
        .ant-tabs-tab {
          color: #333333;
          font-size: 16px;
          font-weight: 600;
          line-height: 1.3;
          padding: 20px 75px;
          position: relative;

          &.ant-tabs-tab-active {
            .ant-tabs-tab-btn {
              color: $primary-color;
              text-shadow: none;
            }
          }
        }
      }

      .ant-tabs-ink-bar {
        background: $primary-color;
        height: 5px;
      }
    }
  }
}

.pagination_group {
  display: flex;

  .pagCount {
    padding: 0 0 0 5px;

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 44px;
      min-height: initial;
      border-radius: 3px !important;
    }

    .ant-select.ant-select-single {
      .ant-select-selector {

        .ant-select-selection-item,
        .ant-select-selection-placeholder {
          line-height: 40px;
        }
      }
    }
  }

  .pagination {
    justify-content: flex-end;
    padding: 0;
    width: 100%;
    flex-wrap: wrap;

    li {
      padding: 0 5px;
      height: auto !important;

      &.previous,
      &.next {
        height: 100%;

        a,
        .page-link {
          text-indent: 100px;
          white-space: nowrap;
          width: 40px;

          &[rel='prev']::before,
          &[rel='next']::before {
            background-image: url('../images/ico-date-left.png');
            background-position: 0 0;
            background-repeat: no-repeat;
            background-size: 100% auto;
            content: '';
            display: inline-block;
            height: 14px;
            position: absolute;
            left: 50%;
            top: 50%;
            @include transform(translate(-50%, -50%));
            width: 8px;
          }

          &[rel='next']::before {
            background-image: url('../images/ico-date-right.png');
          }
        }
      }

      &.active {
        background-color: rgba(0, 0, 0, 0);

        a,
        .page-link {
          background-color: #fe0000;
          border-color: #fe0000;
          color: #ffffff;
          font-weight: 600;
        }
      }

      &.disabled {
        opacity: 0.4;
        cursor: not-allowed;

        a,
        .page-link {
          cursor: not-allowed;
        }
      }

      a,
      .page-link {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;
        align-items: center;
        border: 1px solid #b0bac9;
        border-radius: 3px;
        color: #333;
        cursor: pointer;
        display: flex;
        font-size: 14px;
        height: 40px;
        justify-content: center;
        line-height: 1;
        overflow: hidden;
        padding: 0;
        position: relative;
        width: 40px;
      }
    }
  }
}

table.app_table {
  border-spacing: 0 5px;
  border-collapse: separate;

  td,
  th {
    border-radius: 0;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    color: #4d4f5c;
    padding: 5px 10px;
    font-size: 14px;
    font-weight: 400;
    white-space: nowrap;

    b,
    strong,
    a {
      color: #333333;
      font-weight: 500;
    }

    &:first-child {
      border-left: 1px solid #ccc;
      border-radius: 5px 0 0 5px;
      padding-left: 20px;
    }

    &:last-child {
      border-right: 1px solid #ccc;
      border-radius: 0 5px 5px 0;
      padding-right: 20px;
    }
  }

  td {
    .action-btn {
      @include box-shadow(none);
      align-items: center;
      background-color: #ffffff;
      border-radius: 5px;
      border: none;
      display: flex;
      height: 40px;
      justify-content: center;
      line-height: 1.3;
      padding: 5px;
      position: relative;
      width: 40px;
    }
  }
}

// .multi-select {
//     --rmsc-primary: #02C541;
//     --rmsc-hover: #f1f3f5;
//     --rmsc-selected: #e2e6ea;
//     --rmsc-border: #ccc;
//     --rmsc-gray: #aaa;
//     --rmsc-background: #fff;
//     --rmsc-spacing: 10px;
//     --rmsc-border-radius: 4px;
//     --rmsc-height: 38px;
// }

.rmsc.multi-select {
  position: relative;
  z-index: 10;

  .dropdown-container {
    border: none;

    &:focus-within {
      border: none;
      box-shadow: none;

      .dropdown-heading {
        border-color: #333333;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
          0 0 0 3px rgba(51, 51, 51, 0.1);

        &:hover {
          border-color: #333333;
        }
      }
    }

    .dropdown-heading {
      background-color: #ffffff;
      border-radius: 5px;
      border: 1px solid #ececec;
      box-shadow: 0 5px 15px rgba(159, 159, 159, 0.15);
      box-shadow: none;
      color: #000000;
      cursor: pointer;
      font-size: 14px;
      font-weight: 400;
      height: 53px;
      line-height: 1.3;
      padding: 10px 20px;
      position: relative;
      transform: all 0.25s ease;
      width: 100%;

      &:hover {
        border-color: #cccccc;
      }
    }
  }

  .dropdown-content {
    display: inline-block;
    border-radius: 5px;
    border: 1px solid #ececec;
    box-shadow: 0 5px 15px rgba(159, 159, 159, 0.15);
    opacity: 1;
    padding: 15px;
    z-index: 1000;

    &>div {
      box-shadow: none;

      input {
        background-color: #f2f6fc;
        color: #333333;
        font-size: 14px;
      }
    }

    .panel-content {
      .select-panel {
        // border: 1px solid red;
        display: flex;
        flex-direction: column;

        .search {
          border: none;
          @include box-shadow(none);
          overflow: hidden;

          input {
            background-color: #fff;
            border: 1px solid #b0bac9;
            @include box-shadow(none);
            height: 40px;
            position: relative;
            overflow: hidden;
            z-index: 1;
          }

          .search-clear-button {
            // border: 1px solid red;
            z-index: 10;
            display: inline-block;
            position: absolute;
            right: 0;
            top: 0;

            svg {
              fill: red;
            }
          }
        }

        ul {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          margin-top: 7.5px;
          position: relative;
          width: 100%;

          &>* {
            margin: 2.5px 0;
            width: 100%;
          }

          li {
            background-color: rgba($color: #000000, $alpha: 0);
            height: initial !important;
            min-height: initial;
          }

          .select-item {
            // border: 1px solid red;
            display: inline-flex;
            padding: 10px 15px;
            width: 100%;

            &[aria-selected='true'] {
              background-color: rgba(0, 0, 0, 0);
            }

            .item-renderer {
              display: flex;
              position: relative;
              width: 100%;
            }

            [type='checkbox'] {
              cursor: pointer;
              left: 0;
              opacity: 0;
              position: absolute;
              top: 0;
              visibility: hidden;
              z-index: 2;

              &+span {
                // border: 1px solid purple;
                font-size: 14px;
                line-height: 24px;
                height: 24px;
                padding-left: 0;
                padding-right: 35px;
                position: relative;
                width: 100%;

                &::before,
                &::after {
                  content: '';
                  left: auto;
                  position: absolute;
                  right: 0;
                  top: -1px;
                  @include transition(border 0.25s,
                    background-color 0.25s,
                    width 0.2s 0.1s,
                    height 0.2s 0.1s,
                    top 0.2s 0.1s,
                    left 0.2s 0.1s);
                  z-index: 1;
                }
              }

              &:not(:checked) {
                &+span {
                  &::after {
                    width: 24px;
                    height: 24px;
                    border: 2px solid #c1c1c1;
                    background-color: transparent;
                  }

                  &::before {
                    width: 0;
                    right: 10px;
                    height: 0;
                  }
                }
              }

              &:checked {
                &+span {
                  color: $primary-color;

                  &::before {
                    width: 8px;
                    top: 3px;
                    right: 13px;
                    height: 13px;
                    @include transform(rotateZ(37deg));
                    -webkit-transform-origin: 100% 100%;
                    -ms-transform-origin: 100% 100%;
                    transform-origin: 100% 100%;
                    border-bottom: 2px solid #02c541;
                    border-left: 2px solid transparent;
                    border-right: 2px solid #02c541;
                    border-top: 2px solid transparent;
                  }

                  &::after {
                    width: 24px;
                    height: 24px;
                    border: 2px solid #02c541;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.column_filter {
  .rmsc.multi-select {
    .dropdown-container {
      .dropdown-heading {
        align-items: center;
        display: flex;
        height: 40px;
        justify-content: center;
        padding: 5px 15px;

        .dropdown-heading-value {
          display: flex;
          align-items: center;

          span {
            display: none;
          }

          &::before {
            color: #4d4f5c;
            content: 'Column';
            display: inline-block;
            font-size: 14px;
            font-weight: 500;
            line-height: 1.3;
            margin-right: 5px;
            position: relative;
          }

          &::after {
            background-color: rgba(0, 0, 0, 0);
            background-image: url('../images/ico-columns.png');
            background-position: 0 0;
            background-repeat: no-repeat;
            background-size: 100% auto;
            content: '';
            display: inline-block;
            flex: 0 0 24px;
            height: 24px;
            line-height: initial;
            margin-right: -5px;
            overflow: hidden;
            text-indent: 100px;
            white-space: nowrap;
            width: 24px;
          }
        }

        .clear-selected-button,
        .dropdown-heading-dropdown-arrow {
          display: none;
        }
      }

      .dropdown-content {
        overflow-y: hidden;
        left: auto;
        right: 0;
        width: 384px;
      }
    }
  }
}

.react-tags {
  width: 100%;
  position: relative;

  .react-tags__search {
    width: 100%;

    .react-tags__search-wrapper {
      width: 100%;

      input {
        width: 100% !important;
      }
    }
  }
}

.mail-stats {
  .stats_col {
    .cardContent {
      .cell {
        &:last-child {
          padding-left: 10px;
        }
      }

      .img_wrap {
        border-radius: 100px;
        align-items: center;
        display: flex;
        flex: 0 0 59px;
        justify-content: center;
        height: 59px;
        width: 59px;

        &.sentmail {
          background-color: rgba($color: #02c541, $alpha: 0.1);

          .iconstat {
            background-image: url('../images/ico-stat-sent.png');
            height: 33px;
          }
        }

        .sent-email-1 {
          font-size: 35px;
        }

        .read-message {
          font-size: 35px;
        }

        &.readmail {
          background-color: rgba($color: #00d3fe, $alpha: 0.1);

          .iconstat {
            background-image: url('../images/ico-stat-read.png');
          }
        }

        &.unreadmail {
          background-color: rgba($color: #cd00fe, $alpha: 0.1);

          .iconstat {
            background-image: url('../images/ico-stat-unread.png');
            height: 26px;
          }
        }

        .unread-email {
          font-size: 35px;
        }

        &.processed {
          background-color: rgba($color: #e7ad4e57, $alpha: 0.1);

          .iconstat {
            background-image: url('../images/data-processing.png');
          }
        }

        &.deliveredemail {
          background-color: rgba($color: #61c4676e, $alpha: 0.1);

          .iconstat {
            background-image: url('../images/deliveredemail.png');
            height: 26px;

          }
        }

        &.undeliveredmail {
          background-color: rgba($color: #fe0000, $alpha: 0.1);

          .iconstat {
            background-image: url('../images/ico-stat-undelivered.png');
            height: 33px;
          }
        }

        .unsubscribe-mails {
          font-size: 35px;
          margin-top: 5px;
        }

        .processed-icon {
          font-size: 35px;
        }

        .sent-email {
          font-size: 35px;
        }

        &.lastlogintime {
          background-color: rgba($color: #226a8b5c, $alpha: 0.1);

          .iconstat {
            background-image: url('../images/logintime.png');
            height: 33px;
          }
        }

        .login-time {
          font-size: 35px;
        }

        &.timelogin {
          background-color: rgb(13 184 246 / 17%);

          .iconstat {
            background-image: url('../images/icon-2.png');
            height: 33px;
          }
        }

        .time-login {
          font-size: 35px;
        }

        &.applicationutilized {
          background-color: rgb(253 159 23 / 18%);

          .iconstat {
            background-image: url('../images/icon-1.png');
            height: 33px;
          }
        }

        .overview-icon {
          font-size: 35px;
        }

        .iconstat {
          background-color: rgba(0, 0, 0, 0);
          background-position: 0 0;
          background-repeat: no-repeat;
          background-size: 100% auto;
          display: inline-block;
          flex: 0 0 32px;
          height: 32px;
          line-height: initial;
          overflow: hidden;
          text-indent: 100px;
          white-space: nowrap;
          width: 32px;
        }
      }

      h4 {
        color: #606d81;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 5px;
        position: relative;
        width: 100%;

        strong {
          color: #4d4f5c;
        }
      }

      h3,
      .stat_val {
        color: #4d4f5c;
        font-size: 26px;
        font-weight: 700;
        margin: 0;
        position: relative;
        width: 100%;
      }
    }
  }
}

.react-datepicker-wrapper {
  .react-datepicker__input-container {
    &::after {
      background-color: rgba(0, 0, 0, 0);
      background-image: url('../images/ico-calendar.png');
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 3px;
      content: '';
      display: inline-block;
      fill: transparent;
      height: 24px;
      opacity: 1;
      right: 10px;
      width: 24px;
      position: absolute;
      top: 7px;
      z-index: 1;
    }

    input {
      background-color: rgba(0, 0, 0, 0);
      border: 1px solid #b0bac9;
      box-shadow: none;
      box-sizing: border-box;
      color: #333;
      font-size: 14px;
      font-weight: 500;
      height: 40px;
      letter-spacing: 0;
      margin: 0;
      min-height: initial;
      min-width: 180px;
      padding: 2px 66px 2px 15px;
      position: relative;
      width: 100%;
      z-index: 2;
      border-radius: 5px;
    }
  }
}

.react-datepicker__tab-loop {
  .react-datepicker-popper {
    &[data-placement^='bottom'] {
      padding-top: 0;
    }

    .react-datepicker {
      border-radius: 5px;
      border: 1px solid #ececec;
      box-shadow: 0 5px 15px rgba(159, 159, 159, 0.15);
      font-family: $primary-font;
      left: auto !important;
      right: 0 !important;
      padding: 8px 15px;
      width: 308px;

      .react-datepicker__triangle {
        display: none;
      }

      .react-datepicker__navigation {
        align-items: center;
        background-color: #ffffff;
        border-radius: 4px;
        border: 1px solid #e8e8e8;
        box-shadow: 0 2px 9px rgba(165, 165, 165, 0.15);
        display: flex;
        height: 24px;
        justify-content: center;
        line-height: 1.3;
        padding: 0;
        top: 28px;
        left: 20px;
        width: 24px;

        &.react-datepicker__navigation--next {
          left: auto;
          right: 20px;

          .react-datepicker__navigation-icon {
            background-image: url('../images/ico-date-right.png');
          }
        }

        .react-datepicker__navigation-icon {
          &::before {
            content: none;
          }

          background-color: rgba(0, 0, 0, 0);
          background-image: url('../images/ico-date-left.png');
          background-position: 0 0;
          background-repeat: no-repeat;
          background-size: 100% auto;
          display: inline-block;
          height: 14px;
          line-height: initial;
          width: 8px;
          right: auto;
          left: auto;
          top: auto;
          bottom: auto;
        }
      }

      .react-datepicker__month-container {
        width: 100%;

        .react-datepicker__header {
          background-color: rgba(0, 0, 0, 0);
          border-bottom: none;
          border-top-left-radius: 0;
          padding: 0;
          position: relative;

          .react-datepicker__current-month,
          .react-datepicker-time__header,
          .react-datepicker-year-header {
            color: #333333;
            font-weight: 600;
            font-size: 12px;
            line-height: 24px;
            margin-bottom: 10px;
          }

          .react-datepicker__day-names {
            background-color: #f1f1f1;
            border: 1px solid #e8e9ec;
          }
        }

        .react-datepicker__month {
          margin: 0;
          text-align: center;
        }

        .react-datepicker__day-names,
        .react-datepicker__week {
          display: table;
          border-collapse: collapse;
          border-spacing: 0;
          margin: 0;
          width: 100%;
        }

        .react-datepicker__week {
          margin: -1px 0 0;
        }

        .react-datepicker__day-name,
        .react-datepicker__day,
        .react-datepicker__time-name {
          display: table-cell;
          border: 1px solid #e8e9ec;
          width: 38.97px;
          height: 38.97px;
          vertical-align: middle;
          border-radius: 0 !important;
        }

        .react-datepicker__day--selected,
        .react-datepicker__day--in-selecting-range,
        .react-datepicker__day--in-range,
        .react-datepicker__month-text--selected,
        .react-datepicker__month-text--in-selecting-range,
        .react-datepicker__month-text--in-range,
        .react-datepicker__quarter-text--selected,
        .react-datepicker__quarter-text--in-selecting-range,
        .react-datepicker__quarter-text--in-range,
        .react-datepicker__year-text--selected,
        .react-datepicker__year-text--in-selecting-range,
        .react-datepicker__year-text--in-range {
          border-radius: 0;
          background-color: #b0bac9;
        }

        .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
        .react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
        .react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
        .react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
          background-color: #b0bac9;
        }

        .react-datepicker__day--outside-month {
          color: rgba(0, 0, 0, 0.35);
        }
      }
    }
  }
}

.manageEmail_body {
  .card-header {
    position: relative;
    z-index: 2;

    .filtersec {
      .btn-filterTrig {
        box-shadow: 0 2px 9px rgba(165, 165, 165, 0.15);
        align-items: center;
        background-color: #ffffff;
        border-radius: 4px;
        border: 1px solid #e8e8e8;
        color: #4d4f5c;
        display: flex;
        height: 40px;
        justify-content: center;
        line-height: 1.3;
        padding: 5px 15px;
        position: relative;

        .ico {
          margin-right: 10px;
        }
      }
    }
  }

  .card_body {
    position: relative;
    z-index: 1;
  }

  .myEmail-filter .filter_content .ant-collapse {
    position: relative;
  }

  .filter_content {
    position: relative;
    z-index: 10;

    .collapseContent,
    .ant-collapse {
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;

      &.collapsing {

        .card,
        .card-body {
          overflow: hidden;
        }
      }

      .card-body {
        background-color: #ffffff;
        border: 1px solid #eeeff1;
        box-shadow: 0 10px 20px rgba($color: #000000, $alpha: 0.05);
        padding: 20px;
      }

      .filter_header {
        align-items: center;
        padding-bottom: 10px;

        .title {
          color: #000000;
          margin-right: auto;
          font-size: 16px;
          line-height: 1.3;
          position: relative;
          font-weight: 500;
        }

        .clearfilter_btn {
          background-color: rgba(0, 0, 0, 0);
          border-color: transparent;
          @include box-shadow(none);
          color: #333333;
          font-size: 13px;
          min-height: initial;
          height: 40px;

          &::after {
            background-color: transparent;
            border: 1px solid #333;
          }

          &:focus,
          &:active {
            outline: 2px solid rgba($color: #000000, $alpha: 0.1);
          }
        }
      }

      .fltr_cell {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;

        .label {
          color: #a2a2a2;
          font-size: 12px;
          font-weight: 500;
          line-height: 1.3;
          margin: 0 0 5px;
          position: relative;
          width: 100%;
          width: 100%;
        }

        .select-wrapper {
          border: none;
          box-shadow: none;
          width: 100%;
        }

        input {
          border: 1px solid #b0bac9;
          box-shadow: none;
          height: 40px;
          border-radius: 4px;
          width: 100%;
          margin: 0;
        }

        .btn-primary {
          min-height: initial;
          height: 40px;
          padding: 5px 42px;
        }
      }
    }

    .ant-collapse {
      border: none;
      box-shadow: none;

      &>.ant-collapse-item {
        border-bottom: none;
      }

      &>.ant-collapse-item>.ant-collapse-header {
        align-items: center;
        background-color: #ffffff;
        border-radius: 4px;
        border: 1px solid #e8e8e8;
        bottom: 100%;
        box-shadow: 0 2px 9px rgba(165, 165, 165, 0.15);
        color: #4d4f5c;
        display: flex;
        height: 40px;
        justify-content: center;
        line-height: 1.3;
        // margin-bottom: 5px;
        margin-bottom: 0px;
        padding: 5px 15px;
        // position: absolute;
        // right: 164px;
        right: 1px;


        &:hover {
          border-color: #cccccc;
        }

        &[aria-expanded='true'] {
          border-color: #333333;
        }

        .anticon {
          background-color: rgba(0, 0, 0, 0);
          background-image: url('../images/ico-filter.png');
          background-position: 0 0;
          background-repeat: no-repeat;
          background-size: 100% auto;
          display: inline-block;
          flex: 0 0 24px;
          height: 24px;
          line-height: initial;
          overflow: hidden;
          text-indent: 100px;
          white-space: nowrap;
          width: 24px;

          svg {
            display: none;
          }
        }
      }

      .card-body {
        border: none;
        box-shadow: none;
        padding: 0;
        margin: 0;
      }

      .ant-collapse-content {
        background-color: #ffffff;
        border: 1px solid #eeeff1;
        border-radius: 5px;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
        padding: 20px;

        &>.ant-collapse-content-box {
          padding: 0;
        }

        .ant-select {
          width: 100%;

          .ant-select-selector {
            border: 1px solid #ccc !important;
            box-shadow: none !important;
            min-height: 44px;
            height: 44px;
          }

          &.ant-select-single .ant-select-selector .ant-select-selection-item,
          &.ant-select.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
            line-height: 38px;
          }
        }
      }

      .rmsc.multi-select {
        .dropdown-container {
          .dropdown-heading {
            border: 1px solid #b0bac9;
            height: 40px;
          }

          .dropdown-content {
            padding: 15px 0;

            // width: 120%;
            .panel-content {
              .select-panel {
                .search {
                  padding: 0 15px;

                  input {
                    height: 30px;
                  }

                  .search-clear-button {
                    right: 20px;
                    height: 30px;

                    svg {
                      fill: red;
                    }
                  }
                }

                ul {
                  .select-item {
                    padding: 5px 15px;

                    [type='checkbox'] {
                      &+span {
                        font-size: 12px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// .templetLists_wrap {
//   &.row {
//     margin-bottom: -30px;
//   }
// }

.templetEle {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px 10px 5px 20px;
  margin-bottom: 20px;
  width: 100%;

  .temp_title {
    color: #333333;
    font-size: 16px;
    line-height: 1.3;
    position: relative;
    margin-right: auto;
    width: calc(100% - 85px);
    cursor: pointer;
  }

  .action_grup {
    .action-btn {
      @include box-shadow(none);
      align-items: center;
      background-color: #ffffff;
      border-radius: 4px;
      border: none;
      display: flex;
      height: 24px;
      justify-content: center;
      line-height: 1.3;
      margin: 5px;
      padding: 0;
      position: relative;
      width: 24px;
    }
  }
}

.ant-select {
  &.ant-select-multiple {
    .ant-select-selection-placeholder {
      left: 15px;
    }
  }

  &.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #333333 !important;
  }

  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #333333 !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 0 3px rgba(51, 51, 51, 0.1);
  }

  .ant-select-selector {
    background-color: #ffffff;
    border-radius: 5px !important;
    box-shadow: none;
    border: 1px solid #b0bac9 !important;
    color: #000000;
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: 400;
    min-height: 44px;
    line-height: 1.3;
    padding: 5px 15px;
    position: relative;
    transform: all 0.25s ease;
    width: 100%;
    // cursor: pointer;
  }

  .ant-select-selection-search {
    input {
      height: auto;
      border: none !important;
      box-shadow: none !important;
    }
  }

  &.ant-select-show-arrow {
    .ant-select-arrow {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      margin: 0;
      right: 0;
      top: 0;
      width: 53px;

      .anticon {
        background-color: rgba(0, 0, 0, 0);
        background-image: url('../images/ico-down-arrow.png');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 3px;
        display: inline-block;
        fill: transparent;
        height: 24px;
        opacity: 1;
        right: 10px;
        width: 24px;

        &>svg {
          display: none;
        }
      }
    }
  }

  &.ant-select-single {

    input,
    &:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
      height: 100%;
      padding: 0 15px;
      padding-right: 45px;
      // caret-color: transparent;
    }

    & .ant-select-selector .ant-select-selection-item,
    & .ant-select-selector .ant-select-selection-placeholder {
      line-height: 40px;
    }

    &:not(.ant-select-customize-input) .ant-select-selector {
      padding: 0 15px;
    }
  }
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: $primary-color;
  background-color: rgba($color: #fe0000, $alpha: 0.05) !important;
  gap: 10px;
}

// .ant-select-item.ant-select-item-option-active {
//   background-color: transparent;
// }
.ant-select-item-option-active{
  background-color: transparent !important;
  color: red;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state,
.ant-select-item.ant-select-item-option-active .ant-select-item-option-state {
  color: $primary-color;
  display: flex;
    align-items: center;
}

.ant-select-item-option:hover {
  color: $primary-color;
  background-color: rgba($color: #fe0000, $alpha: 0.05);
}

.ant-dropdown {
  @include box-shadow(0 5px 15px rgba(159, 159, 159, 0.15));
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid #ececec;
  border: 1px solid #f8f8f8;
  border: 1px solid #ececec;
  overflow: hidden;

  .ant-dropdown-menu-item-divider,
  .ant-dropdown-menu-submenu-title-divider {
    background-color: #e0e0e0;
    margin: 0;
  }

  .dropdown-item.active,
  .dropdown-item:active {
    background-color: rgba(254, 0, 0, 0.05);
    color: #fe0000;
  }

  li {
    padding: 0;

    a,
    button {
      display: inline-flex;
      font-size: 14px;
      justify-content: space-between;
      line-height: 1.3;
      padding: 10px 15px;
      width: 100%;

      .ico {
        margin-left: 20px;
      }
    }

    &.nohover {
      &:hover {
        background-color: rgba(0, 0, 0, 0);
      }
    }
  }

  .ant-menu {
    .ant-menu-item {
      height: initial;
      line-height: 1.3;

      .ant-menu-title-content {
        display: inline-block;
        width: 100%;
      }
    }
  }

  .notificationScroll {
    .ant-menu {
      &.ant-menu-vertical {
        border-right: none;
      }
    }
  }

  &.profileDropdown {
    .ant-dropdown-menu {
      min-width: 100%;
    }

    li {
      padding: 0;

      a,
      button {
        font-size: 16px;
        padding: 15px 20px;
      }
    }
  }

  &.viewNotificationsDropdown {
    .notiDropdown_content {
      padding: 20px;
      width: 100%;
      max-width: 365px;

      .dropheader,
      .titleheader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        user-select: none;
        margin-bottom: 10px;

        h3,
        h4,
        h5,
        h6 {
          color: #9a9a9a;
          font-size: 14px;
          font-weight: 400;
          line-height: 1.3;
        }

        .badge {
          align-items: center;
          background: #fd6600;
          border-radius: 100px;
          box-shadow: none;
          display: inline-flex;
          font-size: 14px;
          font-weight: 400;
          height: initial;
          justify-content: center;
          line-height: 1.3;
          padding: 2px 10px;
          position: relative;
        }

        &:hover {
          background-color: transparent;
        }
      }

      .notificationScroll {
        max-height: 325px;
        max-height: 238px;
        margin: 0 -15px;
        padding: 0 15px;
        border: none;
      }

      li {
        cursor: initial;
        padding: 0;

        &:not(.divider) {
          padding: 0;
        }

        a,
        button {
          border-bottom: 1px solid rgba($color: #b0bac9, $alpha: 0.45);
          color: #4d4f5c;
          display: inline-block;
          font-size: 14px;
          padding-bottom: 10px;
          padding-left: 27px;
          padding-right: 0;
          padding-top: 0;
          position: relative;
          white-space: pre-wrap;
          word-break: break-all;

          b {
            color: #333333;
            font-weight: 500;
          }

          .status_dot {
            background-color: #b0bac9;
            border-radius: 100px;
            display: inline-block;
            height: 12px;
            width: 12px;
            position: absolute;
            left: 0;
            top: 5px;

            &.unread {
              background-color: #00bf26;
            }

            &.read {
              background-color: #b0bac9;
            }
          }

          .media-meta,
          .grey-text {
            display: inline-block;
            font-size: 12px;
            font-weight: 500;
            line-height: 1.3;
            margin: 5px 0 0;
            position: relative;
            width: 100%;
          }
        }

        &>time {
          margin-left: 0;
        }
      }

      .viewmore {
        @include box-shadow(none);
        border: none;
        color: #fe0000;
        background-color: rgba($color: #fe0000, $alpha: 0.1);
        font-size: 12px;
        font-weight: 500;
        width: 100%;
        justify-content: center;
      }

      .dropFooter {
        padding: 20px 0 0;
        width: 100%;
        position: relative;
      }
    }
  }

  &.savedFiltersDropdown {

    // &.ant-dropdown-hidden {
    //     display: inline-block !important;
    // }
    .ant-btn.ant-btn-primary {
      border: 2px solid #e8e8e8;
    }

    .dropContent {
      max-width: 300px;
      max-width: 150px;
      padding: 30px 15px;
      padding: 5px 0;
    }

    .ant-space {
      margin-bottom: 10px;
      width: 100%;

      .ant-input-group {
        .ant-input {
          border: 1px solid #b0bac9;
          border-radius: 3px 0 0 3px;
          height: 40px;
          padding: 5px 15px;
          margin: 0;

          &:focus {
            border-color: #333;
            border-width: 1px;
            @include box-shadow(none);
          }
        }

        .ant-btn {
          border: none;
          align-items: center;
          display: inline-flex;
          height: 40px;
          width: 40px;
          font-size: 16px;
          justify-content: center;
          max-height: initial;

          &.ant-btn-primary {
            background-color: $primary-color;
          }
        }
      }
    }

    .ant-menu {
      border: none;
      display: flex;
      flex-wrap: wrap;
      margin: 0 -15px;
      width: initial;

      li,
      .ant-menu-item {
        margin: 0;
        padding: 0;
        width: 100%;

        .dropdown-item {
          background-color: transparent;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 7px 15px 7px 20px;

          &:before {
            content: none;
          }

          &:hover {
            background-color: transparent;
            color: $primary-color;
          }
        }

        &:hover {
          background-color: #f8f8f8;
          color: $primary-color;
        }

        &.ant-menu-item-selected {
          background-color: rgba($color: #fe0000, $alpha: 0.05);
          color: $primary-color;
        }
      }

      .savedFltr_ele {
        .cell {
          display: inline-flex;
          align-items: center;

          &:first-child {
            flex-grow: 1;
          }

          // &:last-child {
          //     flex: 0 0 64px;
          //     max-width: 64px;
          // }
          .btn {
            align-items: center;
            background-color: rgba(0, 0, 0, 0);
            border-radius: 3px;
            border: 1px solid transparent;
            box-shadow: none;
            justify-content: center;
            margin: 5px;
            padding: 0;
            width: 32px;
            transition: all 0.25s ease;

            .ico {
              margin: 0;
            }

            &:hover {
              border-color: $primary-color;
            }
          }
        }
      }
    }
  }
}

.apexcharts-canvas {
  position: relative;
  user-select: none;
  width: auto !important;
  height: auto !important;
}

// .dashTableScroll  {
//     margin: -15px;
//     padding: 15px;
//     max-height: 564px;
// }

.dashTableScroll {
  // border: 1px solid red;
  // margin: 0 -15px;
  // padding: 0 15px 15px;
  padding: 0 0 15px;
  width: auto !important;
}

.dashTableScroll .jexcel_container {
  position: relative;
  z-index: 1;
}

.dashTableScroll .ps__rail-x,
.dashTableScroll .ps__rail-y {
  opacity: 1;
  z-index: 10;
}

.jexcel_container {
  .jexcel_content {
    padding: 0;

    table {
      min-width: 100% !important;
    }
  }
}

.ant-message-custom-content {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .anticon {
    display: flex;
    top: auto;
    left: auto;
    margin-right: 10px;

    &>svg {
      height: 24px;
      width: 24px;
    }
  }
}

.allNotifications_content {
  &.card {
    height: calc(100vh - 195px);
    margin: 0;
    padding: 10px 0;
  }

  .notifications_group {
    border-bottom: 1px solid #efefef;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
    padding-bottom: 15px;
    width: 100%;

    &:last-child {
      border: none;
      margin: 0;
      padding: 0;
    }

    .groupHeader {
      color: #8c97a8;
      padding: 16px 30px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      -o-user-select: none;
      user-select: none;
      width: 100%;

      .title {
        color: inherit;
        font-size: 18px;
        line-height: 1.3;
        padding-right: 5px;
        position: relative;
      }

      .datetime {
        color: inherit;
        font-size: 14px;
        line-height: 1.3;
      }
    }

    .noti_ele {
      padding: 15px 30px;
      width: 100%;

      &:hover {
        background-color: rgba($color: #b0bac9, $alpha: 0.14);
      }

      .noti_msg {
        font-size: 14px;
        color: #333333;
        line-height: 1.3;
        flex-grow: 1;
        margin: 0;
        margin-right: auto;
        word-break: break-word;

        a,
        b,
        strong {
          color: inherit;
          font-weight: 600;
          text-decoration: none;
        }
      }

      .noti_time {
        color: #a4a4a4;
        display: inline-block;
        font-size: 12px;
        line-height: 1.3;
        margin-right: auto;
      }
    }
  }
}

.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
  background: rgba(255, 255, 255, 0.8);
}

.swal2-popup {
  border-radius: 5px;
  border: 1px solid #efefef;
  box-shadow: 0 20px 40px rgba(158, 158, 158, 0.4);
}

.swal2-styled.swal2-confirm {
  background-color: $primary-color;
  margin-right: 15px;
}

.ant-modal {
  top: 100px;
  padding-bottom: 100px;
}

.modal-video {
  background-color: rgba($color: #ffffff, $alpha: 0.5) !important;
}

.modal-video-close-btn::before,
.modal-video-close-btn::after {
  background: red !important;
}

.helpPage_body {
  .secHead {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 30px 0;
    width: 100%;

    .title_sm {
      color: #fe0000;
      font-size: 20px;
      font-weight: 600;
      line-height: 1.3;
      margin-bottom: 0;
    }

    h3 {
      color: #000000;
      font-size: 28px;
      font-weight: 700;
      line-height: 1.3;
      margin-bottom: 10px;
      text-align: center;
      text-transform: uppercase;
      width: 100%;

      &.underline {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &::after {
          content: '';
          height: 5px;
          background-color: #fe0000;
          width: 100px;
          display: inline-block;
          margin: 5px 0;
          border-radius: 100px;
        }
      }
    }

    .subheading {
      color: #9a9a9a;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.3;
      margin: 0;
      max-width: 100%;
      text-align: center;
      width: 545px;
    }
  }

  .videos_Sec {
    margin: 15px 0 40px;

    .videosList {
      margin: 0 -15px;
      padding: 0;

      li {
        display: inline-block;
        padding: 0 15px;
        width: auto;
        max-width: 33.33%;

        @media screen and (max-width: 767px) {
          width: 100%;
          max-width: 100%;
        }

        .video-box {
          background-color: #eff7ff;
          border-radius: 3px;
          border: 1px solid #d4e4f3;
          max-width: 100%;
          overflow: hidden;
          width: 286.33px;

          @media screen and (max-width: 767px) {
            width: 100%;
          }

          &.video1 {
            .videoWrappwe {
              background-image: url('../images/videoThumb1.jpg');
            }
          }

          &.video2 {
            background-color: rgba($color: #ff6969, $alpha: 0.18);
            border-color: #f1c1c1;

            .videoWrappwe {
              background-image: url('../images/videoThumb2.jpg');
            }
          }

          &.video3 {
            background-color: rgba($color: #c869ff, $alpha: 0.18);
            border-color: #e3c6f3;

            .videoWrappwe {
              background-image: url('../images/videoThumb3.jpg');
            }
          }

          .videoWrappwe {
            align-items: center;
            background-position: 0 0;
            background-repeat: no-repeat;
            background-size: cover;
            display: inline-flex;
            height: 235px;
            justify-content: center;
            position: relative;
            width: 100%;

            &::after {
              background-color: rgba($color: #333333, $alpha: 0.45);
              content: '';
              display: inline-block;
              height: 100%;
              left: 0;
              position: absolute;
              top: 0;
              width: 100%;
              z-index: 1;
            }

            .playvdo_icon {
              z-index: 2;
            }
          }

          .playvdo_icon {
            background-color: rgba(0, 0, 0, 0);
            background-color: $primary-color;
            background-image: url('../images/ic-media-play.png');
            background-position: 0 0;
            background-repeat: no-repeat;
            background-size: 100% auto;
            border-radius: 9px;
            border-radius: 100px;
            display: inline-flex;
            height: 54px;
            overflow: hidden;
            position: relative;
            text-indent: 100px;
            text-indent: 100px;
            white-space: nowrap;
            width: 54px;
            @include transition(all 0.25s ease);

            &:hover {
              background-color: rgba(0, 0, 0, 0);
              @include transform(scale(1.1));
            }

            &.playbg {
              background-image: none;
              text-indent: inherit;
            }
          }
          .play_circle {
            font-size: 40px;
          }

          .videoTitle {
            color: #000000;
            font-size: 16px;
            font-weight: 600;
            padding: 15px 5px;
            text-align: center;
            // text-transform: uppercase;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            -o-user-select: none;
            user-select: none;
          }
        }
      }
    }
  }

  .faqAccordionWrapper {
    .actionbtn {
      background: rgba(0, 0, 0, 0);
      height: 40px;
      position: absolute;
      right: 35px;
      top: 7px;
      width: 40px;
      z-index: 10;
    }

    .ant-collapse {
      background-color: rgba(0, 0, 0, 0);
      border: none;
      width: 100%;

      &>.ant-collapse-item {
        background-color: rgba($color: #000000, $alpha: 0);

        &>.ant-collapse-header {
          align-items: center;
          border: none;
          color: #333;
          display: flex;
          flex-direction: row-reverse;
          font-size: 16px;
          font-weight: 600;
          justify-content: space-between;
          justify-content: flex-end;
          line-height: 1.3;
          padding: 15px 0 15px 20px;
          padding: 15px 90px 15px 20px;

          &::after,
          &::before {
            content: none;
          }

          .anticon {
            background-color: rgba(0, 0, 0, 0);
            background-image: url('../images/ico-accord-arrow.png');
            background-position: 0 0;
            background-repeat: no-repeat;
            background-size: 100% auto;
            display: inline-block;
            flex: 0 0 20px;
            height: 20px;
            margin: 0;
            position: absolute;
            width: 20px;
            right: 0;
            top: 18px;

            &>svg {
              display: none;
            }
          }
        }

        &>.ant-collapse-content {
          background-color: rgba(0, 0, 0, 0);
          border: none;
          color: #333333;
          padding: 0 20px 30px;

          .ant-collapse-content-box {
            padding: 0;

            p {
              color: #333333;
              font-size: 16px;
              font-weight: 400;
              line-height: 1.6;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }

        &.ant-collapse-item-active {
          background-color: rgba($color: #b0bac9, $alpha: 0.05);

          &>.ant-collapse-header {
            color: #fe0000;

            .anticon {
              background-position: 0 bottom;
            }
          }
        }
      }
    }
  }

  .contact_info {
    .contactBox {
      align-items: flex-start;
      background: rgba($color: #4bb406, $alpha: 0.12);
      border-radius: 5px;
      border: 1px solid #cfebbb;
      display: flex;
      padding: 30px;
      position: relative;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      -o-user-select: none;
      user-select: none;

      @media screen and (max-width: 567px) {
        padding: 15px;
        text-align: center;

        .cell {
          margin: 0 !important;
        }
      }

      &.mailbox {
        background: rgba($color: #98ebff, $alpha: 0.2);
        border: 1px solid #c4e5ec;

        .icon_wrap {
          border: 1px solid #c4e5ec;
        }
      }

      &>.cell {
        &:first-child {
          padding-right: 30px;

          @media screen and (max-width: 567px) {
            padding-right: 0;
          }
        }
      }

      .email-icon {
        font-size: 30px;
      }

      .phone-icon {
        font-size: 30px;
      }

      .icon_wrap {
        align-items: center;
        background-color: #fff;
        border-radius: 100px;
        border: 1px solid #cfebbb;
        display: inline-flex;
        height: 73px;
        justify-content: center;
        padding: 0;
        position: relative;
        width: 73px;

        @media screen and (max-width: 567px) {
          width: 50px;
          height: 40px;

          img {
            height: 20px;
          }
        }
      }

      .contact_link {
        color: #000000;
        font-size: 20px;
        font-weight: 600;
        line-height: 1.3;
        position: relative;
        margin: 0 0 10px;
        text-decoration: none;
      }

      p {
        color: #272d3c;
        font-size: 14px;
        font-weight: 400;
        margin: 0;
        width: 100%;
      }
    }
  }
}

.ant-modal {
  .ant-rate {
    display: flex;
    margin-right: 30px;

    .ant-rate-star {
      @include transition(all 0.25s ease);
      align-items: center;
      background-color: #f3f3f3;
      border-radius: 8px;
      display: flex;
      height: 50px;
      justify-content: center;
      width: 50px;

      * {
        @include transition(all 0.25s ease);
      }

      [role='radio'] {
        position: relative;
        align-items: center;
        border-radius: 8px;
        display: flex;
        height: 50px;
        justify-content: center;
        width: 50px;
        // &[aria-checked="true"] {
        //   .ant-rate-star-second {
        //     color: #ffffff;
        //   }
        // }
      }

      .ant-rate-star-first,
      .ant-rate-star-second {
        color: #afafaf;
      }

      &.ant-rate-star-full {
        background-color: #5cdb95;

        .ant-rate-star-second {
          color: #ffffff;
        }
      }

      &>div:hover,
      &>div:focus-visible {
        transform: scale(1);

        * {
          transform: none;
          margin: 0;
        }
      }
    }
  }
}

.ant-popover {
  &.saveFilterPopover {
    max-width: 95%;
    padding-top: 0;
    width: 300px;

    .ant-popover-content {
      .ant-popover-arrow {
        display: none;
      }

      .ant-popover-inner {
        border-radius: 5px;
        border: 1px solid #ececec;
        box-shadow: 0 5px 15px rgba(159, 159, 159, 0.15);
        padding: 15px;
      }

      .ant-popover-title {
        color: #333;
        display: none;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.3;
        min-height: initial;
        padding: 0 0 10px;
        position: relative;
        width: 100%;
      }

      .ant-popover-inner-content {
        padding: 0;

        .filter_save_content {
          display: inline-block;
          position: relative;
          width: 100%;

          &>.btn-default {
            background-color: rgba($color: #000000, $alpha: 0);
            border: 1px solid #ececec;
            color: #333333;

            .ico {
              margin-left: auto;
            }

            &:not(.saveFilterbtn)::after {
              content: none;
            }

            &:hover {
              border-color: #333333;
            }
          }

          .saveFilterbtn {
            background-color: $primary-color;
            // background-color: #333333;
            border-color: $primary-color;
            border-width: 2px;
            border: none;
            box-shadow: none;
            color: #ffffff;
            // font-weight: 500;
            height: 45px;
            line-height: 1.3;
            width: 100%;
          }

          .orcontent {
            align-items: center;
            display: inline-flex;
            justify-content: center;
            padding: 15px 0;
            position: relative;
            width: 100%;

            &>span {
              background-color: #ffffff;
              color: #9a9a9a;
              display: inline-block;
              position: relative;
              padding: 5px 10px;
              z-index: 1;
            }

            &::before {
              border-bottom: 1px solid #cfcfcf;
              content: '';
              display: inline-block;
              height: 1px;
              left: 0;
              position: absolute;
              width: 100%;
            }
          }

          .ant-input-search {
            margin-bottom: 15px;

            .ant-input-wrapper {
              input {
                background-color: #f2f6fc;
                color: #333333;
                font-size: 14px;
              }

              input {
                background-color: #fff;
                border: 1px solid #b0bac9;
                border-radius: 3px 0 0 3px;
                @include box-shadow(none);
                height: 40px;
                margin: 0;
                padding: 5px 0 5px 15px;
                position: relative;
                overflow: hidden;
                z-index: 1;

                &:focus {
                  border-color: #333;
                  outline: 2px solid rgba(0, 0, 0, 0.12);
                }
              }

              .ant-input-group-addon {
                left: auto;

                .ant-btn-primary {
                  border-color: $primary-color;
                  background: $primary-color;
                  border-radius: 0 3px 3px 0;
                  text-shadow: none;
                  @include box-shadow(none);
                  align-items: center;
                  display: flex;
                  font-size: 24px;
                  height: 40px;
                  justify-content: center;
                  padding: 0;
                  width: 40px;

                  &>.anticon {
                    line-height: 1;
                    display: inline-flex;
                  }
                }
              }

              .search-clear-button {
                // border: 1px solid red;
                z-index: 10;
                display: inline-block;
                position: absolute;
                right: 0;
                top: 0;

                svg {
                  fill: red;
                }
              }
            }
          }
        }
      }

      .filterpopScroll {
        display: flex;
        margin: -10px -15px 0;
        max-height: 200px;
        padding: 0 15px;

        ul {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          margin-top: 7.5px;
          position: relative;
          width: 100%;

          &>* {
            margin: 2.5px 0;
            margin: 0;
            width: 100%;
          }

          li {
            align-items: center;
            background-color: rgba($color: #000000, $alpha: 0);
            border-bottom: 1px solid #ccc;
            display: flex;
            font-size: 14px;
            font-weight: 400;
            height: initial !important;
            justify-content: space-between;
            min-height: initial;
            padding: 6px 0;
            width: 100%;

            .applybtn {
              border: 2px solid #e8e8e8;
              color: #333;
              font-size: 12px;
              font-weight: 500;
              line-height: 1.3;
              min-height: initial;
              padding: 0 15px;
              text-transform: initial;

              &:hover,
              &:focus {
                color: #ffffff;
                outline: 0;
                box-shadow: none;
                background-color: #fe0000;
              }
            }
          }
        }
      }
    }
  }
}

.filterpopScrollcolumn {
  display: flex;
  margin: -10px -15px 0;
  max-height: 300px;
  padding: 0 15px;

  ul {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-top: 7.5px;
    position: relative;
    width: 100%;
    padding: 0 !important;

    &>* {
      margin: 2.5px 0;
      margin: 0;
      width: 100%;
    }

    li {
      align-items: center;
      background-color: rgba($color: #000000, $alpha: 0);
      border-bottom: 1px solid #ccc;
      display: flex;
      font-size: 14px;
      height: initial !important;
      justify-content: space-between;
      min-height: initial;
      padding: 12px 0px;
      width: 100%;
      margin-top: 5px;
      // padding-bottom: 15px; 
      &:last-child{
        padding-bottom: 0;
        border: none;
      }

      span{
        color: #000;
      }

      .applybtn {
        border: 2px solid #e8e8e8;
        color: #333;
        font-size: 12px;
        font-weight: 500;        
        width: 15px;
        height: 15px;
        line-height: 1.3;
        min-height: initial;
        padding: 0 15px;
        text-transform: initial;

        &:hover,
        &:focus {
          color: #ffffff;
          outline: 0;
          box-shadow: none;
          background-color: #fe0000;
        }
      }
    }
  }
}


.ant-popover {
  z-index: 99 !important;
}

.notesPopover {
  border-radius: 5px;
  border: 1px solid #ececec;
  box-shadow: 0 5px 15px rgba(159, 159, 159, 0.15);
  width: 300px;
  max-width: 95%;

  .popover-header {
    background-color: rgba($color: #000000, $alpha: 0);
    border-width: 0 0 1px;
    border-color: #ececec;
    border-style: solid;
    padding: 15px;
    color: #000000;
    font-size: 14px;
    line-height: 1;
    font-weight: 600;
  }

  .popover-body {
    background-color: rgba($color: #000000, $alpha: 0);
    border: none;
    color: #333;
    font-size: 14px;
    line-height: 1.6;
    padding: 5px 15px 15px;
    width: 100%;

    br {
      // display: none;
      display: inline-block;
      border: 1px solid #cccccc;
      width: 100%;
    }
  }
}

.dash_graphCard {
  .ant-spin-nested-loading {
    align-items: center;
    background-color: rgba($color: #000000, $alpha: 0.5);
    background-color: rgba($color: #ffffff, $alpha: 0.85);
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 5;

    &::after {
      // background-image: url('https://i.imgur.com/lL6tQfy.png');
      content: '';
      display: block;
      -webkit-filter: blur(5px);
      -moz-filter: blur(5px);
      -ms-filter: blur(5px);
      -o-filter: blur(5px);
      filter: blur(5px);
      height: 100%;
      left: 0;
      position: absolute;
      right: 0;
      width: 100%;
      z-index: 1;
    }

    &+[options='[object Object]'],
    &+div {
      position: relative;
      z-index: 1;
    }

    &>div {
      &>.ant-spin {
        max-height: initial;

        .ant-spin-text {
          text-shadow: none;
        }
      }
    }

    .ant-spin-dot-item {
      background-color: $primary-color;
    }

    .ant-spin-text {
      color: $primary-color;
      font-weight: 500;
      font-size: 16px;
      padding-top: 15px;
    }
  }
}

// ========================================================================
//                    Responsive Style Starts from here
// ========================================================================

@media screen and (max-width: 1199px) {
  .manageEmail_body {
    .filter_content {
      .card-body {
        &>.row {
          margin-bottom: -15px;
        }
      }

      .collapseContent,
      .ant-collapse {
        .fltr_cell {
          margin-bottom: 15px;
        }
      }
    }
  }

  .helpPage_body {
    .videos_Sec {
      .videosList {
        justify-content: flex-start !important;

        li {
          margin-bottom: 30px;
        }
      }
    }

    .contact_info {
      &>.row {
        &>.col-lg-6 {
          width: 100%;
        }
      }

      .contactBox:not(.mailbox) {
        margin-bottom: 30px;
      }
    }
  }
}

@media screen and (max-width: 991px) {}

@media screen and (max-width: 767px) {
  .manageEmail_body {
    .filter_content {
      .collapseContent {
        position: relative;

        .card {
          margin-bottom: 0;
        }

        .fltr_cell {
          margin-bottom: 30px;
          // &:last-child {
          //     margin: 0;
          // }
        }
      }
    }
  }

  .card {
    .card-header {
      &.staff_header {
        .sec_title.d-flex {
          order: 1;
          margin-right: initial !important;
        }

        .search_formControl.ms-auto {
          order: 3;
          margin: 10px 0 0;
          flex: 0 0 100% !important;
          width: 100% !important;
        }

        .filtersec {
          order: 2;

          .btn-default {
            &>span.d-inline-block {
              display: none !important;
            }
          }
        }
      }
    }

    .ant-dropdown {
      &.profileDropdown {
        .visible_sml {
          display: flex;
          width: 100%;
        }

        li .profile-button {
          align-items: center;
          background: #fff;
          padding: 10px 25px;
        }
      }
    }
  }
}

@media screen and (max-width: 540px) {
  .helpPage_body {
    .secHead {
      margin: 20px 0;

      h3 {
        font-size: 22px;
      }

      .subheading {
        font-size: 14px;
      }
    }

    .videos_Sec {
      .videosList {
        justify-content: center !important;
      }
    }

    .faqAccordionWrapper {
      .ant-collapse {
        &>.ant-collapse-item {
          &>.ant-collapse-header {
            font-size: 14px;
            padding: 15px;

            .anticon {
              flex: 0 0 20px;
              height: 20px;
              width: 20px;
            }
          }

          &>.ant-collapse-content {
            padding: 0 15px 20px;

            .ant-collapse-content-box {
              p {
                font-size: 14px;
              }
            }
          }
        }
      }
    }

    .contact_info {
      .contactBox {
        flex-wrap: wrap;

        &>.cell {
          width: 100%;

          &:first-child {
            padding-bottom: 20px;
          }
        }

        .contact_link {
          font-size: 13px;
        }

        p {
          font-size: 12px;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {}

@media screen and (max-width: 380px) {}

.jexcel_container .jexcel_content table>thead>tr>td {
  padding: 10px;
}

.jexcel_container .jexcel_content table>tbody>tr>td {
  padding: 10px;
  font-size: 14px;
  text-transform: initial;
}

.jexcel_container .jexcel_content table thead td .jexcel_dropdown,
.jexcel_container .jexcel_content table thead td select {
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #b0bac9;
  min-height: 36px;
  width: 100%;
  line-height: 36px;
  padding: 10px 15px;
  font-size: 12px;
  font-weight: 500;
  color: #333;
}

.jexcel_container .jexcel_content table thead td input {
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #b0bac9;
  min-height: 36px;
  width: 100%;
  line-height: 36px;
  padding: 10px 15px;
  font-size: 12px;
  font-weight: 500;
  color: #333;
  margin: 5px 0 0;
  height: 36px;
}

.jexcel_container .jexcel_content table thead td .jexcel_dropdown,
.jexcel_container .jexcel_content table thead td select,
.jexcel_container .jexcel_content table thead td input {
  &:focus {
    border-color: #333333;
  }
}

.jexcel>tbody>tr>td>input[type='checkbox'] {
  height: 18px !important;
  margin-top: 0 !important;
  cursor: pointer;
  position: relative;
  width: 18px !important;
}

.ant-dropdown-trigger {
  @include transition(all 0.25s ease);

  &.ant-dropdown-open {
    border-color: #333333 !important;
    color: #333 !important;
  }
}

.jexcel>tbody>tr>td.readonly {
  color: rgba(17, 3, 3, 0.62) !important;
}

strong {
  font-weight: bold !important;
}

.svdfltr {
  white-space: normal;
  flex: 1;
  line-break: anywhere;
  display: inline-block;
  margin-right: 10px;
  line-height: 17px;
  cursor: pointer;
}

// .app_form .btn-default.filter--btn:hover, .app_form .btn-default.filter--btn:active {
//   color: #000 !important;
//   border-color: #000 !important;
// }

.input-group .ant-select-multiple.ant-select-show-arrow .ant-select-selector,
.input-group .ant-select-multiple.ant-select-allow-clear .ant-select-selector {
  padding-right: 69px;
}

form.advancedsearchmodalbox .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  cursor: pointer !important;
}

.equipmentdrop .ant-select .ant-select-selector,
.equipmentdrop .ant-select {
  position: relative;
}

.equipmentdrop.equipCrossIcon {
  .ant-select {
    .ant-select-clear {
      top: 9px !important;
    }
  }
}

.equipmentdrop .ant-select .ant-select-clear {
  opacity: 1;
  right: 45px !important;
  top: 15px !important;
  margin: 0 !important;
}

.equipmentdrop .ant-select .ant-select-selector:after {
  background-color: rgba(0, 0, 0, 0);
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAALJJREFUSEtjZKAxYKSx+QyjFhAM4WEaROu3H1cA+T3Q0/IBwTAAKsCnHiOIQIoZmZnugwz+z8CYGOhmtgCfJet3nUpgZPg/H6z+7z9FdEfhtYCQJciGE20BSCGGRiw+IUYNyCycqQifAcQajtcCXD6BaIKEOaEgJGgBNkuQI5yYREBURkMPEmJcDnMIURag+4QYl5NsAdgSEjMgUXGAL5MRI0d0EBFjGDY1oxYQDLmhH0QAyCxlGQM7hxMAAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  background-size: 20px;
  width: 20px;
  height: 20px;
  right: 15px;
  top: 15px;
  margin: auto;
  content: '';
  position: absolute;
}

.reloadadvancedsearch {
  .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {

    cursor: pointer;

  }
}

// .input-group .ant-select-clear {
//   right: 10px;
// }


.card .card-header .filtersec .btn-default:hover,
.app_form .btn--wrap .input-field .btn-default:hover {
  background-color: #fe0000;
  color: #ffffff;
  border-color: #fe0000;
}

.react-datepicker__close-icon {
  top: 25%;
  right: 7px;
  width: 16px;
  height: 16px !important;
  position: absolute;
}

.react-datepicker__close-icon::after {
  right: 0 !important;
  top: 0;
  margin: 0;
}

.datepicker--wrap {
  .datepicker-close--btn {
    left: 218px;
    top: 8px;
    cursor: pointer;
    z-index: 2;
    padding: 2px 4px;
    border-radius: 50%;
    display: none;
    background: none;

    &.date-added {
      display: inline-block;
    }
  }
}

.datePic {
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;

    .btn-primary {
      margin-top: 15px !important;
      margin-left: 0 !important;
    }
  }
}




// insert carrier
.insertcarrier-stats {
  .stats_col {
    .cardContent {
      .cell {
        &:last-child {
          padding-left: 10px;
        }
      }

      .img_wrap {
        border-radius: 100px;
        align-items: center;
        display: flex;
        flex: 0 0 59px;
        justify-content: center;
        height: 59px;
        width: 59px;

        &.newrecords {
          background-color: rgba($color: #b8001bb5, $alpha: 0.12);

          .iconstat {
            background-image: url('../images/new1folder.png');
            width: 100%;
          }
        }

        .newRecord {
          font-size: 35px;
        }

        &.updaterecords {
          background-color: rgba($color: #65fb03c4, $alpha: 0.13);

          .iconstat {
            background-image: url('../images/updated.png');
            height: 36px;
            width: 100%;
          }
        }

        .updated {
          font-size: 30px;
        }

        &.updaterecords1 {
          background-color: rgba(254, 0, 0, 0.1);

          .errors {
            font-size: 35px;
          }

          .iconstat {
            background-image: url('../images/updated.png');
            height: 36px;
            width: 100%;
          }
        }

        &.duplicaterecords {
          background-color: rgba($color: #e9a24cb8, $alpha: 0.13);

          .iconstat {
            background-image: url('../images/duplicate.png');
            width: 100%;
          }
        }

        .duplicate {
          font-size: 35px;
        }

        .updated {
          font-size: 35px;
        }

        .iconstat {
          background-color: rgba(0, 0, 0, 0);
          background-position: 0 0;
          background-repeat: no-repeat;
          background-size: 100% auto;
          display: inline-block;
          flex: 0 0 32px;
          height: 32px;
          line-height: initial;
          overflow: hidden;
          text-indent: 100px;
          white-space: nowrap;
          width: 32px;
        }
      }

      h4 {
        color: #606d81;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 5px;
        position: relative;
        width: 100%;

        strong {
          color: #4d4f5c;
        }
      }

      h3,
      .stat_val {
        color: #4d4f5c;
        font-size: 26px;
        font-weight: 700;
        margin: 0;
        position: relative;
        width: 100%;
      }
    }
  }
}

.error-msg {
  position: absolute;
  /* bottom: -22px; */
  font-size: 16px;
  margin-top: 7px;
}


.cardContent2 {
  .cell {
    &:first-child {
      margin: 0 !important;
    }

    &:last-child {
      width: 100%;
    }
  }
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: inherit;
}

.viewNotificationsDropdown .notiDropdown_content li:hover {
  background-color: #e6f7ff;
}

.ant-select-arrow {
  pointer-events: inherit;

}

.manageemailtable.customtable-1 tr th:nth-child(2),
.manageemailtable.customtable-1 tr td:nth-child(2) {
  width: 250px;
}

.manageemailtable.customtable-1 tr td:nth-child(2) {
  white-space: nowrap;
  overflow: hidden;
  /* width: 100%; */
  text-overflow: ellipsis;
  max-width: 300px;
}

.manageemailtable tr td:last-child {
  text-align: left;
}

.ant-popover.saveFilterPopover .ant-popover-content .ant-popover-inner-content .filter_save_content .ant-input-search span.ant-input-wrapper.ant-input-group {
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
}

.ant-popover.saveFilterPopover .ant-popover-content .ant-popover-inner-content .filter_save_content .ant-input-search span.ant-input-wrapper.ant-input-group input {
  border: none;
  height: 100%;
  outline: none !important;
  color: #000;
  font-size: 13px;
}

.ant-popover.saveFilterPopover .ant-popover-content .ant-popover-inner-content .filter_save_content .ant-input-search span.ant-input-wrapper.ant-input-group span.ant-input-group-addon {
  width: 40px;
}