

.waves-ripple:not(:first-of-type) {
  display: none;
}

.visible_sm {
  display: none;
}

#main {
  // min-height: calc(100vh - 102px);
  position: relative;

  &.main-full {
    // padding-left: 70px;
    padding-left: 100px;

    // .dashHeader {
    //   padding-left: 130px;
    // }
  }
}

.loader {
  background: rgba(255, 255, 255, 0.85);
  position: fixed;
  height: 100vh;
  width: 100%;
  z-index: 999999;
  top: 0;
  left: 0;

  .loading_img {
    display: inline-block;
    margin: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    @include transform(translate(-50%, -50%));
    width: 50px;
  }
}

footer {
  &.dashFooter {
    background-color: #ffffff;
    padding: 20px 30px;

    .container {
      padding: 0;
      max-width: 100%;
      width: 100%;
    }

    .copytext {
      color: #333;
      font-size: 12px;
      font-weight: 400;

      a {
        color: #000;
        display: inline-block;
        margin: 0 5px;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .quicklinks {
      display: flex;
      margin: 0 -15px;

      li {
        padding: 0 15px;
      }

      a {
        color: #333;
        display: inline-block;
        font-size: 14px;
        line-height: 1.3;
        font-weight: 400;
        position: relative;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.modal-overlay,
.modal-backdrop {
  opacity: 0.8 !important;
  background-color: #ffffff;
}

.modal.appModal {
  @include box-shadow(none);
  background-color: transparent;
  height: auto;
  overflow-y: auto;
  max-width: 100%;
  padding: 10%;
  width: 100%;
  max-height: 100vh;
  top: 0 !important;

  .modal-content {
    border-radius: 5px;
    border: 1px solid #efefef;
    @include box-shadow(0 20px 40px rgba($color: #9e9e9e, $alpha: 0.4));
    padding: 30px;
    max-width: 720px;
    width: 100%;
    margin: 0 auto;

    .modal-header {
      padding: 0 0 20px;

      .modal-title {
        font-size: 20px;
        font-weight: 600;
        line-height: 1.3;
      }
    }

    .modal-body {
      padding: 25px 0 0;
    }
  }
}

#editor {
  height: 260px;
}

.dropdown-action {
  display: inline-flex;
  position: relative;

  .dropdown-trigger {
    align-items: center;
    display: flex;
    color: rgba(#000000, $alpha: 0.18);
    height: 24px;
    line-height: 1.3;
    position: relative;
    justify-content: center;
    width: 24px;
  }

  .dropdown-content {
    border-radius: 5px;
    border: 1px solid #ececec;
    -webkit-box-shadow: 0 5px 15px rgba(159, 159, 159, 0.15);
    box-shadow: 0 5px 15px rgba(159, 159, 159, 0.15);
    left: auto !important;
    right: 0 !important;
    padding: 10px 0;
    top: 100% !important;
    min-width: 140px;

    li {
      display: inline-block;
      min-height: initial;

      a {
        color: #7a7a7a;
        display: inline-flex;
        justify-content: space-between;
        font-size: 14px;
        padding: 10px 20px;
        width: 100%;

        .ico {
          margin-left: 20px;
        }
      }

      &.nohover {
        &:hover {
          background-color: rgba(0, 0, 0, 0);
        }
      }
    }
  }
}

.dashHeader {
  align-items: center;
  bottom: 100%;
  height: 102px;
  justify-content: flex-start;
  padding: 20px 30px;
  margin-top: -102px;
  // padding-left: 290px;
  // position: absolute;
  // right: 0;

  h2 {
    color: #333;
    font-size: 24px;
    font-weight: 700;
    margin: 0;
    text-transform: capitalize;
  }
}

.dash_content_body {
  padding: 0 30px 30px;
  min-height: calc(100vh - 165px);
}

.brand-sidebar {
  background-color: rgba(0, 0, 0, 0);
  box-shadow: none;
  width: 260px;

  .logo-wrapper {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: space-between;
    line-height: 1 !important;
    padding: 15px 20px;

    .brand-logo {
      .hide-on-collapse {
        display: block;
      }

      .show-on-collapse {
        display: none;
      }
    }

    a {
      &.brand-logo {
        display: inline-flex;
        padding: 0;
        position: relative;
        right: initial;
        top: initial;

        img {
          height: auto;
        }
      }

      &.navbar-toggler {
        display: inline-flex;
        margin-right: 20px;
        padding: 0;
        position: relative;
        right: initial;
        top: initial;

        &:focus {
          box-shadow: none;
          outline: none;
        }

        .hamburger_icon {
          background-color: rgba(0, 0, 0, 00);
          background-image: url('../../images/menu-hamburger.png');
          background-position: 0 0;
          background-repeat: no-repeat;
          background-size: 100% auto;
          border-radius: 9px;
          display: inline-flex;
          height: 40px;
          overflow: hidden;
          position: relative;
          text-indent: 100px;
          width: 40px;
          // &.hamburger_icon_menus
          // {
          //   text-indent: inherit;
          //   background-image: none;
          // }
        }
      }
    }
  }
}

.sidenav-main {
  background: #ffffff;
  border-radius: 0;
  display: flex;
  padding: 0;
  flex-direction: column;

  // * {
  //     position: relative;
  //     top: auto;
  // }
  .brand-sidebar {
    justify-content: space-between;
    height: 70px;
    padding: 15px 10px 15px 20px;
    position: relative;

    .logo-wrapper {
      padding: 0;

      a.navbar-toggler {
        margin-right: 0;
      }
    }
  }

  ul#slide-out.sidenav {
    // border-radius: 37px;
    // @include box-shadow (5px 5px 20px rgba(159, 159, 159, 0.25));
    // height: calc(100vh - 136px) !important;
    // padding: 30px 0;
    background: transparent;
    border: none;
    box-shadow: none;
    display: inline-block;
    height: 100% !important;
    left: initial;
    padding: 0;
    position: relative;
    top: initial;
    overflow-y: scroll !important;
    overflow-x: hidden!important;
  }

  .sidenav {
    li {
      a,button{
        align-items: center;
        border-radius: 0 !important;
        color: #333333;
        display: inline-flex;
        font-size: 14px;
        font-weight: 500;
        height: auto;
        justify-content: flex-start;
        line-height: 1.3;
        margin: 0;
        padding: 15px 23px !important;
        white-space: nowrap;
        width: 100%;

        &::before {
          @include transform(translateY(-50%));
          @include transition(all 0.25s ease);
          background-color: $primary-color;
          content: '';
          display: none;
          height: 100%;
          left: 0;
          position: absolute;
          top: 50%;
          width: 3px;
        }

        i.navico {
          align-items: center;
          background-color: rgba(0, 0, 0, 0);
          background-image: url('../../images/icos-leftbar-spirit.png');
          background-repeat: no-repeat;
          background-size: auto;
          border-radius: 9px;
          border: none;
          display: inline-block;
          height: 24px;
          margin-right: 5px;
          width: 24px;

          &.dash {
            background-position: 0 0;
          }

          &.staff {
            background-position: -44px 0;
          }

          &.email {
            background-position: -88px 0;
          }

          &.projects {
            background-position: -132px 0;
          }

          &.settings {
            background-position: -176px 0;
          }

          &.metrix {
            background-position: -262px 0;
          }

          &.help {
            background-position: -220px 0;
          }

          &.reloadcarrier {
            background-position: -304px 0;
          }

          &.insertcarrierdata {
            background-position: -340px 0;
            border-radius: 0px !important;

          }
        }

        span.badge.pill {
          margin: 0 0 0 auto !important;
          position: relative;
          right: initial;
        }

        &.active {
          // margin: 0;
          // margin-right: 0px;
          color: $primary-color;
          font-weight: 500;
          background: rgba(0, 0, 0, 0);
          @include box-shadow(none);
        }

        &.waves-effect .waves-ripple {
          background: rgba(0, 0, 0, 0.05);
        }
        .material-symbols-outlined
        {
          height: 24px;
          margin-right: 5px;
          width: 24px;
        }
      }

      &.active a,
      a.active {
        color: $primary-color;
        font-weight: 500;

        &::before {
          display: inline-block;
        }

        i.navico {
          &.dash {
            background-position: 0 bottom;
          }

          &.staff {
            background-position: -44px bottom;
          }

          &.email {
            background-position: -88px bottom;
          }

          &.projects {
            background-position: -132px bottom;
          }

          &.settings {
            background-position: -176px bottom;
          }

          &.metrix {
            background-position: -262px -33px;
          }

          &.help {
            background-position: -220px bottom;
          }

          &.reloadcarrier {
            background-position: -304px bottom;
          }

          &.insertcarrierdata {
            background-position: -340px bottom;
            border-radius: 0px !important;


          }
        }
      }
    }
  }
}

.nav-collapsed {
  .brand-sidebar {
    width: 100%;

    .logo-wrapper {
      justify-content: center;
      padding: 0;

      a.navbar-toggler {
        // display: none;
        visibility: visible;
        opacity: 1;
        margin: 0;

        .hamburger_icon {
          background-image: url('../../images/menu-back.png');
        }
      }
    }

    .brand-logo {
      .hide-on-collapse {
        display: none;
      }

      .show-on-collapse {
        display: inline-block;
      }
    }
  }

  &.sidenav-main {
    width: 100px;

    .brand-sidebar {
      padding: 15px 10px;
    }

    .sidenav {
      li {
        a {

          // padding: 10px 16px !important;
          i.navico {
            margin-right: 0;
          }
        }
      }
    }
  }
}

.nav-expanded:not(.nav-lock):not(.sideNav-lock) {
  .brand-sidebar {
    .logo-wrapper {
      a.navbar-toggler {
        .hamburger_icon {
          background-image: url('../../images/menu-back.png');
        }
      }
    }
  }
}

.ico {
  background-color: rgba(0, 0, 0, 0);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 100% auto;
  display: inline-block;
  flex: 0 0 24px;
  height: 24px;
  line-height: initial;
  overflow: hidden;
  text-indent: 100px;
  white-space: nowrap;
  width: 24px;

  &.settings {
    background-image: url('../../images/ico-settings.png');
  }

  &.power {
    background-image: url('../../images/ico-logout.png');
  }

  &.ico-column {
    background-image: url('../../images/ico-columns.png');
  }

  &.ico-cancel {
    background-image: url('../../images/ico-clear.png');
  }

  &.ico-ellipse-vertical {
    background-image: url('../../images/ico-menu-options.png');
  }

  &.ico-mail {
    background-image: url('../../images/ico-mail.png');
  }

  &.ico-print {
    background-image: url('../../images/ico-print.png');
  }

  &.ico-download {
    background-image: url('../../images/ico-download.png');
  }

  &.ico-send {
    background-image: url('../../images/ico-send.png');
  }

  &.ico-plus {
    background-image: url('../../images/ico-plus.png');
  }

  &.ico-save {
    background-image: url('../../images/ico-save.png');
  }

  &.ico-delete {
    background-image: url('../../images/ico-delete.png');
  }

  &.ico-edit {
    background-image: url('../../images/ico-edit.png');
  }

  &.ico-filter {
    background-image: url('../../images/ico-filter.png');
  }
}

.navbar {
  @include transition(all 0.25s ease);

  &.navbar-fixed {
    height: 102px;
    padding: 0;
  }

  &.sticky_navbar {
    height: 70px;

    nav.navbar-main {
      background-color: #ffffff;
      @include box-shadow(0px 10px 20px rgba(0, 0, 0, 0.05));
      height: 70px;
      padding: 0 30px;

      .nav-wrapper {
        .navbar-list {
          border: none;
          height: 62px;

          li.notification_col {
            position: relative;
          }
        }
      }
    }
  }

  nav.navbar-main {
    background-color: rgba(0, 0, 0, 0);
    @include box-shadow(none);
    line-height: 70px;
    height: 102px;
    padding: 20px 30px;
    @include transition(all 0.25s ease);

    .nav-wrapper {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: space-between;

      .brand-sidebar {
        box-shadow: none;
        height: 100%;
        left: initial;
        margin: 0;
        position: relative;
        top: initial;
        width: 0;

        .logo-wrapper {
          padding: 15px;
          opacity: 0;
          visibility: hidden;
        }
      }

      .navbar-list {
        background-color: rgba(255, 255, 255, 0.3);
        border: 1px solid rgba(223, 223, 223, 1);
        border-radius: 5px;
        height: 100%;
        overflow: hidden;
        position: relative;
        padding: 0px !important;

        &.header-navbar-list 
        {
          overflow: inherit;
        }

        &>li,
        &>li>a {
          display: inline-flex;
          height: 100%;
          flex-wrap: wrap;
        }
        &.nav-dropdowns 
        {
          li 
          {
            background: rgba(255, 255, 255, 0.15);
            text-shadow: none !important;
            color: #333333;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            padding: 0 15px;
            .avatar-status 
            {
              line-height: 0;
            }

            
            
          }
        }

        li {
          position: relative;

          &.notification_col {
            &::before {
              background-color: #cbcbcb;
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
              content: '';
              height: 28px;
              width: 1px;
              position: absolute;
              left: 0;
              top: 50%;
              @include transform(translateY(-50%));
              z-index: 10;
            }

            .notification-badge {
              align-items: center;
              background-color: $primary-color;
              box-shadow: 0 3px 6px $primary-color;
              @include box-shadow(none);
              display: inline-flex;
              font-family: 'Poppins', sans-serif;
              font-size: 12px;
              font-weight: 500;
              height: 12px;
              justify-content: center;
              line-height: 20px;
              margin: 0;
              padding: 0;
              width: 12px;
              position: absolute;
              // top: -3px;
              // right: -3px;
              top: 0;
              right: 0;
            }
          }

          a,
          button {
            background: rgba(255, 255, 255, 0.15) ;
            text-shadow: none !important;
            color: #333333;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            padding: 0 15px;
          }

          #profile-dropdown.dropdown-content {
            width: 199px !important;
          }

          .dropdown-content,
          .ant-dropdown {
            border-radius: 5px;
            border: 1px solid #ececec;
            @include box-shadow(0 5px 15px rgba(159, 159, 159, 0.15));
            left: auto !important;
            right: 0 !important;
            padding: 10px 0;

            li {

              a,
              button {
                display: inline-flex;
                justify-content: space-between;
                width: 100%;

                .ico {
                  margin-left: 20px;
                }
              }

              &.nohover {
                &:hover {
                  background-color: rgba(0, 0, 0, 0);
                }
              }
            }
          }

          #notifications-dropdown {
            padding: 10px 0;

            .dropheader {
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
              user-select: none;

              h3,
              h4,
              h5,
              h6 {
                color: #9a9a9a;
                font-size: 14px;
                font-weight: 400;
                line-height: 1.3;
              }

              .badge {
                align-items: center;
                background: #fd6600;
                border-radius: 100px;
                box-shadow: none;
                display: inline-flex;
                font-size: 14px;
                font-weight: 400;
                height: initial;
                justify-content: center;
                line-height: 1.3;
                padding: 2px 10px;
                position: relative;
              }

              &:hover {
                background-color: transparent;
              }
            }

            .notificationScroll {
              overflow-y: auto;
              max-height: 260px;
            }

            li {
              cursor: initial;

              &:not(.divider) {
                padding: 10px 20px 0;
              }

              a {
                border-bottom: 1px solid rgba($color: #b0bac9, $alpha: 0.45);
                color: #4d4f5c;
                display: inline-block;
                font-size: 14px;
                padding-bottom: 10px;
                padding-left: 27px;
                position: relative;

                b {
                  color: #333333;
                  font-weight: 500;
                }

                .status_dot {
                  background-color: #b0bac9;
                  border-radius: 100px;
                  display: inline-block;
                  height: 12px;
                  width: 12px;
                  position: absolute;
                  left: 0;
                  top: 5px;

                  &.unread {
                    background-color: #00bf26;
                  }

                  &.read {
                    background-color: #b0bac9;
                  }
                }
              }

              &>time {
                margin-left: 0;
              }
            }

            .viewmore {
              @include box-shadow(none);
              border: none;
              color: #fe0000;
              background-color: rgba($color: #fe0000, $alpha: 0.1);
              font-size: 12px;
              font-weight: 500;
              width: 100%;
            }

            .dropFooter {
              padding: 20px;
            }
          }
        }

        i,
        a.sidenav-trigger,
        a.sidenav-trigger i {
          line-height: initial;
          height: initial;
          display: inline-flex;
          position: relative;
        }

        .profile-button {
          flex-wrap: nowrap;
          padding: 0 10px;

          .user-avatar {
            // background-color: rgba(0, 0, 0, 0);
            // background-position: center;
            // background-repeat: no-repeat;
            // background-size: cover;
            // border: 1px solid #d2d2d2;
            // flex: 0 0 38px;
            // height: 38px;
            // line-height: initial;
            // margin-right: 10px;
            // overflow: hidden;
            // text-indent: 100px;
            // width: 38px;

            .avtarstyle{
            height: 100%;
            width: 100%;
            object-fit: cover;
            overflow: hidden;
            }

            img {
              -webkit-object-position: center center;
              object-position: center center;
              -webkit-object-fit: cover;
              object-fit: cover;
              min-height: 100%;
              min-width: 100%;
            }
          }

          .avatar-name {
            display: flex;
            line-height: 1.3;

            .text-truncate {
              color: #333333;
              font-size: 14px;
              font-weight: 500;
              line-height: 24px;
              margin-right: 5px;
              white-space: nowrap;
            }

            .ico_arrow {
              background-color: rgba(0, 0, 0, 0);
              background-image: url('../../images/ic-chevron-down.png');
              background-position: 0 0;
              background-repeat: no-repeat;
              background-size: 100% auto;
              flex: 0 0 24px;
              height: 24px;
              overflow: hidden;
              width: 24px;
            }
          }
        }
      }
    }
  }
}

.switch_ele {
  width: 64px;

  &>.switch_txt {
    font-size: 14px;
    color: #333333;
    font-weight: 500;
    line-height: 32px;
  }

  label {
    display: flex;
    position: relative;
    width: 100%;

    .lever {
      background-color: #ffffff;
      border: 1px solid #ccc;
      width: 100%;
      height: 32px;
      margin: 0;

      &::after {
        top: 3px;
        left: 3px;
        width: 24px;
        height: 24px;
      }

      &::before {
        background-color: #ccc;
        height: 24px;
        left: 3px;
        top: 3px;
        width: 24px;
      }
    }

    input[type='checkbox'] {
      cursor: pointer;
      height: 100%;
      opacity: 0;
      position: absolute;
      width: 100%;
      z-index: 100;

      &:checked+.lever {
        // background-color: #00C789;
        // border-color: #00C789;
        background-color: #02c541;
        border-color: #02c541;

        &::after {
          background-color: #ffffff;
          z-index: 1;
          left: 35px;
        }

        &::before {
          background-color: rgba(0, 0, 0, 0);
          left: 0;
          opacity: 0;
          visibility: hidden;
        }
      }

      &:checked+.lever+.stch_lbl.yeslbl {
        color: #fff;
        left: 10px;
        left: 23px;
        right: auto;
        opacity: 1;
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
      }

      &:checked+.lever+.yeslbl+.stch_lbl.nolbl {
        opacity: 0;
        -webkit-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
      }
    }

    .stch_lbl {
      color: #333333;
      display: inline-block;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.3;
      line-height: 32px;
      margin: 0;
      position: absolute;
      right: 10px;
      // top: 0;
      -webkit-transition: all 0.25s ease;
      -moz-transition: all 0.25s ease;
      -ms-transition: all 0.25s ease;
      -o-transition: all 0.25s ease;
      transition: all 0.25s ease;
    }

    .stch_lbl.yeslbl {
      opacity: 0;
    }
  }

  input[type='checkbox'] {
    &:checked {
      &:not(:disabled).tabbed {
        &:focus~.lever {
          &::before {
            -webkit-transform: scale(2.4);
            -ms-transform: scale(2.4);
            transform: scale(2.4);
            background-color: rgba(136, 136, 136, 0.15);
          }
        }
      }

      &:not(:disabled)~.lever {
        &:active {
          &::before {
            -webkit-transform: scale(2.4);
            -ms-transform: scale(2.4);
            transform: scale(2.4);
            background-color: rgba(136, 136, 136, 0.15);
          }
        }
      }
    }
  }

  &.active_swtch {
    width: 105px;

    label {
      width: 105px;

      &::after {
        top: 3px;
        left: 3px;
      }

      &::before {
        top: 3px;
        left: 3px;
      }
    }

    input[type='checkbox'] {
      &:checked+.lever {
        // width: 90px;

        &::after {
          left: 61px;
          left: 76px;
        }
      }
    }
  }
}

.select-wrapper {
  background-color: rgba(0, 0, 0, 0);
  border-radius: 4px;
  border: 1px solid #e8e8e8;
  @include box-shadow(0px 2px 9px rgba(#a5a5a5, $alpha: 0.15));
  width: 130px;

  input.select-dropdown {
    // background-color: #F8F9FB;
    // border-radius: 12px;
    // border: 1px solid #E7EAEF;
    border: none;
    box-shadow: none;
    box-sizing: border-box;
    color: #333;
    font-size: 14px;
    font-weight: 500;
    height: 44px;
    letter-spacing: 0;
    margin: 0;
    min-height: initial;
    min-width: initial;
    padding: 2px 40px 2px 15px;
    width: 100%;

    &:focus {
      border: none;
    }
  }

  .caret {
    background-color: rgba(0, 0, 0, 0);
    background-image: url('../../images/ico-down-arrow.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 3px;
    display: inline-block;
    fill: transparent;
    height: 24px;
    opacity: 1;
    right: 10px;
    width: 24px;
    // z-index: 10;
  }

  .dropdown-content {
    border-radius: 5px;
    border: 1px solid #ececec;
    -webkit-box-shadow: 0 5px 15px rgba(159, 159, 159, 0.15);
    box-shadow: 0 5px 15px rgba(159, 159, 159, 0.15);
    left: auto !important;
    min-width: 150px;
    right: -2px !important;
    top: -2px !important;

    li {

      &>a,
      &>span {
        color: #7a7a7a;
      }
    }
  }

  &.active_swtch {
    width: 130px;
  }
}
.courserPointer{
  cursor: pointer;
}
.card {
  border: none;
  border-radius: 5px;
  @include box-shadow(0px 10px 20px rgba(#000000, $alpha: 0.05));
  margin: 0 0 30px;
  padding: 20px;
  height: calc(100% - 30px);

  .card-header {
    align-items: center;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 30px;
    padding: 0;

    .sec_title {
      color: #000000;
      display: inline-block;
      font-size: 20px;
      font-weight: 600;
      line-height: 1.3;
      margin: 10px auto 10px 0;
      text-transform: uppercase;
    }

    .filtersec {
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-end;
      gap:20px;
      // margin: 0 -5px;

      // .input-field {
      //   margin: 5px 0;
      //   padding: 0 5px;
      // }

      .btn-default {
        min-height: initial;
        height: 44px;
      }

      .btn.btn-filter {
        @include box-shadow(0 2px 9px rgba($color: #a5a5a5, $alpha: 0.15));
        align-items: center;
        background-color: #ffffff;
        border-radius: 4px;
        border: 1px solid #e8e8e8;
        display: flex;
        height: 44px;
        justify-content: center;
        line-height: 1.3;
        padding: 5px 15px;
        position: relative;
        @include transition(all 0.25s ease);

        &:hover {
          border-color: #cccccc;
        }
      }

      .btn-icon {
        width: 44px;
      }

      .input-group.saveFilter-group {
        box-shadow: 0 2px 9px rgba(165, 165, 165, 0.15);
        align-items: center;
        background-color: #ffffff;
        border-radius: 4px;
        // border: 1px solid #E8E8E8;
        display: flex;
        height: 44px;
        justify-content: center;
        line-height: 1.3;
        position: relative;
        @include transition(all 0.25s ease);

        &:hover {
          border-color: #cccccc;
        }

        button,
        .dropdown-action {
          background-color: rgba(0, 0, 0, 0);
          height: 100%;
        }

        .ant-btn.ant-btn-primary {
          @include box-shadow(none);
          align-items: center;
          background-color: #ffffff;
          border-color: #e8e8e8;
          border: 1px solid #e8e8e8;
          // border-radius: 0;
          // border-radius: 4px 0 0 4px;
          border-radius: 4px;
          border-style: solid;
          // border-width: 0 1px 0 0;
          color: #333333;
          display: flex;
          height: 44px;
          justify-content: center;
          line-height: 1.3;
          // padding: 5px 20px;
          padding: 5px 10px 5px 20px;
          position: relative;
          @include transition(all 0.25s ease);

          .ico_arrow {
            background-color: rgba(0, 0, 0, 0);
            background-image: url('../../images/ic-chevron-down.png');
            background-position: 0 0;
            background-repeat: no-repeat;
            background-size: 100% auto;
            flex: 0 0 24px;
            height: 24px;
            margin-left: 7px;
            overflow: hidden;
            width: 24px;
          }

          &+.dropdown-trigger.btn-icon {
            border-color: #e8e8e8;
            border-radius: 0 4px 4px 0;
            border-style: solid;
            border-width: 1px 1px 1px 0;
            @include transition(all 0.25s ease);

            &:hover {
              border-color: #cccccc;
            }
          }

          &:hover {
            border-color: #cccccc;
          }
        }

        .savefltr_btn {
          padding: 5px 15px;
        }

        .dropdown-action {
          position: relative;

          &::before {
            // background-color: #E8E8E8;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            content: '';
            height: 100%;
            width: 1px;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            // z-index: 10;
          }
        }
      }

      .multiSelect_ele {
        .select-wrapper {
          .dropdown-content {
            top: 100% !important;
            width: 384px !important;
          }
        }
      }

      .form-switch {
        align-items: center;
        padding-left: 74px;
        padding-left: 64px;
        position: relative;

        .form-check-input {
          background: #ffffff !important;
          border-radius: 60px;
          border: 1px solid #b0bac9 !important;
          cursor: pointer;
          display: inline-block;
          height: 32px;
          left: 0;
          margin: 0 !important;
          padding-left: 0;
          position: absolute;
          top: 0;
          transition: all 0.3s ease;
          width: 64px;
          width: 54px;

          &:focus {
            outline: none !important;
            box-shadow: none !important;
          }

          &:checked[type='checkbox'],
          &:checked {
            background: #02c541 !important;
            border-color: #02c541 !important;

            &+label {
              line-height: 32px;
              position: static !important;

              &::after,
              &::before {
                left: 36px;
                left: 28px;
              }
            }
          }

          &+label {
            line-height: 32px;
            position: static !important;

            &::after,
            &::before {
              border-radius: 50%;
              content: '';
              cursor: pointer;
              display: inline-block;
              height: 20px;
              left: 6px;
              position: absolute;
              top: 6px;
              transition: all 0.25s ease;
              width: 20px;
            }

            &::after {
              background-color: #f1f1f1;
              box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
                0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
            }
          }
        }
      }
    }

    input.search_formControl,
    input[type='text']:not(.browser-default):focus:not([readonly]).search_formControl {
      background-image: url('../../images/ico-search.png');
      background-position: right 10px center;
      background-repeat: no-repeat;
      border: 1px solid #ccc;
      border-radius: 5px;
      height: 44px;
      padding: 5px 15px;
      margin: 0 20px 0 0;

      // &:focus {
      //   border-color: #333 !important;
      //   border-width: 1px !important;
      //   @include box-shadow(none);
      // }
    }
  }

  // .card_body {
  //     & > img {
  //         width: 100%;
  //     }
  // }
}

.select2-container.select2-container--default {
  width: 384px;
  max-width: 100%;
}

table {
  thead {
    tr {
      background: #f5f6fa;
      th{
        color: #4d4f5c;
      }

      th,
      td {
        font-size: 14px;
        font-weight: 500;

        a {
          display: inline-flex;
          position: relative;
        }
      }
    }
  }

  tr {

    td,
    th {
      padding: 10px 20px;

      .sort_btn {
        background-color: rgba(0, 0, 0, 0);
        background-image: url('../../images/ic-chevron-down.png');
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: 100% auto;
        display: inline-block;
        flex: 0 0 24px;
        height: 24px;
        line-height: initial;
        overflow: hidden;
        text-indent: 100px;
        white-space: nowrap;
        width: 24px;

        &.sortup {
          @include transform(rotate(180deg));
        }
      }
    }

    th {
      color: #9a9a9a;
      font-size: 12px;
      font-weight: 500;
      padding-bottom: 15px;
      padding-top: 15px;
    }

    td {
      color: #4d4f5c;
      font-size: 14px;
      font-weight: 400;

      &:last-child {
        &.action {
          max-width: 40px;
          width: 40px;
        }

        text-align: right;
      }

      .input-field {
        margin: 0;
      }

      .dropdown-action {
        .dropdown-trigger {
          align-items: center;
          background-color: #ffffff !important;
          border-radius: 5px;
          border: 1px solid #ccc !important;
          @include box-shadow(0 2px 9px rgba(165, 165, 165, 0.15));
          display: flex;
          height: 44px;
          justify-content: center;
          line-height: 1.3;
          padding: 5px 15px;
          position: relative;
          width: 40px;
          &:focus 
          {
            box-shadow: none !important;
          }
        }
      }

      .select-wrapper {
        width: 160px;
      }

      .badge {
        background-color: rgba($color: #b0bac9, $alpha: 0.15);
        border-radius: 100px;
        color: #b0bac9;
        display: inline-flex;
        font-size: 13px;
        font-weight: 500;
        line-height: 1.3;
        padding: 5px 10px;
       
        &.danger {
          background-color: rgba($color: #fe020026, $alpha: 0.15);
          color: #ff2c00;
          text-transform: capitalize;
        }
        &.danger:first-letter{
          text-transform: capitalize;
        }

        &.success {
          background-color: rgba($color: #00bf26, $alpha: 0.15);
          color: #00bf26;
          text-transform: capitalize;
        }
        &.success::first-letter{
          text-transform: capitalize;
        } 
        &.read {
          background-color: rgba($color: #00d3fe, $alpha: 0.15);
          color: #00d3fe;
          text-transform: capitalize;
        }
        &.read::first-letter{
          text-transform: capitalize;
        } 
        &.processed {
          background-color: rgba($color: #fafe0075, $alpha: 0.15);
          color: #ff8f00;
          text-transform: capitalize;
        }
        &.processed::first-letter{
          text-transform: capitalize;
        } 
        &.deferred {
          background-color: rgba($color: #fe020026, $alpha: 0.15);
          color: #ff2c00;
          text-transform: capitalize;
        }
        &.deferred::first-letter{
          text-transform: capitalize;
        } 
        &.dropped {
          background-color: rgba($color: #fe020026, $alpha: 0.15);
          color: #ff2c00;
          text-transform: capitalize;
        }
        &.dropped::first-letter{
          text-transform: capitalize;
        } 
      }
    }
  }
}

.stngsPage_body {
  .sechdr_2 {
    min-height: 45px;

    h3 {
      border-left: 3px solid #fe0000;
      font-size: 18px;
      font-weight: 600;
      line-height: 1.3;
      margin: 0;
      padding-left: 10px;
      position: relative;
    }
  }
}

.notifications_list {
  display: flex;
  margin-top: 30px;

  ul {
    float: left;
    width: 100%;

    .noti_ele {
      border-top: 1px solid #ccc;
      align-items: center;
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding: 10px 0;

      .noti-name {
        color: #272d3c;
        font-size: 14px;
        line-height: 1.3;
        font-weight: 500;
        margin-right: auto;
        word-break: break-word;
        width: 60%;
      }

      .deletebtn {
        background: transparent;
        // border: 1px solid #e9e9e9;
        height: 34px;
        width: 34px;
        margin-left: 10px;
        border-radius: 3px;
      }
    }
  }
}

.notiSetting-body {
  &>.row {
    margin: 0 -15px;

    .col-lg-6 {
      padding: 0 15px;

      &:first-child {
        border-right: 1px solid #b0bac9;
        padding-right: 30px;
      }

      &:nth-child(2) {
        padding-left: 30px;
      }
    }
  }

  .sechdr_2 {
    h3 {
      margin: 10px 0;
    }
  }

  .perfectScroll {
    max-height: 318px;
  }

  .switch_ele {
    label {
      input[type='checkbox'] {
        &:checked+.lever+.stch_lbl.yeslbl {
          left: 10px;
        }
      }
    }
  }

  .app_radios2 {
    .radioCheck {
      margin: 5px 30px 5px 0;
    }
  }

  .action_footer {
    border-top: 1px solid #b0bac9;
    padding-top: 20px;
  }
}

.ant-modal-mask {
  opacity: 0.8 !important;
  background-color: #ffffff;
  z-index: 1048;
}

.ant-message {
  z-index: 99999;
}

.ant-modal-wrap {
  z-index: 1049;

  .ant-modal {
    .ant-modal-content {
      border-radius: 5px;
      border: 1px solid #efefef;
      @include box-shadow(0 20px 40px rgba($color: #9e9e9e, $alpha: 0.4));
      padding: 0 20px 30px;
      width: 100%;

      .ant-modal-close {
        top: 15px;
        right: 15px;

        .ant-modal-close-x {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .anticon.anticon-close {
          background-color: rgba(0, 0, 0, 0);
          background-image: url('../../images/ico-close-modal.png');
          background-position: 0 0;
          background-repeat: no-repeat;
          background-size: 100% auto;
          border: 1px solid rgba(0, 0, 0, 0);
          height: 24px;
          width: 24px;

          position: relative;

          svg {
            display: none;
          }

          &::after {
            background-color: transparent;
            border: 1px solid #333;
            content: '';
            display: inline-block;

            height: 100%;
            left: 0;
            opacity: 0;
            position: absolute;
            top: 0;
            @include transform(scale(0));
            @include transition(all 0.35s ease);
            width: 100%;
            z-index: 1;
          }
        }

        &:hover,
        &:focus {
          .anticon.anticon-close {
            &::after {
              opacity: 0.35;

              @include transform(scale(1));
            }
          }
        }
      }

      .ant-modal-header {
        padding: 30px 0 20px;

        .ant-modal-title {
          font-size: 20px;
          font-weight: 600;
          line-height: 1.3;
        }
      }

      .ant-modal-body {
        padding: 25px 0 0;
      }

      .ant-modal-footer {
        display: none;
      }
    }
  }
}

.filtersec {
  .ant-select {
    &.ant-select-single {
      .ant-select-selector {
        align-items: center;
        background-color: #ffffff;
        border-radius: 5px !important;
        border: 1px solid #ccc !important;
        box-shadow: 0 2px 9px rgba(165, 165, 165, 0.15);
        display: flex;
        min-height: 44px;
        justify-content: center;
        min-width: 155px !important;
        width: 155px !important;
      }
    }
  }
}

// ========================================================================
//                    Responsive Style Starts from here
// ========================================================================

@media screen and (max-width: 1600px) {}

@media screen and (max-width: 1440px) {}

@media screen and (max-width: 1199px) {
  .card {
    .card-content {
      padding: 30px 15px;

      .card-header {
        flex-wrap: wrap;

        .filtersec {
          justify-content: flex-start;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {  
  .ul#slide-out.sidenav{
    overflow-x: hidden;
  }
  .brand-sidebar {
    .logo-wrapper {
      a {
        &.navbar-toggler {
          display: inline-block;
          border: 1px solid #fe0000;
        }
      }
    }

    .dash_content_body{min-height: calc(100vh - 242px);}
  }

  .sidenav-main {
    background: transparent;
    border-radius: 0;
    @include box-shadow(none);
    height: calc(100vh - 68px) !important;
    padding: 0;
    top: 68px;
    width: 0;

    .brand-sidebar {
      display: none;
    }

    .btn-sidenav-toggle {
      // background-color: rgba(253, 102, 0, 0.15);
      background-color: transparent !important;
      // background-image: url('../../images/menu-hamburger.png');
      background-position: 0 0;
      background-repeat: no-repeat;
      background-size: 100% auto;
      border-radius: 3px;
      bottom: 100%;
      box-shadow: none;
      display: inline-flex;
      height: 50px;
      left: 15px;
      margin: 0 0 12px;
      overflow: hidden;
      position: absolute;
      text-indent: 100px;
      top: auto;
      width: 50px;
    }

    ul#slide-out.sidenav {
      background: #ffffff;
      // border-radius: 0 37px 37px 0;
      position: absolute;
      top: 0;
      // position: fixed;
      // padding: 30px 0;

      // box-shadow: 5px 5px 20px rgba(159, 159, 159, 0.25);
      // display: flex;
      // height: calc(100vh - 136px) !important;
      // padding: 30px 0;
    }
  }

  .navbar {
    &.navbar-fixed {
      height: 68px;
      padding: 0;
      // margin-bottom: 30px;
    }

    &.sticky_navbar {
      nav.navbar-main {
        padding: 0;

        .nav-wrapper {
          .navbar-list {
            li.notification_col {
              margin-right: 0;
            }
          }
        }
      }
    }

    nav.navbar-main {
      background-color: #ffffff;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
      padding: 0;
      height: 68px;

      .nav-wrapper {
        .brand-sidebar {
          .logo-wrapper {
            justify-content: flex-start;
            opacity: 1;
            visibility: visible;

            a.brand-logo {
              left: auto;
              @include transform(none);
            }
          }
        }

        .navbar-list {
          background-color: #fff;
          border: none;
        }
      }
    }
  }

  .dashHeader {
    height: auto;
    padding: 30px;
    // padding-left: auto;
    position: relative;
    right: auto;
  }

  .manageEmail_body {
    .filter_content {
      .ant-collapse {
        .fltr_cell {
          margin-bottom: 20px;
        }
      }
    }
  }

  .ant-dropdown {
    &.viewNotificationsDropdown {
      right: 15px !important;
      left: auto !important;
    }
  }

  .card {
    .card-header {
      .filtersec {
        justify-content: flex-start;

        .btn-default {
          white-space: nowrap;
        }
      }

      input.search_formControl,
      input[type='text']:not(.browser-default):focus:not([readonly]).search_formControl {
        max-width: 100%;
      }
    }
  }

  .notiSetting-body {
    &>.row {
      .col-lg-6 {
        padding: 0 15px;

        &:first-child {
          border-right: none;
          padding-right: 15px;
        }

        &:last-child {
          padding-left: 15px;
        }
      }
    }
  }
  

  .brand-sidebar {
    .logo-wrapper {
      .brand-logo {
        .hide-on-collapse {
          display: none;
        }

        .show-on-collapse {
          display: block;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .visible_sm {
    display: inline-block;
  }

  .navbar {
    nav.navbar-main {
      .nav-wrapper {
        .brand-sidebar {
          width: auto;
        }

        .navbar-list {
          li {
            align-items: center;

            // height: 100%;
            a,
            button {
              padding: 0 15px;
            }

            &.notification_col {
              padding-left: 10px;
              padding-right: 10px;

              a,
              button {
                border-radius: 3px;
                border: 1px solid #cbcbcb;
                height: 40px;
                width: 40px;
                padding: 0 10px;
              }
            }
          }

          i,
          a.sidenav-trigger,
          a.sidenav-trigger i {
            top: initial;
          }

          .profile-button {
            .avatar-name {
              .text-truncate {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  .brand-sidebar {
    .logo-wrapper {
      a.brand-logo {
        img {
          // height: 28px;
          height: 40px;
        }
      }

      a.navbar-toggler {
        margin-right: 15px;
      }
    }
  }

  .sidenav-main {
    .btn-sidenav-toggle {
      background-color: transparent;
      // background-color: rgba(253, 102, 0, 0.15);
      height: 40px;
      background-image: none;
      // width: 40px;
      margin: 0 0 16px;
    }
  }

  // .dashHeader {
  //   padding: 0 15px 15px;
  // }

  // .dash_content_body {
  //   padding: 0 15px 15px;
  // }


  .col,
  .container {
    padding: 0 0;
  }

  table.dataTable {
    tbody {

      th,
      td {
        padding: 20px 10px;
      }
    }
  }

  .card {
    .card-header {
      .filtersec {
        justify-content: flex-start;

        .input-field {

          // &:first-child,
          // &:nth-child(2),
          // &:nth-child(3) {
          //     flex: 0 0 33.333%;
          // }
          .column_filter {
            display: inline-block;
            width: 100%;

            .rmsc.multi-select {
              .dropdown-container {
                .dropdown-heading {
                  .dropdown-heading-value {
                    justify-content: space-between;
                  }
                }
              }
            }
          }
        }

        .input-group {
          &.saveFilter-group {
            .savefltr_btn {
              flex-grow: 1;
            }
          }
        }
      }
    }

    .card-content {
      padding: 30px 15px;
    }
  }

  .manageEmail_body {
    .card {
      .card-header {
        .filtersec {
          .input-field {

            &:first-child,
            &:nth-child(2),
            &:nth-child(3) {
              flex: 0 0 100%;
            }
          }
        }
      }
    }

    .filter_content {
      .ant-collapse {
        background-color: rgba(0, 0, 0, 0);
        padding-top: 20px;
        position: relative;

        &>.ant-collapse-item {
          &>.ant-collapse-header {
            margin-bottom: 5px;
            position: relative;
            right: initial;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 540px) {
  .brand-sidebar {
    .logo-wrapper {
      .brand-logo {
        .hide-on-collapse {
          display: none;
        }

        .show-on-collapse {
          display: block;
        }
      }
    }
  }

  .navbar {
    nav.navbar-main {
      .nav-wrapper {
        .brand-sidebar {
          width: auto;
        }

        .navbar-list {
          li {
            &.notification_col {
              padding-right: 15px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .appTabs_container {
    .ant-tabs {
      .ant-tabs-nav {
        .ant-tabs-nav-list {
          .ant-tabs-tab {
            font-size: 14px;
            padding: 15px 20px;

            &+.ant-tabs-tab {
              margin: 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 380px) {}

.scrollbar-container.notiPageScroll.h-100 {
  max-height: calc(100vh - 270px);
}

.ant-select-multiple .ant-select-selection-search input {
  font-size: inherit;
}

    .right_btn {
      background-color: rgba(0, 0, 0, 0);
      background-image: url('../../images/ico-date-right@2x.png');
      background-position: 0 0;
      background-repeat: no-repeat;
      background-size: 100% auto;
      display: inline-block;
      flex: 0 0 24px;
      height: 20px;
      line-height: initial;
      overflow: hidden;
      text-indent: 100px;
      white-space: nowrap;
      width: 20px;
  
      // &.sortup {
      //   @include transform(rotate(180deg));
      // }
    }
.border-right {
  border-right: 2px solid #000;
}
        .accordion-btn-style {
          position: relative;
          width: auto;
          background: transparent !important;
          padding-left: 40px;
          font-weight: 600;
          box-shadow: none !important;
          font-size: 18px;
          color: #000 !important;
        }
               
                .whAccordion p {
                  margin-bottom: 3px;
                  font-size: 13px;
                }
        .accordion-button::after {
          position: absolute;
          left: 15px;
          transform: rotate(-90deg) !important;
        }
                 .accordion-button:not(.collapsed)::after {
                   transform: inherit  !important;
                   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
                 }
        .whAccordion h3 {
          font-weight: bold;
          margin-bottom: 20px;
        }
                .borderRIght {
                  border-right: 1px solid #ccc;
                }
.gray-light 
{
  color: #b1bbca;
}
.mr-10 
{
  margin-right: 10px;
}
.ml-10
{
  margin-left: 10px;
}
.red-color 
{
  color: #fe0000;
}
.sent-email 
{
   font-size: 34px;
   color: #02c541;
}
.sent-email-1 {
  // font-size: 38px;
  margin-bottom: 1px;
  color: #02c541;
  margin-left: 4px;
}
.unread-email 
{
  font-size: 34px;
  color: #cd00fe;
}
.read-message 
{
  // font-size: 34px;
  color: #00d3fe;
}
.unsubscribe-mails 
{
  color: #fe0000;
  font-size: 34px;
}
.processed-icon 
{
  font-size: 34px;
  color: #e5a639;
}
// .email-icon
// {
//   font-size: 38px;
// }
// .phone-icon 
// {
//   font-size: 40px;
// }
.play_circle
{
  color: #fff;
  margin : auto;
}
.menu-icon {
  width: 50px;
  height: 50px;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  span 
  {
    width: 30px;
    height: 4px;
    display: inline-block;
    background: #fe0000;
    border-radius: 4px;
    margin-bottom: 5px;

    &:last-child
    {
      margin: 0;
    }
  }

}
.login-time 
{
  color: #186090;
}
.time-login
{
  color: #108eb1;
}
.overview-icon
{
  color: #e5a639;
  font-size: 35px;
}

.user-Name  {
  > span{
  display: flex;
  align-items: center;
  height: 100%;}

}
.metricSelect 
{
  @media screen and (max-width:580px)
  {
    .filtersec
    {
      width: 100%;
      >div 
      {
        width: 100%;
         
      }
      .ant-select.ant-select-single
        {
          width: 100%;
          margin: 0 !important;
          .ant-select-selector
          {
            width: 100% !important;
            
          }
        }
    }
  }
}

.sidenav-main ul#slide-out.sidenav::-webkit-scrollbar {
  display: none;
}


.custom-table-heading > span{
  font-weight: 500;
  min-width: 150px;
}