@import './radiobox';

.app_form {
  display: block;
  padding: 0;
  position: relative;
  & > .row {
    // margin: 0 -15px;
    &:last-child {
      margin-bottom: -25px;
    }
  }
  .form-group  {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin-bottom: 20px;
    &.mb10
    { 
      margin-bottom: 10px ;
    }
    .input-label,
    label {
      color: #333;
      line-height: $line-height-base;
      @include font-size(14px);
      font-weight: 500;
      // margin: 0 0 10px;
      position: relative;
      text-transform: capitalize;
      width: 100%;

      .css-b62m3t-container {
        width: 100%;
      }
      & + div {
        width: 100%;
      }
    }
    .heading_lbl {
      color: $secondary-color;
      line-height: $line-height-base;
      @include font-size(24px);
      font-weight: $font-weight-medium;
      margin: 0;
      position: relative;
      text-transform: uppercase;
      width: 100%;
      span {
        color: $text-color;
        @include font-size(16px);
        font-weight: $font-weight-medium;
      }
    }
    // p {
    //     @include font-size(16px);
    //     color: $text-color;
    //     font-weight: $font-weight-normal;
    //     line-height: $line-height-base;
    //     margin: 0 0 15px;
    //     width: 100%;
    // }
    input {
      margin: 0;
    }
    input:not([type='checkbox']):not([type='radio']):not([type='file']):not(.select-dropdown):not(.select2-search__field):not(.ant-select-selection-search-input),
    textarea {
      background-color: #ffffff;
      @include border-radius($border-radius-base);
      @include box-shadow(none);
      border: 1px solid $gray-dark;
      color: #000000;
      @include font-size(14px);
      padding-right: 53px ;
      font-weight: $font-weight-normal;
      height: 44px;
      line-height: 3;
      padding: 8px 15px;
      position: relative;
      @include transform(all 0.25s ease);
      width: 100%;
      &[type='password'] {
        padding-right: 53px;
      }

      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        font-weight: $font-weight-normal;
        opacity: 1;
        color: $text-color;
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        font-weight: $font-weight-normal;
        opacity: 1;
        color: $text-color;
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        font-weight: $font-weight-normal;
        opacity: 1;
        color: $text-color;
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        font-weight: $font-weight-normal;
        opacity: 1;
        color: $text-color;
      }
      &:hover {
        border-color: darken($gray-dark, 20%);
      }
      &:focus {
        border-color: $secondary-color;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
          0 0 0 3px rgba(51, 51, 51, 0.1);
      }
      &.num_text {
        // font-family: $secondary-font;
        font-weight: $font-weight-normal;
      }
    }
    textarea {
      min-height: $textarea-height;
    }

    .has-error {
      margin-top: 5px;
      position: relative;
      width: 100%;
    }

    //Bootstrap-datepicker style
    .input-group {
      &.date {
        .input-group-addon {
          align-items: center;
          display: inline-flex;
          height: 100%;
          justify-content: center;
          position: absolute;
          right: 0;
          top: 0;
          width: 66px;
          z-index: 10;
          .calIcon {
            background-color: rgba(0, 0, 0, 0);
            // background-image: url('../images/ico-calender.png');
            background-repeat: no-repeat;
            background-position: 0 0;
            background-size: 100% auto;
            display: inline-block;
            height: 30px;
            width: 28px;
          }
        }
      }
      &.app_Select {
        flex-direction: column;
        .select-wrapper {
          width: 100%;
        }
        .select2.select2-container {
          border: 1px solid #b0bac9;
          border-radius: 3px;
          padding: 5px 15px;
          position: relative;
          ul {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -5px;
            li {
              align-items: center;
              color: #333333;
              display: inline-flex;
              flex-direction: row-reverse;
              margin: 5px;
              padding: 5px;
              position: relative;

              &.select2-selection__choice {
                background-color: rgba($color: #333333, $alpha: 0.15);
                border: none;
                border-radius: 3px;
                font-size: 14px;
              }
              &.select2-search.select2-search--inline {
                padding: 0;
                input {
                  border: none;
                  height: 18px;
                  &:focus {
                    border: none;
                  }
                }
              }
              .select2-selection__choice__remove {
                align-items: center;
                background-color: rgba(0, 0, 0, 0);
                background-image: url('../../images/Icon_close_outline.png');
                background-position: center;
                background-repeat: no-repeat;
                background-size: 100% auto;
                cursor: pointer;
                display: inline-flex;
                flex: 0 0 24px;
                height: 24px;
                justify-content: center;
                line-height: 1.3;
                overflow: hidden;
                position: relative;
                margin: 0 -3px 0 5px;
                text-indent: 100px;
                width: 24px;
              }
            }
          }
        }
      }
      .btn-viewPass {
        background-color: rgba(0, 0, 0, 0);
        background-image: url('../../images/ico-pass-view.png');
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: 100% auto;
        border: none;
        display: inline-block;
        height: 34px;
        left: 15px;
        left: auto;
        margin-left: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        right: 15px;
        text-indent: 100px;
        top: 50%;
        transform: translateY(-50%);
        white-space: nowrap;
        width: 34px;
        z-index: 10;
        &.btn-pass
          {
            background-image: none;
            text-indent: inherit;
            .visibility_off
            {
              display: none;
            }
            .visibility 
            {
              opacity: 0.7;
              &:hover 
              {
                opacity: 1;
              }
            }
          }
      }
      .btn-hidePass {
        background-color: rgba(0, 0, 0, 0);
        background-image: url('../../images/ico-pass-hide@2x.png');
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: 100% auto;
        border: none;
        display: inline-block;
        height: 34px;
        left: 15px;
        left: auto;
        margin-left: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        right: 15px;
        text-indent: 100px;
        top: 50%;
        transform: translateY(-50%);
        white-space: nowrap;
        width: 34px;
        z-index: 10;
        &.btn-pass
          {
            background-image: none;
            text-indent: inherit;
            .visibility_off
            {
              display: block;
              margin-top: -3px;

              opacity: 0.7;
              &:hover 
              {
                opacity: 1;
              }
            }
            .visibility 
            {
              display: none;
            }
          }
      }
    }
.date{
  .ant-picker-clear{
    margin-right: 40px
  }
}
    //Bootstarp-selct style
    .customSelect.bootstrap-select {
      .btn.dropdown-toggle {
        @include border-radius($border-radius-base);
        @include box-shadow(none);
        border: 1px solid $gray-dark;
        font-weight: $font-weight-normal;
        @include font-size(16px);
        height: 60px;
        line-height: $line-height-base;
        padding: 10px 15px;
        position: relative;
        @include transform(all 0.25s ease);
        &:hover {
          border-color: darken($gray-dark, 20%);
        }
      }
      &.show {
        .btn.dropdown-toggle {
          border-color: $secondary-color;
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
            0 0 0 3px rgba(51, 51, 51, 0.1);
        }
      }
    }

    //input-groups style
    .input-group {
      flex-wrap: nowrap;
      width: 100%;
      // input:not([type=checkbox]):not([type=radio]):not([type=file]) {
      //     width: auto;
      // }
      .input-group-text {
        @include font-size(16px);
        background-color: rgba(0, 0, 0, 0);
        border: none;
        color: $text-color;
        padding: 0.9375rem 1.25rem;
      }
      .custom-control {
        margin: 0;
        padding-left: 0;
        width: 100%;
        &.custom-radiobox {
          .custom-control-input-radio {
            top: 18px;
          }
          .custom-control-label-radio {
            padding-left: 30px;
            line-height: 53px;

            .custom-control-label-radio {
              width: auto;
            }
            &::after {
              top: 18px;
            }
            &::before {
              top: 22px;
            }
          }
        }
      }
      .ant-radio-group {
        display: flex;
        justify-content: space-between;
        width: 100%;
        label {
          align-items: center;
          display: flex;
          height: 53px;
          justify-content: flex-start;
          line-height: 53px;
          margin: 0;
          width: auto;
        }
        .ant-radio-checked + label {
          color: $primary-color;
        }
        .ant-radio {
          height: 18px;
          top: 0;

          [type='radio'] {
            & + span::after,
            & + span::before {
              top: -2px;
              left: -2px;
            }
            &:not(:checked) + span::after,
            &:not(:checked) + span::before {
              border: none;
            }
          }

          .ant-radio-inner {
            border-width: 2px;
            height: 18px;
            padding: 0;
            width: 18px;
          }
          &.ant-radio-checked {
            .ant-radio-inner {
              border-color: $primary-color;
            }
          }
        }
        [type='radio'] + span::after,
        [type='radio'] + span::before {
          width: 10px;
          height: 10px;
        }
      }
    }

    //Radio style
    .radio_group {
      margin-top: 20px;
    }

    // Inputs with icon style
    .icon_before_input {
      display: inline-block;
      position: relative;
      width: 100%;
      input:not([type='checkbox']):not([type='radio']):not([type='file']) {
        background-color: rgba(0, 0, 0, 0);
        position: relative;
        z-index: 2;
      }
      &::before {
        background-color: rgba(0, 0, 0, 0);
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: 100%;
        content: '';
        display: inline-block;
        position: absolute;
        right: 15px;
        top: 50%;
        @include transform(translateY(-50%));
        z-index: 1;
      }
      &.icon-loc {
        &::before {
          // background-image:url(#{$assetspath}/img/icon-loc-current.png);
          // @include image-2x("#{$assetspath}/img/icon-loc-current@2x.png", 100%, auto);
          height: 30px;
          width: 30px;
        }
      }
    }

    .btn-group {
      justify-content: flex-end;
      .btn-default {
        margin: 5px 0 5px 12px;
        &:first-child{
          margin-left: 0;
        }

        @media screen and (max-width: 567px) {
          margin: 5px 0 5px 7px;
        }
      }
    }
    .radioCheck {
      cursor: pointer;
      display: flex;
      width: 126px;
      // width: 140px;
      margin-right: 30px;
      // height: 50px;
      height: 40px;
      position: relative;

      input[type='radio'] {
        @include transition(all 0.3s ease);
        cursor: pointer;
        height: 100%;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 10;
      }
      .radio-label {
        align-items: center;
        border-radius: 3px;
        border: 2px solid #afafaf;
        cursor: pointer;
        display: flex;
        height: 100%;
        margin: 0;
        padding: 10px 15px;
        position: relative;
        width: 100%;
        @include transition(all 0.3s ease);
        z-index: 1;
        .label_ico {
          align-items: center;
          border-radius: 100px;
          border: 2px solid #afafaf;
          display: flex;
          height: 24px;
          justify-content: center;
          margin-right: 10px;
          position: relative;
          width: 24px;
          @include transition(all 0.3s ease);

          &::after {
            background-color: $primary-color;
            border-radius: 100%;
            content: '';
            display: inline-block;
            height: 12px;
            width: 12px;
            opacity: 0;
            visibility: hidden;
          }
        }
        .label_txt {
          color: #1c1b24;
          font-size: 16px;
          font-weight: 400;
          line-height: 1.3;
          position: relative;
        }
      }
      [type='radio'] {
        &:hover {
          & + .radio-label {
            border-color: rgba($color: #333, $alpha: 0.65);
          }
        }
        &:checked {
          & + .radio-label {
            background-color: rgba($color: #fe0000, $alpha: 0.05);
            border-color: $primary-color;
            .label_ico {
              border-color: $primary-color;
              &::after {
                opacity: 1;
                visibility: visible;
              }
            }
            .label_txt {
              color: $primary-color;
              font-weight: 500;
            }
          }
        }
      }
    }
    .select-wrapper {
      border: none;
      box-shadow: none;
      width: 100%;
      input.select-dropdown {
        background-color: #ffffff;
        @include border-radius($border-radius-base);
        @include box-shadow(none);
        border: 1px solid $gray-dark;
        color: #000000;
        @include font-size(14px);
        font-weight: $font-weight-normal;
        height: 53px;
        line-height: $line-height-base;
        padding: 10px 20px;
        position: relative;
        @include transform(all 0.25s ease);
        width: 100%;

        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          font-weight: $font-weight-normal;
          opacity: 1;
          color: $text-color;
        }
        &::-moz-placeholder {
          /* Firefox 19+ */
          font-weight: $font-weight-normal;
          opacity: 1;
          color: $text-color;
        }
        &:-ms-input-placeholder {
          /* IE 10+ */
          font-weight: $font-weight-normal;
          opacity: 1;
          color: $text-color;
        }
        &:-moz-placeholder {
          /* Firefox 18- */
          font-weight: $font-weight-normal;
          opacity: 1;
          color: $text-color;
        }
        &:hover {
          border-color: darken($gray-dark, 20%);
        }
        &:focus {
          border-color: $secondary-color;
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
            0 0 0 3px rgba(51, 51, 51, 0.1);
        }
        &.num_text {
          // font-family: $secondary-font;
          font-weight: $font-weight-normal;
        }
      }
      .dropdown-content {
        box-shadow: none;
        left: 0 !important;
        top: 100% !important;
      }
    }
    .quill {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      .ql-toolbar.ql-snow {
        border-radius: 5px 5px 0 0;
        width: 100%;
        position: relative;
      }
      .ql-container {
        height: auto;
        border-radius: 0 0 5px 5px;
        .ql-editor {
          min-height: 150px;
        }
        &.ql-snow {
          width: 100%;
        }
      }
      .ql-snow {
        .ql-tooltip {
          // display: flex;
          border: 1px solid #ececec;
          box-shadow: 0 3px 15px rgba(159, 159, 159, 0.15);
          flex-wrap: wrap;
          margin-left: -30px;
          margin-top: 4px;
          padding: 12px;
          width: calc(100% - 15px);
          left: 8px !important;
          margin: 0 !important;
          input:not([type='checkbox']):not([type='radio']):not([type='file']):not(.select-dropdown):not(.select2-search__field):not(.ant-select-selection-search-input),
          textarea {
            min-width: initial;
            width: calc(100% - 45px);
            height: 40px;
          }
          .ql-action {
            display: inline-flex;
            align-items: center;
          }
          &:not(.ql-hidden) {
            display: inline-flex;
          }
        }
      }
    }
    .mobileEle {
      input:not([type='checkbox']):not([type='radio']):not([type='file']):not(.select-dropdown):not(.select2-search__field):not(.ant-select-selection-search-input),
      textarea {
        padding-left: 70px;
      }
      .react-tel-input {
        .flag-dropdown {
          .selected-flag {
            width: 53px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            &:focus {
              border: solid 1px #444;
            }
          }
        }
      }
    }
    .ant-picker {
      border: none;
      box-shadow: none;
      padding: 0;
      font-family: "Montserrat", sans-serif;
      &.ant-picker-range {
        .ant-picker-active-bar {
          display: none;
        }
      }
      &.ant-picker-focused {
        .ant-picker-input-active {
          input:not([type='checkbox']):not([type='radio']):not([type='file']):not(.select-dropdown):not(.select2-search__field):not(.ant-select-selection-search-input):focus,
          .app_form .form-group textarea:focus {
            border-color: #333333;
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
              0 0 0 3px rgba(51, 51, 51, 0.1);
          }
        }
      }
    }
    .ant-picker-clear {
      .anticon-close-circle {
        font-size: 1.5rem;
      }
    }
  }
}
.addRemoveBtn {
    width: 10px;
}
// ========================================================================
//                    Responsive Style Starts from here
// ========================================================================

@media screen and (max-width: 1600px) {
}

@media screen and (max-width: 1440px) {
}

@media screen and (max-width: 1199px) {
}

@media screen and (max-width: 991px) {
}

@media screen and (max-width: 767px) {
  .app_form {
    .form-group {
      .input-group {
        flex-wrap: wrap;
      }
    }
  }
}